export const loadScript = (src: string) =>
  new Promise<void>((resolve, reject) => {
    if (
      document.querySelector(`script[type="text/javascript"][src="${src}"]`)
    ) {
      resolve();
      return;
    }

    const scriptElem = Object.assign(document.createElement('script'), {
      type: 'text/javascript',
      defer: true,
      src,
      onerror: reject,
    });
    scriptElem.onload = () => {
      resolve();
    };
    document.body.appendChild(scriptElem);
  });
