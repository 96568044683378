import { LoadingSpinner, LoadingSpinnerSize } from 'main/components/common';
import { AlertWarning } from 'main/components/common/alerts';
import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import AuthenticationService from 'main/services/auth/AuthenticationService';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import WorldpayCardService from 'main/services/worldpay/WorldpayCardService';
import { redirect } from 'main/utils/redirect';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const PayWithWorldpayCardBtn: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    setLoading(true);
    setError(undefined);

    let promise;
    if (PaymentStore.flow === PaymentIntentFlow.Manage) {
      const customerNumber = AuthenticationService.user.tpId;
      if (customerNumber === undefined) {
        throw new Error('customerNumber is undefined!');
      }

      if (PaymentStore.legalEntity === undefined) {
        throw new Error('Legal entity cannot be undefined');
      }

      promise = WorldpayCardService.getManageRedirectUrl(
        PaymentStore.legalEntity,
        customerNumber,
        PaymentModuleId.WorldpayCard,
        language as SupportedLocale
      );
    } else {
      promise = WorldpayCardService.getRedirectUrl(language as SupportedLocale);
    }

    promise
      .then(setRedirectUrl)
      .catch((e: Error) => {
        TelemetryService.trackException(e, 'WorldpayCardBtn');
        Logger.error(LoggerOrigin.ReactComponent, 'WPCC URL fetch failure', e);
        setError(e);
      })
      .finally(() => setLoading(false));
  }, [language]);

  if (error) {
    return (
      <AlertWarning
        title={t('Something went wrong!')}
        description={t('Card payments are currently unavailable.')}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="h-14">
        <LoadingSpinner size={LoadingSpinnerSize.Small} />
      </div>
    );
  }

  return (
    <div className="flex">
      <PayButton
        onClick={() => redirect(redirectUrl)}
        title={
          PaymentStore.flow === PaymentIntentFlow.Manage
            ? t('Add new card')
            : t('Proceed to pay')
        }
      />
    </div>
  );
};
