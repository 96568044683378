import { execGet } from 'main/services/utils/RequestUtils';

import { InstrumentsAndBraintreeTokensApiResponse } from './PaymentInstrumentsApiResponse';

export async function getInstrumentsConfig(legalEntityNumber: number) {
  const response = await execGet<InstrumentsAndBraintreeTokensApiResponse>(
    `paymentinstruments/newInstrumentConfig?legalEntityNumber=${legalEntityNumber}`
  );

  return response;
}
