import i18n from 'i18next';
import {
  DefaultI18nNameSpace,
  DefaultLocale,
  loadTranslationsFor,
  sanitizeLocale,
  SupportedLocale,
} from 'main/i18n';
import en from 'main/i18n/translations/en.json';
import * as StartupHelper from 'main/services/base/StartupHelper';
import { isProdBuild } from 'main/utils/env';
import { getWindowParam } from 'main/utils/url';
import { initReactI18next } from 'react-i18next';

import { Startupable } from '../base/Startupable';
import Logger, { LoggerOrigin } from '../Logger';

interface I18nInnerLogger {
  logger: {
    type: string;
    log(): void;
    warn(): void;
    error(): void;
    output(): void;
  };
}

class TranslationService implements Startupable {
  public readonly name = 'TranslationService';

  constructor() {
    StartupHelper.registerStartupable(this, []);
  }

  public startup(): Promise<void> {
    // Attach our Logger to i18n for better control
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const i18nLogger = (i18n as any).logger as I18nInnerLogger;
    if (i18nLogger && i18nLogger.logger) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      i18nLogger.logger.log = (...args: any) => {
        Logger.log(LoggerOrigin.TranslationService, '', ...args);
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      i18nLogger.logger.warn = (...args: any) => {
        Logger.warn(LoggerOrigin.TranslationService, '', ...args);
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      i18nLogger.logger.error = (...args: any) => {
        Logger.error(LoggerOrigin.TranslationService, '', ...args);
      };
    }

    // eslint-disable-next-line import/no-named-as-default-member
    return i18n
      .use(initReactI18next)
      .init({
        /**
         * i18next options
         */
        debug: !isProdBuild,
        fallbackLng: DefaultLocale,
        ns: DefaultI18nNameSpace,
        resources: {
          en: {
            [DefaultI18nNameSpace]: en,
          },
        },
        keySeparator: false,
        interpolation: {
          escapeValue: false,
        },
        /**
         * react-i18next options
         */
        react: {
          bindI18n: 'languageChanged',
        },
      })
      .then(() => {
        const localeInUrl = getWindowParam('locale');
        if (localeInUrl !== undefined && typeof localeInUrl === 'string') {
          return this.changeLanguage(localeInUrl).catch(() => void 0);
        }

        return Promise.resolve();
      });
  }

  public async changeLanguage(locale: string) {
    const supportedLocale = sanitizeLocale(locale);

    await this.addTranslationsFor(supportedLocale);
    // eslint-disable-next-line import/no-named-as-default-member
    await i18n.changeLanguage(supportedLocale);
    return Promise.resolve();
  }

  private async addTranslationsFor(locale: SupportedLocale): Promise<void> {
    if (i18n.hasResourceBundle(locale, DefaultI18nNameSpace)) {
      return Promise.resolve();
    }

    const translations = await loadTranslationsFor(locale);
    i18n.addResourceBundle(
      locale,
      DefaultI18nNameSpace,
      translations,
      true,
      true
    );
  }
}

export default new TranslationService();
