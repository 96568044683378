import Logger, { LoggerOrigin } from 'main/services/Logger';

export function isApplePaySupported() {
  if (window['ApplePaySession' as never] !== undefined) {
    // TODO: Create proper typings and avoid usage of any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const appleWindow = window as any;
    if (
      appleWindow.ApplePaySession.supportsVersion(3) &&
      appleWindow.ApplePaySession.canMakePayments()
    ) {
      Logger.log(
        LoggerOrigin.ApplePay,
        'Apple Pay is available for this device.'
      );
      return true;
    } else {
      Logger.log(
        LoggerOrigin.ApplePay,
        'This device is not capable of making Apple Pay payments.'
      );
      return false;
    }
  }

  Logger.log(LoggerOrigin.ApplePay, 'This device does not support Apple Pay.');

  return false;
}
