import { Paragraph, UserIcon } from 'main/components/common';
import AuthenticationService from 'main/services/auth/AuthenticationService';
import { FC } from 'react';

export const UserDetails: FC = () => {
  return (
    <Paragraph>
      <span className="mr-xs">
        <UserIcon />
      </span>
      {AuthenticationService.user.firstName ?? AuthenticationService.user.tpId}
    </Paragraph>
  );
};
