import {
  Modal,
  ModalProps,
  Heading,
  PrimaryButton,
  SecondaryButton,
} from 'main/components/common';
import { createRef, FC, useEffect, useState } from 'react';

interface ConfirmationModal extends ModalProps {
  readonly title: string;
  readonly description: React.ReactNode;
  readonly cancelBtnTitle: string;
  readonly confirmBtnTitle: string;

  readonly onClose: () => void;
  readonly onConfirm: () => Promise<void>;
}

type FocusableElement =
  | HTMLButtonElement
  | HTMLAnchorElement
  | HTMLInputElement;

export const ConfirmationModal: FC<ConfirmationModal> = ({
  title,
  description,
  cancelBtnTitle,
  confirmBtnTitle,
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [isModalOpen, setModelOpen] = useState<boolean>(isOpen);
  const [isConfirmingAction, setConfirmationAction] = useState<boolean>(false);

  const containerRef = createRef<HTMLDivElement>();

  const enableGlobalScroll = () => {
    document.body.classList.remove('overflow-hidden');
    document.documentElement.classList.remove('overflow-hidden');
  };

  const disableGlobalScroll = () => {
    document.body.classList.add('overflow-hidden');
    document.documentElement.classList.add('overflow-hidden');
  };

  const onModalClose = () => {
    enableGlobalScroll();
    document.removeEventListener('keydown', handleKeyboard);
    setModelOpen(false);
    setConfirmationAction(false);
    onClose();
  };

  const handleKeyboard = (event: KeyboardEvent) => {
    switch (event.code) {
      case 'Escape':
        handleEscapeKey(event);
        break;
      case 'Tab':
        handleTabKey(event);
        break;
    }
  };

  const handleEscapeKey = (event: KeyboardEvent) => {
    onModalClose();
    event.preventDefault();
  };
  const handleTabKey = (event: KeyboardEvent) => {
    if (!containerRef.current || !document.activeElement) {
      return;
    }

    const focusabledElems: Element[] = Array.from(
      containerRef.current.querySelectorAll('button')
    );
    const firstElem = focusabledElems[0] as FocusableElement;
    const lastElem = focusabledElems[
      focusabledElems.length - 1
    ] as FocusableElement;

    if (!focusabledElems.includes(document.activeElement)) {
      firstElem.focus();
      event.preventDefault();
    } else if (event.shiftKey && document.activeElement === firstElem) {
      lastElem.focus();
      event.preventDefault();
    } else if (!event.shiftKey && document.activeElement === lastElem) {
      firstElem.focus();
      event.preventDefault();
    }
  };

  const attachKeyboardListenerAndForceFocus = () => {
    document.addEventListener('keydown', handleKeyboard);

    if (containerRef.current) {
      // Force focus on the first button of the dialog
      containerRef.current.querySelectorAll('button').item(0).focus();
    }
  };

  const onModalConfirmation = async () => {
    setConfirmationAction(true);
    onConfirm().finally(() => onModalClose());
  };

  useEffect(() => {
    if (isOpen !== isModalOpen) {
      setModelOpen(isOpen);
    }

    if (isOpen) {
      disableGlobalScroll();
      attachKeyboardListenerAndForceFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isModalOpen]);

  return (
    <Modal isOpen={isModalOpen}>
      <Heading>{title}</Heading>
      <span className={'mt-sm md:mt-md'}>{description}</span>
      <div className="flex flex-row gap-3 mt-sm md:mt-md" ref={containerRef}>
        <SecondaryButton disabled={isConfirmingAction} onClick={onModalClose}>
          {cancelBtnTitle}
        </SecondaryButton>
        <PrimaryButton
          disabled={isConfirmingAction}
          onClick={onModalConfirmation}
        >
          {confirmBtnTitle}
        </PrimaryButton>
      </div>
    </Modal>
  );
};
