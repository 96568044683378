import { enableUIBlocker } from 'main/components/common';

const DefaultRedirectDelay = 1000;

export function redirect(url: string, delay = DefaultRedirectDelay) {
  if (!window || !window.location) {
    return;
  }

  enableUIBlocker();
  setTimeout(() => window.location.assign(url), delay);
}
