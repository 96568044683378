import { DefaultLocale } from 'main/i18n';
import { execGet } from 'main/services/utils/RequestUtils';

import { ECommerceConnectUrlApiResponse } from '../schemas';

export async function getEcommerceConnectRedirectUrl(
  paymentIntentId: string,
  locale = DefaultLocale,
  telemetryConsent = false
): Promise<string> {
  const response = await execGet<ECommerceConnectUrlApiResponse>(
    `paymentintent/${paymentIntentId}/ecommerceconnect/url?tc=${telemetryConsent}&locale=${locale}`
  );

  return response.redirectUrl;
}
