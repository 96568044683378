import { ParentComponent } from 'main/schemas/ParentComponent';
import { FC } from 'react';

interface DemoContainerProps extends ParentComponent {
  readonly title: string;
}

export const DemoContainer: FC<DemoContainerProps> = ({ title, children }) => {
  return (
    <div className="border-primary border p-2 m-2 block align-top">
      <DemoContainerTitle title={title} />
      {children}
    </div>
  );
};

const DemoContainerTitle: FC<{ title: string }> = ({ title }) => {
  return <h1 className="font-bold text-primary">{title}</h1>;
};
