import {
  AlertError,
  AlertLoading,
  AlertSuccess,
  AlertWarning,
} from 'main/components/common/alerts';

import { DemoContainer } from './renderers';

export const CommonAlerts = () => {
  return (
    <DemoContainer title={'ALERTS'}>
      <AlertError
        title="Error alert"
        description="Optional description goes here."
      />
      <AlertWarning
        title="Warning alert"
        description="Optional description goes here."
      />
      <AlertSuccess
        title="Success alert"
        description="Optional description goes here."
      />
      <AlertLoading
        title="Loading alert"
        description="Optional description goes here."
      />
    </DemoContainer>
  );
};
