import Logger, { LoggerOrigin } from '../Logger';
import { ApiError } from './ApiError';
import { getCommonAuthenticatedHeaders } from './HeaderUtils';

export async function execGet<T>(apiEndpoint: string): Promise<T> {
  const response = await fetch(getFullUrl(apiEndpoint), {
    method: 'GET',
    headers: getCommonAuthenticatedHeaders(),
  });

  return handleFetchResponse(response);
}

export async function execPost<T>(
  apiEndpoint: string,
  body: object
): Promise<T> {
  const response = await fetch(getFullUrl(apiEndpoint), {
    method: 'POST',
    headers: getCommonAuthenticatedHeaders(),
    body: JSON.stringify(body),
  });

  return handleFetchResponse(response);
}

export async function execPatch<T>(
  apiEndpoint: string,
  body: object
): Promise<T> {
  const response = await fetch(getFullUrl(apiEndpoint), {
    method: 'PATCH',
    headers: getCommonAuthenticatedHeaders(),
    body: JSON.stringify(body),
  });

  return handleFetchResponse(response);
}

export async function execDelete(apiEndpoint: string): Promise<void> {
  const response = await fetch(getFullUrl(apiEndpoint), {
    method: 'DELETE',
    headers: getCommonAuthenticatedHeaders(),
  });

  return handleFetchResponse(response);
}

function getFullUrl(apiEndpoint: string) {
  return `${process.env.REACT_APP_PAYMENT_API}/${apiEndpoint}`;
}

async function handleFetchResponse(response: Response) {
  if (response.status === 204 && response.ok) {
    return;
  }

  const textResponse = await response.text();
  let jsonResponse;

  try {
    jsonResponse = await JSON.parse(textResponse);
  } catch (e) {
    Logger.warn(
      LoggerOrigin.RequestUtils,
      'Failed to parse JSON response. Was it as JSON? Will use it as string'
    );
  }

  if (response.ok) {
    return jsonResponse ?? textResponse;
  }

  if (Array.isArray(jsonResponse)) {
    const errMsg = jsonResponse
      .reduce((msg, err) => `${msg}\n${err?.detail}\n` ?? '\n', '')
      .slice(1);

    throw new ApiError(response.status, response.statusText, errMsg);
  }

  throw new ApiError(response.status, response.statusText, '');
}
