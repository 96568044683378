import { FC } from 'react';

export const PlusIcon: FC = () => {
  return (
    <svg
      width={24}
      height={24}
      strokeWidth={2}
      className="flex-none self-center inline-block"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g
        strokeLinecap="square"
        strokeMiterlimit="10"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="miter"
      >
        <line x1="12" y1="7" x2="12" y2="17"></line>
        <line x1="17" y1="12" x2="7" y2="12"></line>
      </g>
    </svg>
  );
};
