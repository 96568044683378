import { execGet } from '../utils/RequestUtils';

export interface YookassaApiResponse {
  readonly redirectUrl: string;
}

export async function getYookassaRedirectUrl(
  paymentIntentId: string,
  locale: string,
  telemetryConsent = false
): Promise<string> {
  const response = await execGet<YookassaApiResponse>(
    `paymentintent/${paymentIntentId}/yookassa/url?tc=${telemetryConsent}&locale=${locale}`
  );

  return response.redirectUrl;
}
