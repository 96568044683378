import cn from 'clsx';
import type { ParentComponent } from 'main/schemas/ParentComponent';
import { FC } from 'react';
import ReactDOM from 'react-dom';

export interface ModalProps extends ParentComponent {
  readonly isOpen: boolean;
}

const styles = {
  modalContainer: 'fixed inset-0 z-front overflow-auto',
  modalBackground:
    'absolute w-full h-full flex justify-center items-center inset-0 bg-black bg-opacity-25',
  modalDialog:
    'p-sm md:p-md relative flex overflow-hidden flex-col bg-white shadow w-full md:w-auto mx-sm md:mx-md md:mx-0 rounded-panel break-words text-center',
};

export const Modal: FC<ModalProps> = ({ isOpen, children }) => {
  const renderModal = () => {
    return (
      <div className={cn(styles.modalContainer, isOpen ? 'block' : 'hidden')}>
        <div className={styles.modalBackground}>
          <div role="dialog" aria-modal="true" className={styles.modalDialog}>
            {children}
          </div>
        </div>
      </div>
    );
  };

  return ReactDOM.createPortal(renderModal(), document.body);
};
