import {
  enableUIBlocker,
  PadlockIcon,
  PrimaryButton,
} from 'main/components/common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface PayButtonProps {
  readonly onClick: () => void;
  readonly disabled?: boolean;
  readonly title?: string;
}

export const PayButton: FC<PayButtonProps> = ({
  onClick,
  disabled = false,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <PrimaryButton
      disabled={disabled}
      onClick={() => {
        enableUIBlocker();
        onClick();
      }}
    >
      <span className={'flex flex-row items-center justify-center'}>
        <PadlockIcon />
        <span className={'ml-xs'}>{title ?? t('Proceed to pay')}</span>
      </span>
    </PrimaryButton>
  );
};
