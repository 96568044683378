import type { ParentComponent } from 'main/schemas/ParentComponent';
import { AppTheme } from 'main/schemas/Theme';
import { FC, useEffect, useState } from 'react';

import { FaviconLinkMissingError } from './exceptions/FaviconLinkMissingError';
import { ThemeContext } from './ThemeContext';

export const ThemeProvider: FC<ParentComponent> = ({ children }) => {
  const [theme, setTheme] = useState<AppTheme>(AppTheme.PM);

  useEffect(() => {
    if (theme) {
      updateHtmlThemeAttribute(theme);
      updateHtmlThemeFaviconLink(theme);
    }
  }, [theme]);

  const updateHtmlThemeAttribute = (newTheme: AppTheme) => {
    // Attribute name used in themes.scss
    const root = document.getElementsByTagName('html').item(0);
    if (root) {
      root.setAttribute('data-theme', newTheme);
    }
  };

  const updateHtmlThemeFaviconLink = (newTheme: AppTheme) => {
    // Favicons are named with the same values as the AppThemes
    const faviconPath = `/${newTheme}_favicon.ico`;
    const htmlFaviconLink = Array.from(
      document.getElementsByTagName('link')
    ).find((linkElement) => linkElement.getAttribute('rel') === 'icon');

    if (!htmlFaviconLink) {
      throw new FaviconLinkMissingError(faviconPath);
    }

    htmlFaviconLink.setAttribute('href', faviconPath);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
