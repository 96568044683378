import {
  FilledBadge,
  GlobeIcon,
  Paragraph,
  PaymentIcon,
} from 'main/components/common';
import { BadgeType } from 'main/components/common/badges/enums';
import { CountryFlag } from 'main/components/common/icons/CountryFlag';
import { useUserFriendlyPrefixForInstrumentBrand } from 'main/hooks/useUserFriendlyPrefixForInstrumentBrands';
import {
  InstrumentStatus,
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import PaymentStore from 'main/services/payments/PaymentStore';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiChevronDown } from 'react-icons/bi';

import { EditStoredInstrumentSection } from './EditStoredInstrumentSection';

interface LegalEntityDescription {
  code: string;
  label: string;
}

export const legalEntityToCode = new Map<number, LegalEntityDescription>([
  [1, { code: 'de', label: 'Germany' }],
  [9009999, { code: 'lu', label: 'Luxembourg' }],
  [401000, { code: 'it', label: 'Italy' }],
  [441000, { code: 'be', label: 'Belgium' }],
  [441001, { code: 'nl', label: 'Netherlands' }],
  [534000, { code: 'no', label: 'Norway' }],
  [950000, { code: 'ch', label: 'Switzerland' }],
  [790300, { code: 'gb', label: 'United Kingdom' }],
  [430000, { code: 'es', label: 'Spain' }],
  [660020, { code: 'dk', label: 'Denmark' }],
  [770000, { code: 'se', label: 'Sweden' }],
  [500000, { code: 'pl', label: 'Poland' }],
  [509997, { code: 'pl', label: 'Poland - DSC Poznan' }],
  [930000, { code: 'at', label: 'Austria' }],
  [910110, { code: 'fr', label: 'France' }],
  [510001, { code: 'fi', label: 'Finland' }],
  [2400000, { code: 'us', label: 'US North' }],
  [2400024, { code: 'us', label: 'US South' }],
  [24000241, { code: 'us', label: 'US FPO' }],
  [2450003, { code: 'ca', label: 'Canada' }],
  [2600000, { code: 'au', label: 'Australia' }],
  [7200000, { code: 'jp', label: 'Japan' }],
  [66005000, { code: 'mx', label: 'Mexico' }],
  [480002, { code: 'ru', label: 'Russia' }],
  [7010000, { code: 'th', label: 'Thailand' }],
  [7100011, { code: 'tw', label: 'Taiwan' }],
  [7500000, { code: 'hk', label: 'Hong Kong' }],
  [7300050, { code: 'sg', label: 'Singapore' }],
  [2700600, { code: 'in', label: 'India' }],
  [7900000, { code: 'cl', label: 'Chile' }],
  [7400002, { code: 'sg', label: 'HQ Asia pacific' }],
  [431000, { code: 'pt', label: 'Portugal' }],
  [75100, { code: 'de', label: 'Germany - DSC München' }],
  [77100, { code: 'de', label: 'DSC Hamburg Norderstedt' }],
  [600501, { code: 'hu', label: 'Hungary' }],
  [2810000, { code: 'ma', label: 'Morocco' }],
  [800555, { code: 'tr', label: 'Turkey' }],
  [2610000, { code: 'nz', label: 'New Zealand' }],
  [464000, { code: 'cz', label: 'Czech Republic' }],
  [890050, { code: 'bg', label: 'Bulgaria' }],
  [6000000, { code: 'ci', label: 'Ivory Coast' }],
]);

export const StoredInstrument: FC<PaymentInstrument> = (instrument) => {
  const { t } = useTranslation();
  const instrumentBrandPrefixer = useUserFriendlyPrefixForInstrumentBrand();
  const [isEditSectionExpanded, setIsEditSectionExpanded] = useState(false);

  const isManageFlow = PaymentStore.flow === PaymentIntentFlow.Manage;

  const instrumentName = useMemo(() => {
    if (instrument.brand) {
      const brandPrefix = instrumentBrandPrefixer(instrument.brand) ?? '';

      if (instrument.moduleId === PaymentModuleId.BraintreeGooglePay) {
        const googlePayString = 'Google Pay';
        return `${googlePayString} (${brandPrefix ? t(brandPrefix) : ''} ${
          instrument.maskedInfo
        })`;
      }

      return `${brandPrefix ? t(brandPrefix) : ''} ${instrument.maskedInfo}`;
    }

    return instrument.maskedInfo;
  }, [instrument, instrumentBrandPrefixer, t]);

  const flag =
    instrument.legalEntityNumber === undefined
      ? undefined
      : legalEntityToCode.get(instrument.legalEntityNumber);

  return (
    <div className={'w-full'}>
      <div className={'w-full flex flex-row items-center space-x-sm h-14 p-sm'}>
        <PaymentIcon icon={instrument.iconName} />
        <div className="flex flex-row w-full justify-between items-center">
          <div className="grid" title={instrumentName}>
            <Paragraph ellipsisOnOverflow>{instrumentName}</Paragraph>
            <div className="flex items-center gap-3">
              {instrument.expirationDate && (
                <div className="grow-0 shrink-0 w-14 text-sm text-silver">
                  <Paragraph ellipsisOnOverflow>
                    {instrument.expirationDate}
                  </Paragraph>
                </div>
              )}
              {instrument.status === InstrumentStatus.Expired && (
                <FilledBadge type={BadgeType.Error} text={t('Expired')} />
              )}
              {instrument.status ===
                InstrumentStatus.TemporarilyUnavailable && (
                <FilledBadge type={BadgeType.Info} text={t('Unavailable')} />
              )}
              {isManageFlow && instrument.isDefault && (
                <FilledBadge type={BadgeType.Primary} text={t('Default')} />
              )}
            </div>
          </div>
        </div>

        {isManageFlow && instrument.isDuplicate && (
          <div className="grow-0 shrink-0 w-6 flex flex-row items-center h-full">
            {instrument.legalEntityNumber === 0 ? (
              //Paypal has no reference to a particular country, but value for this should be 0!
              <GlobeIcon className="w-6 h-4 object-cover" />
            ) : flag ? (
              <CountryFlag countryCode={flag.code}></CountryFlag>
            ) : (
              '*'
            )}
          </div>
        )}

        {isManageFlow && (
          <button
            onClick={() => setIsEditSectionExpanded((prevState) => !prevState)}
          >
            <BiChevronDown
              size={22}
              className={
                'text-primary transform transition-transform duration-300' +
                (isEditSectionExpanded ? ' rotate-180' : ' rotate-0')
              }
            />
          </button>
        )}
      </div>

      {isManageFlow && (
        <EditStoredInstrumentSection
          isVisible={isEditSectionExpanded}
          instrument={instrument}
          instrumentName={instrumentName}
          hideSection={() => setIsEditSectionExpanded(false)}
        />
      )}
    </div>
  );
};
