import { Copyrights, Paragraph } from 'main/components/common';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const CommonText: FC = () => {
  return (
    <DemoContainer title={'UI TEXT'}>
      <Paragraph>Normal text paragraph</Paragraph>
      <Copyrights />
    </DemoContainer>
  );
};
