import { PageLayout, ProgressBarButton } from 'main/components/common';
import { AlertSuccess } from 'main/components/common/alerts';
import { ParentComponent } from 'main/schemas/ParentComponent';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import PaymentStore from 'main/services/payments/PaymentStore';
import { redirect } from 'main/utils/redirect';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface PaymentSuccessfulProps extends ParentComponent {
  readonly orderId?: string;
  readonly title: string;
  readonly successUrl?: string;
}

export const PaymentSuccessful: FC<PaymentSuccessfulProps> = ({
  orderId,
  title,
  successUrl,
  children,
}) => {
  const { t } = useTranslation();

  const description =
    PaymentStore.flow === PaymentIntentFlow.Moto
      ? t('Close the window')
      : undefined;

  return (
    <PageLayout
      headerDescription={orderId ? `${t('Order')}: ${orderId}` : undefined}
    >
      <AlertSuccess title={title} description={description ?? undefined}>
        {children}
        {successUrl !== undefined &&
          PaymentStore.flow !== PaymentIntentFlow.Moto && (
            <ProgressBarButton
              onClick={() => {
                redirect(successUrl);
              }}
            />
          )}
      </AlertSuccess>
    </PageLayout>
  );
};
