import { PaymentInstrument } from 'main/schemas/PaymentInstrument';
import PaymentStore from 'main/services/payments/PaymentStore';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const PaymentInstruments: FC = () => {
  return (
    <DemoContainer title={'STORED INSTRUMENTS'}>
      <p>
        Computed pre-selected instrument:{' '}
        {printInstrument(PaymentStore.preSelectedInstrument)}
      </p>
      {PaymentStore.instruments.map((i, index) => (
        <p key={index}>{printInstrument(i)}</p>
      ))}
    </DemoContainer>
  );
};

function printInstrument(i?: PaymentInstrument) {
  if (!i) {
    return 'undefined';
  }

  return `{ id: ${i.id}, moduleId: ${i.moduleId}, status: ${i.status}, maskedInfo: ${i.maskedInfo} }`;
}
