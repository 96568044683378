import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { execGet, execPost } from 'main/services/utils/RequestUtils';

import { BraintreeMerchantInfo } from '../BraintreeClient';
import { ClientTokenApiResponse } from '../schemas/schemas';

export async function getBraintreeClientToken(
  paymentIntentId: string,
  moduleId: PaymentModuleId
): Promise<string> {
  const selectedPaymentMethod = moduleId;
  const response = await execPost<ClientTokenApiResponse>(
    'braintree/clienttoken',
    { paymentIntentId, selectedPaymentMethod }
  );

  return response.token;
}

export async function getBraintreeSecureNonce(
  paymentDataId?: number
): Promise<string> {
  const response = await execPost<string>('braintree/secureNonce', {
    paymentDataId: paymentDataId,
  });

  return response;
}

export async function getBraintreeMerchantInfo(
  paymentIntentId: string | undefined,
  legalEntityNumber: number | undefined
): Promise<BraintreeMerchantInfo> {
  let searchParams;

  if (paymentIntentId !== undefined) {
    searchParams = new URLSearchParams({
      paymentIntentId: paymentIntentId,
    });
  } else if (legalEntityNumber !== undefined) {
    searchParams = new URLSearchParams({
      legalEntityNumber: legalEntityNumber?.toString(),
    });
  } else {
    throw new Error(
      'Neither PaymentIntentId, nor legalEntityNumber was provided'
    );
  }

  const response = await execGet<BraintreeMerchantInfo>(
    `braintree/merchantdetails?${searchParams.toString()}`
  );

  return response;
}
