import { Badge, FilledBadge } from 'main/components/common';
import { BadgeType } from 'main/components/common/badges/enums';

import { DemoContainer } from './renderers';

export const CommonBadges = () => {
  return (
    <DemoContainer title={'UI BADGES'}>
      <div>
        <Badge text="badge" type={BadgeType.Primary} />
        <Badge text="badge" type={BadgeType.Error} />
      </div>
      <div>
        <FilledBadge text="filled badge" type={BadgeType.Primary} />
        <FilledBadge text="filled badge" type={BadgeType.Error} />
        <FilledBadge text="filled badge" type={BadgeType.Info} />
      </div>
    </DemoContainer>
  );
};
