export enum ToastType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export type ToastContainerConfig = { readonly [type in ToastType]: Toast };

interface Toast {
  readonly textColor: string;
  readonly bgColor: string;
}
