import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { registerStartupable } from 'main/services/base/StartupHelper';

import { OptionalStartupable } from '../base/Startupable';
import PaymentStore from '../payments/PaymentStore';
import { getYookassaRedirectUrl } from './YookassaApi';

class YookassaService implements OptionalStartupable {
  public readonly name = 'YookassaService';
  public readonly moduleId = PaymentModuleId.Yookassa;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public startup(): Promise<void> {
    return Promise.resolve();
  }

  public shouldStart(): boolean {
    return PaymentStore.allowedPaymentModules.includes(this.moduleId);
  }

  public getRedirectUrl(
    locale: SupportedLocale,
    telemetryConsent = false
  ): Promise<string> {
    const paymentIntentId = PaymentStore.paymentIntentId;

    return getYookassaRedirectUrl(paymentIntentId, locale, telemetryConsent);
  }
}

export default new YookassaService();
