import { FC } from 'react';

import { IconProps } from './schemas';

export const LoadingIcon: FC<IconProps> = ({
  className = 'w-4 h-4 self-center inline-block',
}) => {
  return (
    <svg fill="currentColor" viewBox="0 0 16 16" className={className}>
      <path d="M8,16c-1.199,0-2.352-0.259-3.428-0.77l0.857-1.807C6.235,13.806,7.1,14,8,14c3.309,0,6-2.691,6-6 s-2.691-6-6-6S2,4.691,2,8c0,0.901,0.194,1.766,0.578,2.572l-1.806,0.859C0.26,10.354,0,9.2,0,8c0-4.411,3.589-8,8-8s8,3.589,8,8 S12.411,16,8,16z" />
    </svg>
  );
};
