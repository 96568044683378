import * as React from 'react';

import { BasicBadge } from './BasicBadge';
import { BadgeType } from './enums';
import { BadgeProps } from './schemas';

const styles = {
  primaryBadge: 'border-primary text-primary',
  errorBadge: 'border-error text-error',
};

export const Badge: React.FunctionComponent<BadgeProps> = ({ text, type }) => {
  const badgeStyle = React.useMemo(() => {
    switch (type) {
      case BadgeType.Error:
        return styles.errorBadge;
      case BadgeType.Primary:
      default:
        return styles.primaryBadge;
    }
  }, [type]);

  return <BasicBadge extraClassName={badgeStyle}>{text}</BasicBadge>;
};
