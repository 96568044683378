import AuthenticationService from 'main/services/auth/AuthenticationService';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const UserProfile: FC = () => {
  const { firstName, lastName, tpId } = AuthenticationService.user;

  return (
    <DemoContainer title={'LOGGED IN USER'}>
      <div className="flex flex-col">
        <span>TP: {tpId}</span>
        <span>Name: {firstName}</span>
        <span>Surname: {lastName}</span>
        <button
          className="border border-1 p-2 m-2"
          onClick={() => AuthenticationService.startSignout()}
        >
          Sign out
        </button>
      </div>
    </DemoContainer>
  );
};
