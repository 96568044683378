export function Ideal(className: string) {
  return (
    <svg
      width="307px"
      height="270px"
      viewBox="0 0 307 270"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <path
              d="M0,20 L0,249.8 C0,260.8 9,269.8 20,269.8 L157.3,269.8 C261.1,269.8 306.1,211.7 306.1,134.6 C306.1,57.9 261.1,0 157.3,0 L20,0 C9,0 0,9 0,20 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M91.9,56.4 L91.9,226.2 L165.8,226.2 C232.9,226.2 262,188.3 262,134.7 C262,83.4 232.9,43.6 165.8,43.6 L104.7,43.6 C97.6,43.6 91.9,49.4 91.9,56.4 Z"
              fill="#CC0066"
            ></path>
            <path
              d="M157.3,251.5 L37.9,251.5 C27.3,251.5 18.7,242.9 18.7,232.3 L18.7,37.6 C18.7,27 27.3,18.4 37.9,18.4 L157.3,18.4 C270.6,18.4 287.5,91.3 287.5,134.7 C287.5,210 241.2,251.5 157.3,251.5 Z M37.9,24.8 C30.8,24.8 25.1,30.5 25.1,37.6 L25.1,232.3 C25.1,239.4 30.8,245.1 37.9,245.1 L157.3,245.1 C237.1,245.1 281.1,205.9 281.1,134.7 C281.1,39.1 203.5,24.8 157.3,24.8 L37.9,24.8 Z"
              fill="#000000"
            ></path>
          </g>
          <g transform="translate(100.600000, 111.700000)" fill="#FFFFFF">
            <path d="M17.3,0.1 C19.9,0.1 22.3,0.5 24.6,1.3 C26.9,2.1 28.8,3.4 30.5,5 C32.1,6.7 33.4,8.8 34.4,11.2 C35.3,13.7 35.8,16.6 35.8,20 C35.8,23 35.4,25.7 34.7,28.2 C33.9,30.7 32.8,32.9 31.3,34.7 C29.8,36.5 27.9,37.9 25.6,39 C23.3,40 20.6,40.6 17.5,40.6 L0,40.6 L0,2.84217094e-14 L17.3,2.84217094e-14 L17.3,0.1 Z M16.7,33.2 C18,33.2 19.2,33 20.5,32.6 C21.7,32.2 22.8,31.5 23.7,30.5 C24.6,29.5 25.4,28.3 26,26.7 C26.6,25.1 26.9,23.3 26.9,21 C26.9,19 26.7,17.1 26.3,15.5 C25.9,13.9 25.2,12.4 24.3,11.3 C23.4,10.2 22.2,9.2 20.7,8.6 C19.2,8 17.4,7.7 15.2,7.7 L8.8,7.7 L8.8,33.3 L16.7,33.3 L16.7,33.2 Z"></path>
            <polygon points="71.9 0.1 71.9 7.6 50.5 7.6 50.5 16.3 70.2 16.3 70.2 23.2 50.5 23.2 50.5 33.1 72.4 33.1 72.4 40.6 41.6 40.6 41.6 2.84217094e-14 71.9 2.84217094e-14"></polygon>
            <path d="M102.5,0.1 L117.7,40.7 L108.4,40.7 L105.3,31.7 L90.1,31.7 L86.9,40.7 L77.9,40.7 L93.2,0.1 L102.5,0.1 L102.5,0.1 Z M103,25 L97.9,10.1 L97.8,10.1 L92.5,25 L103,25 Z"></path>
            <polygon points="132.2 0.1 132.2 33.2 152 33.2 152 40.7 123.3 40.7 123.3 0.1 132.2 0.1"></polygon>
          </g>
          <circle fill="#000000" cx="58.5" cy="132.1" r="18.7"></circle>
          <path
            d="M72.6,226.2 L72.6,226.2 C56.9,226.2 44.3,213.5 44.3,197.9 L44.3,175.8 C44.3,168 50.6,161.6 58.5,161.6 L58.5,161.6 C66.3,161.6 72.7,167.9 72.7,175.8 L72.7,226.2 L72.6,226.2 Z"
            fill="#000000"
          ></path>
        </g>
      </g>
    </svg>
  );
}
