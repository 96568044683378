import cn from 'clsx';
import { FC, ReactNode } from 'react';

import { BasicButton } from './BasicButton';
import { IButtonProps } from './schemas';

const styles = {
  colors: 'text-gray opacity-40',
  spacing: 'p-0',
  shape: 'border-0 p-xs',
  font: 'font-normal text-center',
  disabled: 'cursor-not-allowed',
  hover: 'hover:opacity-100',
  focus: 'focus:opacity-100',
  active: 'active:opacity-100',
};

interface IconButtonProps extends IButtonProps {
  readonly icon: ReactNode;
}

export const IconButton: FC<IconButtonProps> = ({
  disabled,
  onClick,
  icon,
  className,
  ariaLabel,
}) => (
  <BasicButton
    onClick={onClick}
    disabled={disabled}
    className={cn(
      styles.colors,
      styles.spacing,
      styles.shape,
      styles.font,
      styles.hover,
      !disabled && styles.focus,
      !disabled && styles.active,
      disabled && styles.disabled,
      className && className
    )}
    aria-label={ariaLabel}
  >
    {icon}
  </BasicButton>
);
