import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { registerStartupable } from 'main/services/base/StartupHelper';

import { OptionalStartupable } from '../base/Startupable';
import { paymentInstrumentAuthorize } from '../payments/intent/PaymentIntentApi';
import PaymentStore from '../payments/PaymentStore';

class SubsidiaryService implements OptionalStartupable {
  public readonly name = 'SubsidiaryService';
  public readonly moduleId = PaymentModuleId.PaymentAtSubsidiary;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public startup(): Promise<void> {
    return Promise.resolve();
  }

  public shouldStart(): boolean {
    return PaymentStore.allowedPaymentModules.includes(this.moduleId);
  }

  public authorise() {
    return paymentInstrumentAuthorize({
      paymentIntentId: PaymentStore.paymentIntentId,
      paymentDataTypeId: this.moduleId,
    });
  }
}

export default new SubsidiaryService();
