import {
  InstrumentStatus,
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';
import AuthenticationService from 'main/services/auth/AuthenticationService';
import { getWindowParam } from 'main/utils/url';

import { determineInstrumentPreSelection } from '../utils/InstrumentUtils';
import {
  getManagePaymentInstruments,
  getPaymentInstruments,
} from './PaymentInstrumentsApi';

export class PaymentInstrumentsStore {
  public readonly name = 'PaymentInstrumentsStore';

  private _instruments: ReadonlyArray<PaymentInstrument> = [];
  private _preSelectedInstrument: PaymentInstrument | undefined;
  private _rawPreSelectedPaymentMethod: PaymentModuleId | undefined;

  //this prop represents a specified in the url instrument that will be used for payment
  private _externalPreselectedInstrument: PaymentInstrument | undefined;

  constructor(rawPreSelectedPaymentMethod: PaymentModuleId | undefined) {
    this._rawPreSelectedPaymentMethod = rawPreSelectedPaymentMethod;
  }

  public async init(paymentIntentId: string): Promise<void> {
    const instrumentIdSearchParam = getWindowParam('instrumentId');
    const instrumentId = !isNaN(Number(instrumentIdSearchParam))
      ? Number(instrumentIdSearchParam)
      : undefined;

    if (instrumentId !== undefined) {
      const customerNumber = AuthenticationService.user.tpId;

      if (customerNumber === undefined) {
        throw new Error('Customer number is not set');
      }

      /*Since external selected instrument (from url) could be any user's instrument (not necessairly in the context of the payment intent - e.g. it could have been added in other legal entity)
      we fetch all user's instruments as we do in the new /manage page*/
      this._instruments = await getManagePaymentInstruments(customerNumber);

      //even though we need only id of the instrument better to have the whole object
      this._externalPreselectedInstrument = this._instruments.find(
        (i) => i.id === instrumentId
      );
    } else {
      this._instruments = await getPaymentInstruments(paymentIntentId);
    }

    this.computePreSelectedInstrument();
  }

  public forceUpdateInstrumentStatus(
    startedModules: ReadonlyArray<PaymentModuleId>
  ) {
    this._instruments = this._instruments.map((i) => {
      if (!startedModules.includes(i.moduleId)) {
        return { ...i, status: InstrumentStatus.TemporarilyUnavailable };
      }
      return i;
    });
    this.computePreSelectedInstrument();
  }

  get instruments() {
    return this._instruments;
  }

  get preSelectedInstrument() {
    return this._preSelectedInstrument;
  }

  get externalPreselectedInstrument() {
    return this._externalPreselectedInstrument;
  }

  private computePreSelectedInstrument() {
    this._preSelectedInstrument =
      this._externalPreselectedInstrument !== undefined &&
      this.externalPreselectedInstrument?.status === InstrumentStatus.Valid
        ? this._externalPreselectedInstrument
        : determineInstrumentPreSelection(
            this._rawPreSelectedPaymentMethod,
            this._instruments
          );
  }
}
