import { FC } from 'react';

interface RadioIconProps {
  readonly selected?: boolean;
  readonly size?: number;
}

export const RadioIcon: FC<RadioIconProps> = ({ selected, size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="flex-none self-center inline-block"
    >
      <g fill="currentColor">
        <g>
          <path d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Z"></path>
        </g>
        {selected && (
          <circle
            cx="12"
            cy="12"
            r="7"
            stroke="none"
            fill="currentColor"
          ></circle>
        )}
      </g>
    </svg>
  );
};
