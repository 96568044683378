import { UnsupportedLocaleError } from '../errors/UnsupportedLocaleError';
import { SupportedLocale } from '../schemas/Locales';

export function isLocaleSupported(locale: string): boolean {
  return Object.values(SupportedLocale).includes(locale as SupportedLocale);
}

export function sanitizeLocale(locale: string): SupportedLocale {
  // Currently, we use country codes as locales.
  const unsanitizedLocale = locale.toLowerCase().split('-')[0];

  // Norwegian matches "nn" and "no" for file en(no).json
  if (unsanitizedLocale === 'nn') {
    return SupportedLocale.Norwegian;
  }

  // Chinese simplified matches "zh" and "zh-Hans" for file en(zh-Hans).json
  if (unsanitizedLocale === 'zh') {
    return SupportedLocale.ChineseSimplifiedChina;
  }

  // Indonesian matches "in" and "id" for file en(id).json
  if (unsanitizedLocale === 'in') {
    return SupportedLocale.Indonesian;
  }

  // Check if it's a supported locale
  if (isLocaleSupported(unsanitizedLocale)) {
    return unsanitizedLocale as SupportedLocale;
  }

  throw new UnsupportedLocaleError(locale);
}
