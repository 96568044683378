import { loadScript } from 'main/hooks/loadScript';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { registerStartupable } from 'main/services/base/StartupHelper';

import { OptionalStartupable } from '../base/Startupable';
import PaymentStore from '../payments/PaymentStore';
import { getNuveiConfiguration } from './NuveiApi';
import { NuveiConfigurationApiResponse } from './NuveiConfigurationApiResponse';
import { NuveiTwintCreatePaymentInput } from './NuveiCreatePaymentInput';
import { NuveiTransactionResponse } from './NuveiTransactionResponse';

interface NuveiSafeCharge {
  readonly createPayment: (
    options: NuveiTwintCreatePaymentInput,
    handler: (params: NuveiTransactionResponse) => void
  ) => Promise<NuveiTransactionResponse>;
}

class NuveiTwintService implements OptionalStartupable {
  private _nuveiTwintConfiguration!: NuveiConfigurationApiResponse;
  private _safeCharge!: NuveiSafeCharge;

  public readonly name = 'NuveiTwintService';
  public readonly moduleId = PaymentModuleId.NuveiTwint;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public async startup(): Promise<void> {
    /*For Nuvei TWINT it is important to regenerate clientUniqueId every time we do attempt to pay, so API call to Payment service should be done on every button click */
  }

  public shouldStart(): boolean {
    const isAllowed = PaymentStore.allowedPaymentModules.includes(
      this.moduleId
    );

    return isAllowed;
  }

  private initializeSafeCharge(): Promise<void> {
    return loadScript(
      'https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js'
    ).then(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignored
      this._safeCharge = window.SafeCharge({
        env: this._nuveiTwintConfiguration.environment,
        merchantId: this._nuveiTwintConfiguration.merchantId,
        merchantSiteId: this._nuveiTwintConfiguration.merchantSiteId,
      }) as NuveiSafeCharge;
    });
  }

  private async initTransaction(): Promise<void> {
    const nuveiTwintConfiguration = await getNuveiConfiguration(
      PaymentStore.paymentIntentId,
      PaymentModuleId.NuveiTwint
    );
    if (nuveiTwintConfiguration === undefined) {
      throw new Error('Nuvei TWINT transaction configuration has no value!');
    }
    this._nuveiTwintConfiguration = nuveiTwintConfiguration;
  }

  public async createNewPayment(): Promise<NuveiTransactionResponse> {
    await this.initTransaction();
    await this.initializeSafeCharge();

    return new Promise((resolve, reject) => {
      this._safeCharge.createPayment(
        {
          sessionToken: this._nuveiTwintConfiguration.sessionToken,
          clientUniqueId: this._nuveiTwintConfiguration.clientUniqueId,
          paymentOption: {
            alternativePaymentMethod: {
              paymentMethod: 'apmgw_TWINT',
            },
          },
          billingAddress: {
            email: PaymentStore.customerEmail,
            country: PaymentStore.customerCountryCode,
          },
          apmWindowType: 'popup', // popup (default), redirect, customRedirect
        },
        (response) => this.responseHandler(response, reject, resolve)
      );
    });
  }

  public responseHandler(
    params: NuveiTransactionResponse,
    reject: (params: NuveiTransactionResponse) => void,
    resolve: (params: NuveiTransactionResponse) => void
  ): void {
    const responseWithConvertedError: NuveiTransactionResponse = {
      ...params,
      errCode:
        typeof params.errCode === 'string'
          ? params.errCode
          : `${params.errCode}`,
    };

    if (responseWithConvertedError.result === 'ERROR') {
      //cancel, failure?
      reject(responseWithConvertedError);
    } else {
      //success
      resolve(responseWithConvertedError);
    }
  }
}

export default new NuveiTwintService();
