import { DefaultLocale } from 'main/i18n';
import { execGet } from 'main/services/utils/RequestUtils';

import { TransbankUrlApiResponse } from '../schemas';

export async function getTransbankUrl(
  paymentIntentId: string,
  locale = DefaultLocale,
  telemetryConsent = false
): Promise<string> {
  const response = await execGet<TransbankUrlApiResponse>(
    `paymentintent/${paymentIntentId}/transbank/url?tc=${telemetryConsent}&locale=${locale}`
  );

  return response.redirectUrl;
}
