import { ParentComponent } from 'main/schemas/ParentComponent';
import { FC } from 'react';

import { StatusParamHandler } from '../contexts/params/StatusParamHandler';
import { ThemedPage } from '../contexts/theme';
import { ErrorBoundaries } from './ErrorBoundaries';

export const WrapperProviders: FC<ParentComponent> = ({ children }) => {
  return (
    <ErrorBoundaries>
      <ThemedPage>{children}</ThemedPage>
      <StatusParamHandler />
    </ErrorBoundaries>
  );
};
