import { EnvInfo } from '../utils/EnvInfo';
import { PaymentInstruments } from '../utils/PaymentInstruments';
import { PaymentIntent } from '../utils/PaymentIntent';
import { SearchParams } from '../utils/SearchParams';
import { UserProfile } from '../utils/UserProfile';

export function TestEnvironmentInfo() {
  return (
    <>
      <h1 className="font-bold text-xl mt-sm">ENVIRONMENT INFORMATION</h1>
      <UserProfile />
      <EnvInfo />
      <SearchParams />
      <PaymentIntent />
      <PaymentInstruments />
    </>
  );
}
