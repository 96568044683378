import PaymentStore from 'main/services/payments/PaymentStore';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const PaymentIntent: FC = () => {
  const {
    currency,
    flow,
    orderNumber,
    orderTotal,
    theme,
    allowedPaymentModules,
    paymentIntentId,
    cancelUrl,
    successUrl,
    preSelectedModule,
    rawPreSelectedPaymentMethod,
    legalEntity,
  } = PaymentStore;

  return (
    <DemoContainer title={'INTENT DETAILS'}>
      <p>ID: {paymentIntentId}</p>
      <p>Currency: {currency}</p>
      <p>Total: {orderTotal}</p>
      <p>Order: {orderNumber}</p>
      <p>Theme: {theme}</p>
      <p>Flow: {flow}</p>
      <p>Allowed payment types: {allowedPaymentModules?.toString()}</p>
      <p>Cancel Url: {cancelUrl}</p>
      <p>Success Url: {successUrl}</p>
      <p>Raw pre selection: {rawPreSelectedPaymentMethod}</p>
      <p>Computed pre-selection payment method: {preSelectedModule}</p>
      <p>Legal Entity: {legalEntity}</p>
    </DemoContainer>
  );
};
