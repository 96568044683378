import * as React from 'react';

import { BasicBadge } from './BasicBadge';
import { BadgeType } from './enums';
import { BadgeProps } from './schemas';

const styles = {
  primaryBadge: 'border-primary bg-primary text-white',
  errorBadge: 'border-error bg-error text-white',
  infoBadge: 'border-silver bg-silver text-gray',
};

export const FilledBadge: React.FunctionComponent<BadgeProps> = ({
  text,
  type,
}) => {
  const badgeStyle = React.useMemo(() => {
    switch (type) {
      case BadgeType.Error:
        return styles.errorBadge;
      case BadgeType.Info:
        return styles.infoBadge;
      case BadgeType.Primary:
      default:
        return styles.primaryBadge;
    }
  }, [type]);

  return <BasicBadge extraClassName={badgeStyle}>{text}</BasicBadge>;
};
