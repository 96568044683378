export enum SupportedLocale {
  Bulgarian = 'bg',
  Czech = 'cs',
  Danish = 'da',
  German = 'de',
  English = 'en',
  Spanish = 'es',
  Finnish = 'fi',
  French = 'fr',
  Hungarian = 'hu',
  Indonesian = 'id',
  Italian = 'it',
  Japanese = 'ja',
  Korean = 'ko',
  Dutch = 'nl',
  Norwegian = 'no',
  Polish = 'pl',
  Portuguese = 'pt',
  Russian = 'ru',
  Swedish = 'sv',
  ChineseSimplifiedChina = 'zh-Hans',
  Thai = 'th',
  ChineseTraditionalTaiwan = 'zh-Hant-TW',
  Turkish = 'tr',
  Ukrainian = 'uk',
}

type SupportedLanguage = {
  [locale in SupportedLocale]: string;
};

export const SupportedLanguagesMap: SupportedLanguage = {
  [SupportedLocale.Bulgarian]: 'Български',
  [SupportedLocale.Czech]: 'Čeština',
  [SupportedLocale.Danish]: 'Dansk',
  [SupportedLocale.German]: 'Deutsch',
  [SupportedLocale.English]: 'English',
  [SupportedLocale.Spanish]: 'Español',
  [SupportedLocale.Finnish]: 'Suomi',
  [SupportedLocale.French]: 'Français',
  [SupportedLocale.Hungarian]: 'Magyar',
  [SupportedLocale.Indonesian]: 'Bahasa Indonesia',
  [SupportedLocale.Italian]: 'Italiano',
  [SupportedLocale.Japanese]: '日本語',
  [SupportedLocale.Korean]: '한국어',
  [SupportedLocale.Dutch]: 'Nederlands',
  [SupportedLocale.Norwegian]: 'Nørsk',
  [SupportedLocale.Polish]: 'Polska',
  [SupportedLocale.Portuguese]: 'Português',
  [SupportedLocale.Russian]: 'Русский',
  [SupportedLocale.Swedish]: 'Svenska',
  [SupportedLocale.ChineseSimplifiedChina]: '中文(简体)',
  [SupportedLocale.Thai]: 'ไทย',
  [SupportedLocale.ChineseTraditionalTaiwan]: '中文(繁體)',
  [SupportedLocale.Turkish]: 'Türkçe',
  [SupportedLocale.Ukrainian]: 'українська',
};

export const DefaultLocale = SupportedLocale.English;
export const DefaultI18nNameSpace = 'pmi.web.payments';
