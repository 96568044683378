import { ICustomer } from 'main/schemas/Customer';

import { execGet } from '../utils/RequestUtils';

export async function getCustomerInformation(
  customerNumber: string
): Promise<ICustomer> {
  const response = await execGet<ICustomer>(`customer/${customerNumber}`);
  return response;
}
