import { LanguageSelector as CommonLanguageSelector } from 'main/components/common/LanguageSelector';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DemoContainer } from './renderers';

export const LanguageSelector: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <DemoContainer title={'TRANSLATIONS'}>
      <CommonLanguageSelector />
      <p>Locale in use: {language}</p>
      <p>{t('This functionality is not available right now.')}</p>
    </DemoContainer>
  );
};
