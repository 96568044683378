import cn from 'clsx';
import { FC } from 'react';

import { BasicButton } from './BasicButton';
import type { IButtonProps } from './schemas';

const styles = {
  hover: 'filter hover:text-primary-interaction',
  focus: 'filter focus:text-primary-interaction',
  active: 'filter active:text-primary-interaction',
};

export const SimpleButton: FC<IButtonProps> = ({
  disabled,
  onClick,
  className,
  children,
}) => {
  return (
    <BasicButton
      onClick={onClick}
      disabled={disabled}
      className={cn(
        'text-primary p-0 border-0 relative font-normal text-center',
        !disabled && styles.hover,
        !disabled && styles.focus,
        !disabled && styles.active,
        disabled && 'cursor-not-allowed disabled',
        className ?? ''
      )}
    >
      {children}
    </BasicButton>
  );
};
