import { BoldHeading, Heading, PageLayout } from 'main/components/common';
import { SupportedLocale } from 'main/i18n';
import { ParentComponent } from 'main/schemas/ParentComponent';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import PaymentStore from 'main/services/payments/PaymentStore';
import { formatPrice } from 'main/utils/formatters';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface PaymentsLayoutProps extends ParentComponent {
  readonly title: string;
  readonly footer: JSX.Element | null;
  readonly navigation: JSX.Element | null;
  readonly mainAction: JSX.Element | null;
  readonly flow: PaymentIntentFlow;
}

const styles = {
  loadingSpinner: 'flex items-center justify-center',
  pageContent: 'p-sm md:p-md 2xl:p-lg',
  pageContentHeader:
    'sticky top-0 bg-primary p-sm md:p-md 2xl:p-lg z-50 text-white',
  pageContentNavigation: 'flex',
  pageContentFooter: 'mt-md flex w-full justify-between',
  pageStickyContainer:
    'sticky bottom-0 border-0 z-50 bg-transparent pb-xs pt-sm',
  pageInnerStickyContainer: 'flex justify-center',
};

export const PaymentsLayout: FC<PaymentsLayoutProps> = ({
  navigation,
  title,
  footer,
  mainAction,
  children,
  flow,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const headerText = useMemo(() => {
    if (flow === PaymentIntentFlow.Manage) {
      return t('Payment methods');
    }
    return t('Total: {{totalPrice}}', {
      totalPrice: formatPrice(
        language as SupportedLocale,
        PaymentStore.currency,
        PaymentStore.orderTotal
      ),
    });
  }, [flow, t, language]);

  return (
    <PageLayout
      headerDescription={
        flow !== PaymentIntentFlow.Manage
          ? `${t('Order')}: ${PaymentStore.orderNumber}`
          : ''
      }
    >
      <div className={styles.pageContentHeader}>
        <BoldHeading>{headerText}</BoldHeading>
      </div>

      <div className={styles.pageContent}>
        {navigation && (
          <div className={styles.pageContentNavigation}>{navigation}</div>
        )}

        {title && (
          <div className={navigation ? 'mt-sm md:mt-md' : ''}>
            <Heading>{title}</Heading>
          </div>
        )}

        <div className="mt-sm md:mt-md">{children}</div>

        {footer && <div className={styles.pageContentFooter}>{footer}</div>}
        {mainAction && (
          <div className={styles.pageStickyContainer}>
            <div className={styles.pageInnerStickyContainer}>{mainAction}</div>
          </div>
        )}
      </div>
    </PageLayout>
  );
};
