import {
  errorToast,
  infoToast,
  successToast,
} from 'main/components/common/toasts/utils';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import PaymentStore from 'main/services/payments/PaymentStore';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { isValidRedirectStatus } from './helpers/StatusHelper';
import { RedirectStatus } from './schemas/RedirectStatus';

export function StatusParamHandler() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const toastMessageHandler = (status: RedirectStatus): void => {
    switch (status) {
      case RedirectStatus.Canceled:
        infoToast(t('Select a new payment method and proceed to pay.'));
        break;
      case RedirectStatus.Failure:
        errorToast(
          t(
            'Operation failed. Please try again with a different payment method.'
          )
        );
        break;
      case RedirectStatus.Success:
        successToast(t('Operation completed with success!'));
        break;
    }
  };

  useEffect(() => {
    const paymentIntentId = searchParams.get('pid');
    const status = searchParams.get('status');

    const isStatusValid =
      PaymentStore.flow === PaymentIntentFlow.Manage
        ? status && isValidRedirectStatus(status)
        : paymentIntentId && status && isValidRedirectStatus(status);

    if (isStatusValid) {
      toastMessageHandler(status as RedirectStatus);
      searchParams.delete('status');
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, toastMessageHandler]);

  return null;
}
