import { execGet } from 'main/services/utils/RequestUtils';

import { TwoCTwoPUrlApiResponse } from './schemas/schemas';
import { TwoCTwoPLocale } from './schemas/TwoCTwoPLocale';

export async function getTwoCTwoPRedirectUrl(
  paymentIntentId: string,
  locale: TwoCTwoPLocale,
  telemetryConsent = false
): Promise<string> {
  const response = await execGet<TwoCTwoPUrlApiResponse>(
    `paymentintent/${paymentIntentId}/twoctwop/url?tc=${telemetryConsent}&locale=${locale}`
  );

  return response.redirectUrl;
}
