import { GlobeIcon } from 'main/components/common/icons';
import {
  SupportedLanguagesMap,
  SupportedLocale,
  UnsupportedLocaleError,
} from 'main/i18n';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import TranslationsService from 'main/services/translations/TranslationsService';
import { TelemetryEvent } from 'main/telemetry';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const styles = {
  container: 'flex relative focused-container',
  selectInput:
    'absolute w-full h-full inset-0 appearance-none cursor-pointer opacity-0',
  selectOption:
    'ml-xs after:content-[""] after:ml-xs after:border-4 after:border-transparent after:border-t-gray after:absolute after:top-1/2 after:-translate-y-0.5',
};

export const LanguageSelector = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const onLanguageUpdate = (newLocale?: string | null) => {
    if (newLocale === void 0 || newLocale === null || newLocale === language) {
      return;
    }

    TranslationsService.changeLanguage(newLocale)
      .then(() => {
        TelemetryService.trackEvent({
          name: TelemetryEvent.LanguageUpdated,
          properties: {
            locale: newLocale,
          },
        });
      })
      .catch((err: UnsupportedLocaleError) => {
        TelemetryService.trackException(err, 'LanguageSelector');
      });
  };

  useEffect(() => {
    if (searchParams.has('locale')) {
      searchParams.set('locale', language);
    } else {
      searchParams.append('locale', language);
    }
    setSearchParams(searchParams);
  }, [language, searchParams, setSearchParams]);

  return (
    <div className={styles.container}>
      <GlobeIcon />
      <span className={styles.selectOption}>
        {SupportedLanguagesMap[language as SupportedLocale]}
      </span>
      {/* Hide the select element so that the text width is dynamic with the content */}
      <select
        className={styles.selectInput}
        title="Language selector"
        onChange={(event) =>
          onLanguageUpdate(event?.target.value as SupportedLocale)
        }
        defaultValue={language}
      >
        {Object.values(SupportedLocale).map((supportedLocale) => (
          <option key={supportedLocale} value={supportedLocale}>
            {SupportedLanguagesMap[supportedLocale]}
          </option>
        ))}
      </select>
    </div>
  );
};
