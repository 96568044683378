import {
  ArrowIcon,
  CheckMarkIcon,
  CopyrightIcon,
  DeleteIcon,
  GlobeIcon,
  InfoIcon,
  LoadingIcon,
  PadlockIcon,
  PlusIcon,
  QuestionMarkIcon,
  RadioIcon,
  UserIcon,
  WarningIcon,
} from 'main/components/common';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const CommonIcons: FC = () => {
  return (
    <DemoContainer title={'UI ICONS'}>
      <div className="flex flex-row flex-wrap gap-4">
        <ArrowIcon direction="down" />
        <ArrowIcon direction="left" />
        <ArrowIcon direction="right" />
        <ArrowIcon direction="up" />
        <CheckMarkIcon />
        <CopyrightIcon />
        <DeleteIcon />
        <GlobeIcon />
        <InfoIcon />
        <LoadingIcon />
        <PadlockIcon />
        <PlusIcon />
        <QuestionMarkIcon />
        <RadioIcon selected={false} />
        <RadioIcon selected={true} />
        <UserIcon />
        <WarningIcon />
      </div>
    </DemoContainer>
  );
};
