import cn from 'clsx';
import { RadioIcon } from 'main/components/common';
import { FC, useEffect, useState } from 'react';

interface RadioGroupProps {
  readonly onSelectionChange?: (optionId: string) => void;
  readonly preSelectedValue?: string;
  readonly name: string;
  readonly options: InputRadioOption[];
}

export interface InputRadioOption {
  readonly id: string;
  readonly label: string | JSX.Element;
  readonly value: string;
  readonly disabled: boolean;
}

const styles = {
  container:
    'relative mt-xs first:mt-0 flex items-center border-2 border-secondary rounded-field',
  disabledContainer: 'bg-secondary opacity-50',
  containerFocus: 'hover:border-focus focused-container',
  containerSelected: 'bg-secondary',
  input: 'absolute inset-0 w-14 h-full opacity-0',
  label: 'ml-sm w-full overflow-hidden',
  radioIcon: 'text-secondary',
  radioIconSelected: 'text-primary',
};

export const RadioGroup: FC<RadioGroupProps> = ({
  onSelectionChange,
  preSelectedValue,
  name,
  options,
}) => {
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    if (options.length > 0) {
      setSelected(
        isPreSelectionValid(preSelectedValue, options)
          ? preSelectedValue
          : undefined
      );
    }
  }, [options, preSelectedValue]);

  useEffect(() => {
    if (onSelectionChange && selected) {
      onSelectionChange(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <>
      {options.map((opt) => {
        return (
          <div
            key={`radio-${opt.id}`}
            className={cn(
              'h-14 p-sm',
              styles.container,
              opt.disabled === true && styles.disabledContainer,
              opt.disabled === false && styles.containerFocus,
              selected === opt.id && styles.containerSelected
            )}
          >
            <input
              className={cn(
                styles.input,
                opt.disabled === false && 'cursor-pointer'
              )}
              onChange={() => setSelected(opt.id)}
              id={opt.id}
              type="radio"
              name={name}
              value={opt.value}
              disabled={opt.disabled === true}
            />
            <span
              className={
                selected === opt.id
                  ? styles.radioIconSelected
                  : styles.radioIcon
              }
            >
              <RadioIcon size={20} selected={selected === opt.id} />
            </span>
            <label
              className={cn(
                styles.label,
                opt.disabled === false && 'cursor-pointer'
              )}
              htmlFor={opt.id}
            >
              {opt.label}
            </label>
          </div>
        );
      })}
    </>
  );
};

function isPreSelectionValid(
  value: string | undefined,
  options: InputRadioOption[]
): boolean {
  return value !== undefined && options.map((o) => o.id).includes(value);
}
