import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';

import { OptionalStartupable } from '../base/Startupable';
import { registerStartupable } from '../base/StartupHelper';
import { paymentInstrumentAuthorize } from '../payments/intent/PaymentIntentApi';
import PaymentStore from '../payments/PaymentStore';

class PostFinanceService implements OptionalStartupable {
  public readonly name = 'PostFinanceService';
  public readonly moduleId = PaymentModuleId.DirectDebitPostFinance;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  shouldStart(): boolean {
    const isAllowed = PaymentStore.allowedPaymentModules.includes(
      this.moduleId
    );

    const hasAtLeastOneInstrument =
      PaymentStore.instruments.find((i) => i.moduleId === this.moduleId) !==
      undefined;

    return isAllowed || hasAtLeastOneInstrument;
  }

  startup(): Promise<void> {
    return Promise.resolve();
  }

  public authorize(instrumentId: number) {
    return paymentInstrumentAuthorize({
      paymentIntentId: PaymentStore.paymentIntentId,
      paymentDataTypeId: this.moduleId,
      paymentDataId: instrumentId,
    });
  }

  public async submitPostFinanceForm(
    accountHolderName: string,
    iban: string,
    bankName: string
  ): Promise<void> {
    if (PaymentStore.flow === PaymentIntentFlow.Manage) {
      if (PaymentStore.legalEntity === undefined) {
        throw new Error('Legal entity cannot be undefined');
      }

      //TODO: implement this
      throw new Error('Not Implemented!!!');
    } else {
      return paymentInstrumentAuthorize({
        paymentIntentId: PaymentStore.paymentIntentId,
        paymentDataTypeId: this.moduleId,
        iban: iban,
        bankName: bankName,
        accountHolderName: accountHolderName,
        paymentDataId: undefined,
      });
    }
  }
}

export default new PostFinanceService();
