import { FC } from 'react';

export const FitlineLogo: FC = () => (
  <svg
    className={'h-14 sm:h-16 flex-none'}
    viewBox="0 0 524.45 396.85"
    fill="#b2b2b2"
  >
    <path d="M505.68 213.98c9.34 0 16.52 7.65 16.52 16.53 0 9.24-7.4 16.68-16.52 16.68-9.05 0-16.54-7.34-16.54-16.68 0-9.09 7.49-16.53 16.54-16.53m0-2.24c-10.04 0-18.77 8.25-18.77 18.77 0 10.53 8.64 18.87 18.77 18.87 10.22 0 18.77-8.34 18.77-18.87 0-10.43-8.45-18.77-18.77-18.77m-4.08 10.92h4.37c1.49 0 3.97 0 3.97 3.18 0 3.08-2.58 3.08-3.97 3.08h-4.37v-6.26zm-2.78 17.78h2.78v-9.14h3.48c3.28 0 4.07.79 4.37 3.88.39 3.17.6 3.97.99 5.26h2.87c-.69-1.99-.89-3.87-1.2-6.36-.3-2.49-.99-3.38-3.07-3.88 3.77-1.09 3.77-4.27 3.77-4.86 0-5.07-5.36-5.07-6.85-5.07h-7.15v20.17h.01zM21.45 323.87c9.4-26.18 20.56-52.05 34.08-76.45 58.76-17.05 69.63-4.12 69.63-19.11 0-1.18 1.18-4.41-4.99-5.88-8.22-2.05-28.21.3-53.17 5.59 11.46-21.16 24.38-42.04 39.66-60.86 34.38-7.64 46.42-9.41 52.29-9.7 5.88 0 5.58 1.76 15.28 1.76 4.41 0 7.63-4.41 7.63-8.81 0-7.65-12.34-8.53-17.91-8.53-17.93 0-46.72 5.58-64.35 9.99-6.46 1.77-21.15 4.12-21.15 13.52 0 2.65 1.76 5.59 4.99 5.59 17.33-28.8-37.32 62.62-55.23 94.37C21.74 276.82 0 323.56 0 335.02c0 5 4.11 11.47 9.4 11.47 4.7 0 6.17-4.41 7.34-7.93l4.71-14.69" />
    <path d="M101.67 308.18c0 3.53 2.65 7.06 6.47 7.06 6.45 0 7.64-7.35 9.1-12.34 14.1-47.63 37.9-71.15 37.9-77.61 0-3.24-2.64-5.6-5.57-5.6-10.29 0-47.9 70.57-47.9 88.49m76.39-144.64c-7.63 0-18.21 14.11-18.21 21.46 0 4.12 2.64 7.06 6.76 7.06 7.93 0 18.51-13.82 18.51-21.47 0-4.1-2.65-7.05-7.06-7.05z" />
    <path d="M174.83 207.89c-4.41.59-10.57 1.76-10.57 7.35 0 4.41 5.58 6.77 9.11 6.77 2.06 0 4.12-.59 6.16-1.18-20.56 37.93-24.98 55.27-24.98 71.74-1.16 14.11 8.81 20.28 16.17 20.28 14.4 0 26.74-15.59 34.38-26.17 2.64-3.52 12.34-17.63 12.34-22.05 0-2.35-2.35-4.11-4.7-4.11-2.93 0-4.69 2.64-6.17 4.99-5.58 9.41-19.1 31.75-30.26 31.75-5 0-6.47-4.12-6.47-8.22 0-19.11 19.69-57.34 30.26-74.09 11.17-2.93 22.63-7.94 33.79-9.11 4.71-.59 10.88-.59 10.88-6.76 0-5.29-3.82-7.06-8.52-7.06-7.35 0-16.45 2.65-23.8 4.7 3.22-6.76 16.74-21.46 16.74-27.63 0-2.93-2.93-6.48-5.87-6.48-7.64 0-28.2 34.41-32.9 41.75l-15.59 3.53" />
    <path d="M283.12 302.59c0 3.53 2.65 7.06 6.46 7.06 6.46 0 7.65-7.35 9.11-12.34 14.11-47.62 37.9-71.15 37.9-77.62 0-3.23-2.64-5.58-5.58-5.58-10.29-.01-47.89 70.55-47.89 88.48m76.39-144.64c-7.64 0-18.22 14.11-18.22 21.46 0 4.12 2.64 7.06 6.75 7.06 7.94 0 18.52-13.82 18.52-21.46 0-4.12-2.65-7.06-7.05-7.06z" />
    <path d="M363.1 222c-9.11 0-32.9 51.16-38.19 61.74-2.06 4.41-5.59 11.76-5.59 16.46 0 4.12 2.35 7.35 6.75 7.35 6.17 0 9.99-9.12 12.34-13.24 8.82-16.17 19.39-31.45 34.67-41.45l.59.59c-8.23 12.05-15.28 28.51-15.28 43.8 0 8.82 2.93 27.05 14.69 27.05 3.23 0 6.17-2.94 6.17-6.17 0-3.83-6.47-8.53-6.47-21.17 1.48-36.45 33.79-59.67 34.09-69.68 0-4.41-2.36-7.04-6.18-7.04-10.87 0-37.31 26.16-44.66 34.69l-.59-.59c1.76-6.47 14.11-19.4 14.11-25.86.02-3.54-2.93-6.48-6.45-6.48" />
    <path d="M419.56 266.1c1.18-5.3 6.75-14.41 13.22-22.05 6.46-7.94 14.1-14.41 19.1-14.41 2.05 0 3.24 1.18 3.24 3.24 0 5.29-6.76 12.93-9.99 16.46-7.64 8.23-15.28 13.52-25.57 16.76m38.79-52.33c-16.75 0-57.89 33.81-57.89 70.85 0 14.11 9.41 22.05 22.63 22.63 24.09.89 61.4-34.98 62.28-45.27.3-2.06-2.06-4.12-4.11-4.12-5.88 0-25.86 34.1-51.12 34.1-8.81 0-15.28-4.99-13.81-14.1 29.38-7.64 53.18-35.87 53.18-53.51 0-6.18-5.29-10.58-11.16-10.58z" />
    <path
      d="M287.09 231.83c30.89-52.9 143.76-194.38 149.71-218.16C438.58 6.54 429.67 0 422.54 0c-13.07 0-27.92 23.18-35.05 32.69l-64.75 88.56c-79.6 113.55-114.06 178.94-133.66 238.38-8.32 24.38 5.93 39.82 21.97 36.86 20.2-3.57 29.71-7.72 41.59-13.08 22.57-10.1 143.56-20.21 168.51-20.21 4.16 0 33.86 8.33 33.86-14.86 0-22-110.88-26.75-227.92 8.32 13.07-44.59 36.83-85 60-124.83"
      fill="#ce0538"
    />
  </svg>
);
