import { execGet } from 'main/services/utils/RequestUtils';

import { ProcessingKZLocale } from './schemas/ProcessingKZLocale';
import { ProcessingKZUrlApiResponse } from './schemas/schemas';

export async function getProcessingKZRedirectUrl(
  paymentIntentId: string,
  locale: ProcessingKZLocale,
  telemetryConsent = false
): Promise<string> {
  const response = await execGet<ProcessingKZUrlApiResponse>(
    `paymentintent/${paymentIntentId}/processingkz/url?tc=${telemetryConsent}&locale=${locale}`
  );

  return response.redirectUrl;
}
