export function Yookassa(className: string) {
  return (
    <svg viewBox="0 0 175 28" className={className}>
      <path
        d="M48 8.33489L53.5685 21.2331L51.3411 27.0088H54.734L61.9083 8.33489H58.4118L55.2779 17.0891L51.6519 8.33489H48Z"
        fill="#0A2540"
      ></path>
      <path
        d="M69.5029 8.04999C65.5143 8.04999 62.5876 10.9249 62.5876 14.8099C62.5876 18.7208 65.5143 21.5698 69.5029 21.5698C73.4915 21.5698 76.4182 18.7208 76.4182 14.8099C76.4182 10.9249 73.4915 8.04999 69.5029 8.04999ZM69.5029 11.0803C71.6008 11.0803 72.9735 12.5825 72.9735 14.8099C72.9735 17.0632 71.6008 18.5654 69.5029 18.5654C67.405 18.5654 66.0064 17.0632 66.0064 14.8099C66.0064 12.5825 67.405 11.0803 69.5029 11.0803Z"
        fill="#0A2540"
      ></path>
      <path
        d="M85.1339 8.04999C81.1453 8.04999 78.2186 10.9249 78.2186 14.8099C78.2186 18.7208 81.1453 21.5698 85.1339 21.5698C89.1225 21.5698 92.0492 18.7208 92.0492 14.8099C92.0492 10.9249 89.1225 8.04999 85.1339 8.04999ZM85.1339 11.0803C87.2318 11.0803 88.6045 12.5825 88.6045 14.8099C88.6045 17.0632 87.2318 18.5654 85.1339 18.5654C83.036 18.5654 81.6374 17.0632 81.6374 14.8099C81.6374 12.5825 83.036 11.0803 85.1339 11.0803Z"
        fill="#0A2540"
      ></path>
      <path
        d="M94.8339 21.3108H98.1232V13.3595C98.1232 11.676 99.2887 11.0803 100.428 11.0803C101.671 11.0803 102.5 11.8573 102.5 13.3595V21.3108H105.79V13.3595C105.79 11.6501 106.955 11.0803 108.095 11.0803C109.338 11.0803 110.167 11.8573 110.167 13.3595V21.3108H113.456V13.1005C113.456 9.78529 111.384 8.04999 108.82 8.04999C107.11 8.04999 105.893 8.82699 104.961 9.96659C104.132 8.69749 102.785 8.04999 101.257 8.04999C99.8585 8.04999 98.7966 8.67159 97.9678 9.60399L97.7865 8.33489H94.8339V21.3108Z"
        fill="#0A2540"
      ></path>
      <path
        d="M122.871 8.04999C118.882 8.04999 115.956 10.9249 115.956 14.8099C115.956 18.7208 118.882 21.5698 122.871 21.5698C126.86 21.5698 129.786 18.7208 129.786 14.8099C129.786 10.9249 126.86 8.04999 122.871 8.04999ZM122.871 11.0803C124.969 11.0803 126.342 12.5825 126.342 14.8099C126.342 17.0632 124.969 18.5654 122.871 18.5654C120.773 18.5654 119.375 17.0632 119.375 14.8099C119.375 12.5825 120.773 11.0803 122.871 11.0803Z"
        fill="#0A2540"
      ></path>
      <path
        d="M132.571 21.3108H135.86V14.007C135.86 11.8314 137.362 11.1062 138.735 11.1062C140.263 11.1062 141.351 12.0904 141.351 14.007V21.3108H144.64V13.489C144.64 9.91479 142.309 8.04999 139.538 8.04999C138.088 8.04999 136.767 8.59389 135.731 9.62989L135.549 8.33489H132.571V21.3108Z"
        fill="#0A2540"
      ></path>
      <path
        d="M154.005 21.5698C157.139 21.5698 159.393 20.0935 160.17 17.5035L157.113 16.8819C156.699 17.9697 155.715 18.7208 154.005 18.7208C152.141 18.7208 150.82 17.8402 150.509 15.82H160.144C160.247 15.3279 160.299 14.8099 160.299 14.3437C160.299 10.5882 157.657 8.04999 153.85 8.04999C149.913 8.04999 147.142 10.9508 147.142 14.8876C147.142 18.7467 149.706 21.5698 154.005 21.5698ZM153.85 10.7695C155.508 10.7695 156.57 11.676 156.854 13.3077H150.613C151.079 11.5983 152.348 10.7695 153.85 10.7695Z"
        fill="#0A2540"
      ></path>
      <path
        d="M161.06 8.33489L166.628 21.2331L164.401 27.0088H167.794L174.968 8.33489H171.471L168.337 17.0891L164.711 8.33489H161.06Z"
        fill="#0A2540"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5313 13.9618C11.5521 6.27038 17.8505 0 25.7651 0C33.6028 0 40.0892 6.29114 39.9991 14C39.9991 21.7089 33.6028 28 25.7651 28C17.9403 28 11.5522 21.8178 11.5313 14.0384V24.4557H6.48634L0 4.07593H11.5313V13.9618ZM20.4499 14C20.4499 16.8354 22.8823 19.2279 25.7651 19.2279C28.7381 19.2279 31.0803 16.8354 31.0803 14C31.0803 11.1646 28.648 8.77215 25.7651 8.77215C22.8823 8.77215 20.4499 11.1646 20.4499 14Z"
        fill="#0070F0"
      ></path>
    </svg>
  );
}
