import { InputRadioOption } from 'main/components/common/forms/inputs/RadioGroup';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';

import { NewPaymentMethod } from './NewPaymentMethod';

export function computeRadioOptions(
  modules: ReadonlyArray<PaymentModuleId>
): InputRadioOption[] {
  return modules.map((mid) => ({
    id: mid.toString(),
    label: <NewPaymentMethod moduleId={mid} />,
    value: mid.toString(),
    disabled: false,
  }));
}
