import { DefaultLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { execGet, execPost } from 'main/services/utils/RequestUtils';

import { FlutterwaveUrlApiResponse } from '../schemas';

export async function getCardUrl(
  paymentIntentId: string,
  paymentInstrumentId?: number,
  locale = DefaultLocale,
  telemetryConsent = false
): Promise<string> {
  const searchParams = new URLSearchParams({
    tc: telemetryConsent.toString(),
    locale,
  });

  if (paymentInstrumentId !== undefined) {
    searchParams.append('paymentDataId', paymentInstrumentId.toString());
  }

  const response = await execGet<FlutterwaveUrlApiResponse>(
    `paymentintent/${paymentIntentId}/flutterwave/url?${searchParams.toString()}`
  );

  return response.redirectUrl;
}

export async function getManageCardUrl(
  legalEntityNumber: number,
  customerNumber: string,
  moduleId: PaymentModuleId,
  locale = DefaultLocale,
  telemetryConsent = false
): Promise<string> {
  const response = await execPost<FlutterwaveUrlApiResponse>(
    `paymentinstruments`,
    {
      legalEntityNumber: legalEntityNumber,
      customerNumber: customerNumber,
      paymentDataTypeId: moduleId,
      locale: locale,
      tc: telemetryConsent,
    }
  );

  return response.redirectUrl;
}
