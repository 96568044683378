import { Paragraph, PaymentIcon } from 'main/components/common';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { InstrumentIconForModuleId } from 'main/services/payments/instruments/PaymentInstrumentTypes';
import { FC } from 'react';

const styles = {
  wrapper: 'w-full flex flex-row items-center space-x-sm',
};

export const NewPaymentMethod: FC<{ readonly moduleId: PaymentModuleId }> = ({
  moduleId,
}) => {
  const paymentModuleT = useUserFriendlyPaymentModuleName();

  return (
    <div className={styles.wrapper}>
      <Paragraph ellipsisOnOverflow>{paymentModuleT(moduleId)}</Paragraph>
      <PaymentIcon icon={InstrumentIconForModuleId[moduleId]} />
    </div>
  );
};
