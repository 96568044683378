import {
  CheckMarkIcon,
  ConfirmationModal,
  DeleteIcon,
  Paragraph,
  PaymentIcon,
  SimpleButton,
  disableUIBlocker,
  enableUIBlocker,
} from 'main/components/common';
import { errorToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params';
import {
  InstrumentStatus,
  PaymentInstrument,
} from 'main/schemas/PaymentInstrument';
import {
  changeDefaultPaymentInstrument,
  deletePaymentInstrument,
} from 'main/services/payments/instruments/PaymentInstrumentsApi';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { isDeletable } from './helper';

export const EditStoredInstrumentSection: FC<{
  readonly instrument: PaymentInstrument;
  readonly instrumentName: string;
  readonly isVisible: boolean;
  hideSection(): void; // this should notify the parent element that current element needs to be hidden
}> = ({ instrument, instrumentName, hideSection, isVisible }) => {
  const [removingInstrument, setRemovingInstrument] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const isExpired = instrument.status === InstrumentStatus.Expired;

  return (
    <>
      <div
        className={
          'relative h-fit overflow-hidden w-full ' +
          ' transition-all ease-in-out duration-300' +
          (isVisible ? ' max-h-screen opacity-100' : ' max-h-0 opacity-0')
        }
      >
        <div
          className={
            'px-9 pb-2 flex items-center' +
            (instrument.isDefault || isExpired
              ? ' justify-end '
              : ' justify-between')
          }
        >
          {!instrument.isDefault && !isExpired && (
            <SimpleButton
              className="py-1 px-2 flex items-center gap-2 justify-start"
              onClick={async () => {
                try {
                  enableUIBlocker();
                  hideSection();
                  await changeDefaultPaymentInstrument(instrument.id);
                  searchParams.set('status', RedirectStatus.Success);
                  setSearchParams(searchParams);
                  window.location.reload();
                } catch (error) {
                  errorToast(
                    t('Something went wrong!'),
                    t('Failed to make this payment method a default one.')
                  );
                } finally {
                  disableUIBlocker();
                }
              }}
            >
              <span className="flex gap-2 opacity-80  hover:opacity-100">
                <CheckMarkIcon /> {t('Make default')}
              </span>
            </SimpleButton>
          )}

          {isDeletable(instrument) && (
            <SimpleButton
              className="py-1 px-2 flex items-center gap-2 justify-start"
              onClick={() => {
                setRemovingInstrument(true);
              }}
            >
              <span className="flex gap-2 opacity-80  hover:opacity-100">
                <DeleteIcon />
                {t('Delete')}
              </span>
            </SimpleButton>
          )}
        </div>
      </div>

      <ConfirmationModal
        title={t('Are you sure you want to remove this payment method?')}
        description={
          <>
            <Paragraph ellipsisOnOverflow>
              <span className="mr-xs">
                <PaymentIcon icon={instrument.iconName} />
              </span>
              {instrumentName}
            </Paragraph>
            {instrument.expirationDate && (
              <span className="text-sm text-silver">
                <Paragraph ellipsisOnOverflow>
                  {`${t('Valid until')} ${instrument.expirationDate}`}
                </Paragraph>
              </span>
            )}
          </>
        }
        confirmBtnTitle={t('Remove')}
        cancelBtnTitle={t('Cancel')}
        isOpen={removingInstrument}
        onClose={() => {
          setRemovingInstrument(false);
          hideSection();
        }}
        onConfirm={async () => {
          try {
            enableUIBlocker();
            setRemovingInstrument(false);
            hideSection();
            await deletePaymentInstrument(instrument.id);
            searchParams.set('status', RedirectStatus.Success);
            setSearchParams(searchParams);
            window.location.reload();
          } catch (error) {
            setRemovingInstrument(false);
            errorToast(
              t('Something went wrong!'),
              t('Failed to delete payment method.')
            );
          } finally {
            disableUIBlocker();
          }
        }}
      />
    </>
  );
};
