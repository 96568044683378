import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import { FC } from 'react';

export const PayWithBankTransferBtn: FC = () => {
  return (
    <div className="flex">
      <PayButton
        onClick={() => {
          NavigationService.navigateTo(InternalPage.BankTransfer);
        }}
      />
    </div>
  );
};
