import { LoadingSpinner, LoadingSpinnerSize } from 'main/components/common';
import { FC, useEffect } from 'react';

import { UIBlockerProps } from './schemas';

const styles = {
  backgroundContainer:
    'fixed inset-0 z-50 overflow-hidden bg-secondary opacity-80',
  foregroundContainer: 'fixed inset-0 z-60 cursor-not-allowed',
  spinnerContainer: 'flex flex-col justify-center h-full',
};

export const UIBlockerContent: FC<UIBlockerProps> = ({ blocked = false }) => {
  const trapFocus = (e: Event) => e.preventDefault();

  useEffect(() => {
    if (blocked) {
      document.addEventListener('keydown', trapFocus);
    } else {
      document.removeEventListener('keydown', trapFocus);
    }

    return () => document.removeEventListener('keydown', trapFocus);
  }, [blocked]);

  if (!blocked) {
    return null;
  }

  return (
    <div>
      <div className={styles.backgroundContainer}></div>
      <div className={styles.foregroundContainer}>
        <div className={styles.spinnerContainer}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
      </div>
    </div>
  );
};
