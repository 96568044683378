import { CommonAlerts } from '../utils/CommonAlerts';
import { CommonBadges } from '../utils/CommonBadges';
import { CommonButtons } from '../utils/CommonButtons';
import { CommonHeadings } from '../utils/CommonHeadings';
import { CommonIcons } from '../utils/CommonIcons';
import { CommonLayouts } from '../utils/CommonLayouts';
import { CommonLogos } from '../utils/CommonLogos';
import { CommonModals } from '../utils/CommonModals';
import { CommonText } from '../utils/CommonText';
import { LanguageSelector } from '../utils/LanguageSelector';
import { ToasterButtons } from '../utils/ToasterButtons';

export function TestUiElements() {
  return (
    <>
      <h1 className="font-bold text-xl mt-sm">UI ELEMENTS</h1>
      <CommonLayouts />
      <CommonLogos />
      <CommonText />
      <CommonBadges />
      <CommonButtons />
      <CommonHeadings />
      <CommonIcons />
      <LanguageSelector />
      <ToasterButtons />
      <CommonModals />
      <CommonAlerts />
    </>
  );
}
