import { Startupable } from 'main/services/base/Startupable';
import { isProdBuild } from 'main/utils/env';

interface ILogger {
  error(...data: unknown[]): void;
  info(...data: unknown[]): void;
  log(...data: unknown[]): void;
  warn(...data: unknown[]): void;
  debug(...data: unknown[]): void;
}

const BlockedOriginsKey = 'pmi.logger.blacklist';

class Logger implements Startupable {
  public readonly name = 'Logger';

  private _blockedOrigins: LoggerOrigin[] = [];
  private _observers: ((blocked: LoggerOrigin[]) => void)[] = [];
  private _logger: ILogger | undefined;

  public startup(): Promise<void> {
    if (!isProdBuild && window.sessionStorage.getItem(BlockedOriginsKey)) {
      try {
        this._blockedOrigins = JSON.parse(
          window.sessionStorage.getItem(BlockedOriginsKey) as string
        ) as LoggerOrigin[];
      } catch (err) {
        // If it fails, default to empty array
        this._blockedOrigins = [];
        this.updateStorage();
      }
    } else {
      this._blockedOrigins = [];
      this.updateStorage();
    }

    if (!isProdBuild) {
      this.setLogger(console);
    }

    // Expose the logger for extra debugging details
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)['pmi.web.payments'] = {
      Logger: this,
    };

    return Promise.resolve();
  }

  public warn(origin: LoggerOrigin, msg: string, ...values: unknown[]) {
    this.outputDebuggingInformation('warn', origin, msg, ...values);
  }

  public error(origin: LoggerOrigin, msg: string, ...values: unknown[]) {
    this.outputDebuggingInformation('error', origin, msg, ...values);
  }

  public log(origin: LoggerOrigin, msg: string, ...values: unknown[]) {
    this.outputDebuggingInformation('log', origin, msg, ...values);
  }

  public info(origin: LoggerOrigin, msg: string, ...values: unknown[]) {
    this.outputDebuggingInformation('info', origin, msg, ...values);
  }

  public debug(origin: LoggerOrigin, msg: string, ...values: unknown[]) {
    this.outputDebuggingInformation('debug', origin, msg, ...values);
  }

  public disableOrigin(origin: LoggerOrigin) {
    if (!this._blockedOrigins.includes(origin)) {
      this._blockedOrigins.push(origin);
      this.updateStorage();
      this.info(LoggerOrigin.LoggerService, `Disabled origin "${origin}"`);
    }
  }

  public enableOrigin(origin: LoggerOrigin) {
    const index = this._blockedOrigins.findIndex((o) => o === origin);
    if (index !== -1) {
      this._blockedOrigins.splice(index, 1);
      this.updateStorage();
      this.info(LoggerOrigin.LoggerService, `Enabled origin "${origin}"`);
    }
  }

  public subscribe(callback: (currentPage: LoggerOrigin[]) => void) {
    this._observers.push(callback);
    callback(this._blockedOrigins);
  }

  private updateStorage() {
    window.sessionStorage.setItem(
      BlockedOriginsKey,
      JSON.stringify(this._blockedOrigins)
    );
    this.notifyObservers();
  }

  private notifyObservers() {
    this._observers.forEach((cb) => cb(this._blockedOrigins));
  }

  private outputDebuggingInformation(
    logger: 'error' | 'warn' | 'log' | 'info' | 'debug',
    origin: LoggerOrigin,
    msg: string,
    ...values: unknown[]
  ) {
    if (!this._logger || this._blockedOrigins.includes(origin)) {
      return;
    }

    this._logger[logger](
      `[${new Date().toUTCString()}][${origin}] ${msg}`,
      ...values
    );
  }

  private setLogger(logger: ILogger): void {
    this._logger = logger;
  }
}

export enum LoggerOrigin {
  // Keep this list sorted!
  ApplePay = 'ApplePay',
  AuthenticationService = 'AuthenticationService',
  AuthenticationServiceClient = 'AuthenticationServiceClient',
  BraintreeApplePayService = 'BraintreeApplePayService',
  BraintreeGooglePayService = 'BraintreeGooglePayService',
  BraintreeCardService = 'BraintreeCardService',
  ErrorBoundaries = 'ErrorBoundaries',
  LoggerService = 'LoggerService',
  PayPalButton = 'PayPalButton',
  NavigationService = 'NavigationService',
  ReactComponent = 'ReactComponent',
  RequestUtils = 'RequestUtils',
  ServiceBootstrapper = 'ServiceBootstrapper',
  ServiceRegistrar = 'ServiceRegistrar',
  StartupHelper = 'StartupHelper',
  TelemetryService = 'TelemetryService',
  TranslationService = 'TranslationService',
}

export default new Logger();
