import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { redirect } from 'main/utils/redirect';
import { useTranslation } from 'react-i18next';

import { SimpleButton } from '../../../common';

export function CancelPaymentButton() {
  const { t } = useTranslation();

  const url = PaymentStore.cancelUrl;

  if (url === undefined) {
    return null;
  }
  return (
    <SimpleButton
      onClick={() => {
        TelemetryService.trackEvent({
          name: 'CancelPaymentBtn',
          properties: {
            pid: PaymentStore.paymentIntentId,
            flow: PaymentStore.flow,
          },
        });
        redirect(url);
      }}
    >
      {t('Cancel payment')}
    </SimpleButton>
  );
}
