import { PageLayout } from 'main/components/common';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const CommonLayouts: FC = () => {
  return (
    <DemoContainer title={'PAGE LAYOUTS'}>
      <PageLayout headerDescription="header description">
        Content goes here
      </PageLayout>
    </DemoContainer>
  );
};
