export class ApiError extends Error {
  public readonly status: number;
  public readonly statusText: string;

  constructor(
    status: number,
    statusText: string,
    message: string,
    traceId?: string
  ) {
    const msg = `${status} ${statusText}\n${message}`;
    if (traceId !== undefined && traceId !== null) {
      super(`${msg}\n${traceId}`);
    }

    super(msg);
    this.status = status;
    this.statusText = statusText;
    this.name = 'ApiError';
  }
}
