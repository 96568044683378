import { FC } from 'react';

type DeleteIconProps = {
  className?: string;
};

export const DeleteIcon: FC<DeleteIconProps> = ({ className }) => {
  return (
    <svg
      width={16}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      className={
        'self-center inline-block' + (className ? ` ${className}` : '')
      }
    >
      <g fill="currentColor">
        <path d="M2,6v8c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V6H2z"></path>
        <path d="M12,3V1c0-0.6-0.4-1-1-1H5C4.4,0,4,0.4,4,1v2H0v2h16V3H12z M10,3H6V2h4V3z"></path>
      </g>
    </svg>
  );
};
