import { SupportedLocale } from 'main/i18n';

import { TwoCTwoPLocale } from './schemas/TwoCTwoPLocale';

export const TwoCTwoPSupportedLocale: {
  [key in SupportedLocale]: TwoCTwoPLocale;
} = {
  [SupportedLocale.Bulgarian]: 'en',
  [SupportedLocale.Czech]: 'en',
  [SupportedLocale.ChineseSimplifiedChina]: 'zh-cn',
  [SupportedLocale.Danish]: 'en',
  [SupportedLocale.Dutch]: 'en',
  [SupportedLocale.English]: 'en',
  [SupportedLocale.Finnish]: 'en',
  [SupportedLocale.French]: 'fr',
  [SupportedLocale.German]: 'de',
  [SupportedLocale.Hungarian]: 'en',
  [SupportedLocale.Indonesian]: 'id',
  [SupportedLocale.Italian]: 'it',
  [SupportedLocale.Japanese]: 'ja',
  [SupportedLocale.Korean]: 'ko',
  [SupportedLocale.Norwegian]: 'en',
  [SupportedLocale.Polish]: 'en',
  [SupportedLocale.Portuguese]: 'pt',
  [SupportedLocale.Russian]: 'ru',
  [SupportedLocale.Spanish]: 'es',
  [SupportedLocale.Swedish]: 'en',
  [SupportedLocale.Thai]: 'th',
  [SupportedLocale.Turkish]: 'en',
  [SupportedLocale.ChineseTraditionalTaiwan]: 'zh-tw',
  [SupportedLocale.Ukrainian]: 'en',
};
