import type {
  IPageViewTelemetry,
  IMetricTelemetry,
} from '@microsoft/applicationinsights-common';
import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import type {
  ICustomProperties,
  IEventTelemetry,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import * as StartupHelper from 'main/services/base/StartupHelper';
import { TelemetryEnvelopeUpdateError } from 'main/services/telemetry/errors/TelemetryEnvelopeUpdateError';
import { AppVersion } from 'main/utils/version';

import { Startupable } from '../base/Startupable';
import Logger, { LoggerOrigin } from '../Logger';
import PaymentStore from '../payments/PaymentStore';

class TelemetryService implements Startupable {
  public readonly name = 'TelemetryService';

  private _appInsights: ApplicationInsights | undefined;

  constructor() {
    StartupHelper.registerStartupable(this, []);
    this._appInsights = undefined;
  }

  public startup(): Promise<void> {
    this._appInsights = new ApplicationInsights({
      config: {
        // Client
        instrumentationKey: process.env.REACT_APP_AI_KEY as string,
        appId: process.env.REACT_APP_AI_APP_NAME,
      },
    });

    this._appInsights.addTelemetryInitializer(this.telemetryInitializer);
    this._appInsights.loadAppInsights();
    this.trackPageView();
    Logger.log(
      LoggerOrigin.TelemetryService,
      'AppInsights started with user consent'
    );

    return Promise.resolve();
  }

  public trackEvent(
    event: IEventTelemetry,
    customProperties?: ICustomProperties
  ) {
    this._appInsights?.trackEvent(event, customProperties);
  }

  public trackException(
    exception: Error | undefined,
    from: string,
    customProperties?: Record<string, unknown>
  ) {
    this._appInsights?.trackException({
      error: exception,
      exception: exception,
      severityLevel: SeverityLevel.Error,
      properties: {
        from,
        type: exception?.name,
        cause: exception?.cause ?? undefined,
        causeDetails: exception?.cause,
        ...customProperties,
      },
    });
  }

  public trackPageView(pageView?: IPageViewTelemetry) {
    this._appInsights?.trackPageView(pageView);
  }

  public trackMetric(metric: IMetricTelemetry) {
    this._appInsights?.trackMetric(metric);
  }

  private telemetryInitializer(envelope: ITelemetryItem) {
    try {
      if (envelope.baseData) {
        envelope.baseData.properties = {
          ...envelope.baseData.properties,
          'app.name': process.env.REACT_APP_AI_APP_NAME,
          'app.version': AppVersion,
          pid: PaymentStore.paymentIntentId,
        };
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (envelope as any).tags['ai.cloud.role'] =
        process.env.REACT_APP_AI_APP_NAME;
    } catch (err) {
      Logger.error(
        LoggerOrigin.TelemetryService,
        'Failed to update telemetry envelope!',
        new TelemetryEnvelopeUpdateError(err as Error)
      );
    }
  }
}

export default new TelemetryService();
