import { AppTheme } from 'main/schemas/Theme';
import { noop } from 'main/utils/noop';
import { createContext, useContext } from 'react';

interface ThemeContextValues {
  readonly theme: AppTheme;
  readonly setTheme: (theme: AppTheme) => void;
}

export const ThemeContext = createContext<ThemeContextValues>({
  theme: AppTheme.PM,
  setTheme: noop,
});

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be used within ThemeProvider');
  }
  return context;
};
