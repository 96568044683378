import { InputRadioOption } from 'main/components/common/forms/inputs/RadioGroup';
import {
  InstrumentStatus,
  PaymentInstrument,
} from 'main/schemas/PaymentInstrument';
import { isDirectDebitLsvOrMpmSepa } from 'main/services/payments/utils/InstrumentUtils';

import { StoredInstrument } from './StoredInstrument';

export function computeRadioOptions(
  instruments: ReadonlyArray<PaymentInstrument>
): InputRadioOption[] {
  return instruments.map((i) => ({
    id: i.id.toString(),
    label: <StoredInstrument {...i} />,
    value: i.id.toString(),
    disabled: i.status !== InstrumentStatus.Valid,
  }));
}

export function isDeletable(instrument: PaymentInstrument) {
  return !isDirectDebitLsvOrMpmSepa(instrument.moduleId);
}
