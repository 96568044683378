import { StartupableInfo } from '../Startupable';

export class StartupableCycleError extends Error {
  constructor(details: StartupableInfo[]) {
    const errorDescription = ''
      .concat(
        'Cannot start list of startupables. ',
        'Detected a dependency cycle. \n',
        details
          .map(
            (info) =>
              `${info.service.name} -> [${info.dependencies
                .map((dep) => dep.name)
                .join(', ')}]`
          )
          .join('\n')
      )
      .trim();

    super(errorDescription);
    this.name = 'StartupableCycleError';
  }
}
