import { loadScript } from 'main/hooks/loadScript';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import { registerStartupable } from 'main/services/base/StartupHelper';

import AuthenticationService from '../auth/AuthenticationService';
import { OptionalStartupable } from '../base/Startupable';
import PaymentStore from '../payments/PaymentStore';
import { getManageNuveiConfiguration, getNuveiConfiguration } from './NuveiApi';
import { NuveiConfigurationApiResponse } from './NuveiConfigurationApiResponse';
import { NuveiCardCreatePaymentInput } from './NuveiCreatePaymentInput';
import { NuveiTransactionResponse as NuveiTransactionResponse } from './NuveiTransactionResponse';

export const NUVEI_CARDS_CANCEL_ERROR_CODE = '1156';
export const NUVEI_TWINT_CANCEL_ERROR_CODE = '9072';

interface NuveiSafeCharge {
  readonly createPayment: (
    options: NuveiCardCreatePaymentInput,
    handler: (params: NuveiTransactionResponse) => void
  ) => Promise<NuveiTransactionResponse>;

  fields: (options: { fonts: FontOptions[]; locale: string }) => FieldBuilder;
}

interface FontOptions {
  cssUrl: string;
}

interface FieldBuilder {
  create: (
    type: 'card' | 'ccNumber' | 'ccExpiration' | 'ccCvc',
    config?: FieldConfig | any
  ) => any;
}

interface FieldConfig {
  style: {
    /* Define your specific style properties */
  };
}

declare global {
  interface Window {
    readonly SafeCharge: (argument: any) => NuveiSafeCharge;
  }
}

class NuveiCardService implements OptionalStartupable {
  private _nuveiCardConfiguration!: NuveiConfigurationApiResponse;
  private _safeCharge!: NuveiSafeCharge;

  public readonly name = 'NuveiCardService';
  public readonly moduleId = PaymentModuleId.NuveiCreditCard;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public async startup(): Promise<void> {
    /*For Nuvei it is important to regenerate clientUniqueId every time we do attempt to pay, so API call to Payment service should be done on every button click */
  }

  public shouldStart(): boolean {
    const isAllowed = PaymentStore.allowedPaymentModules.includes(
      this.moduleId
    );

    const hasAtLeastOneInstrument =
      PaymentStore.instruments.find((i) => i.moduleId === this.moduleId) !==
      undefined;

    return isAllowed || hasAtLeastOneInstrument;
  }

  private async initTransaction(paymentInstrumentId?: number): Promise<void> {
    let nuveiCardConfiguration;
    if (PaymentStore.flow === PaymentIntentFlow.Manage) {
      const customerNumber = AuthenticationService.user.tpId;
      if (customerNumber === undefined) {
        throw new Error('customerNumber is undefined!');
      }

      if (PaymentStore.legalEntity === undefined) {
        throw new Error('Legal entity cannot be undefined');
      }

      nuveiCardConfiguration = await getManageNuveiConfiguration(
        PaymentModuleId.NuveiCreditCard,
        PaymentStore.legalEntity,
        customerNumber
      );
    } else {
      nuveiCardConfiguration = await getNuveiConfiguration(
        PaymentStore.paymentIntentId,
        PaymentModuleId.NuveiCreditCard,
        paymentInstrumentId
      );
    }

    if (nuveiCardConfiguration === undefined) {
      throw new Error('Nuvei card transaction configuration has no value!');
    }
    this._nuveiCardConfiguration = nuveiCardConfiguration;
  }

  private initSafeCharge(): Promise<void> {
    return loadScript(
      'https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js'
    ).then(() => {
      this._safeCharge = window.SafeCharge({
        sessionToken: this._nuveiCardConfiguration.sessionToken,
        env: this._nuveiCardConfiguration.environment,
        merchantId: this._nuveiCardConfiguration.merchantId,
        merchantSiteId: this._nuveiCardConfiguration.merchantSiteId,
      });
    });
  }

  public async createPayment(
    cardNumberField: any,
    cardHolderName: string
  ): Promise<NuveiTransactionResponse> {
    return new Promise((resolve, reject) => {
      this._safeCharge.createPayment(
        {
          sessionToken: this._nuveiCardConfiguration.sessionToken,
          cardHolderName: cardHolderName,
          paymentOption: cardNumberField,
          billingAddress: {
            email: PaymentStore.customerEmail,
            country: PaymentStore.customerCountryCode,
          },
          alwaysCollectCvv: 'optional',
        },
        (response) =>
          this.createPaymentResponseHandler(response, reject, resolve)
      );
    });
  }

  public async createCITPayment(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._safeCharge.createPayment(
        {
          sessionToken: this._nuveiCardConfiguration.sessionToken,
          userTokenId: AuthenticationService.user.tpId,
          paymentOption: {
            userPaymentOptionId:
              this._nuveiCardConfiguration.userPaymentOptionId,
          },
          billingAddress: {
            email: PaymentStore.customerEmail,
            country: PaymentStore.customerCountryCode,
          },
          alwaysCollectCvv: 'optional',
          //deviceDetails - not provided
        },
        (response) => {
          this.createPaymentResponseHandlerForCIT(response)
            .then(resolve)
            .catch(reject);
        }
      );
    });
  }

  public async initTransactionAndSafeCharge(
    instrumentId?: number
  ): Promise<NuveiSafeCharge> {
    await this.initTransaction(instrumentId);
    await this.initSafeCharge();

    return Promise.resolve(this._safeCharge);
  }

  public createPaymentResponseHandler(
    params: NuveiTransactionResponse,
    reject: (params: NuveiTransactionResponse) => void,
    resolve: (params: NuveiTransactionResponse) => void
  ): void {
    const responseWithConvertedError: NuveiTransactionResponse = {
      ...params,
      errCode:
        typeof params.errCode === 'string'
          ? params.errCode
          : `${params.errCode}`,
    };

    if (
      responseWithConvertedError.result === 'ERROR' ||
      responseWithConvertedError.result === 'DECLINED'
    ) {
      //cancel, failure?
      reject(responseWithConvertedError);
    } else {
      //success
      resolve(responseWithConvertedError);
    }
  }

  public createPaymentResponseHandlerForCIT(
    params: NuveiTransactionResponse
  ): Promise<void> {
    const responseWithConvertedError: NuveiTransactionResponse = {
      ...params,
      errCode:
        typeof params.errCode === 'string'
          ? params.errCode
          : `${params.errCode}`,
    };

    if (
      responseWithConvertedError.result === 'ERROR' ||
      responseWithConvertedError.result === 'DECLINED'
    ) {
      //cancel, failure?
      return Promise.reject(responseWithConvertedError);
    } else {
      //success
      return Promise.resolve();
    }
  }

  public isNuveiError(obj: any): obj is NuveiTransactionResponse {
    if (
      obj &&
      typeof obj === 'object' &&
      'result' in obj &&
      (obj.result === 'ERROR' || obj.result === 'DECLINED')
    ) {
      return true;
    }
    return false;
  }
}

export default new NuveiCardService();
