import { ParentComponent } from 'main/schemas/ParentComponent';
import { FC } from 'react';

interface TooltipProps extends ParentComponent {
  readonly tooltip: JSX.Element;
}

export const Tooltip: FC<TooltipProps> = ({ tooltip, children }) => {
  return (
    <div className="tooltip">
      {tooltip}
      <span className="tooltiptext">{children}</span>
    </div>
  );
};
