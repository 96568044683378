import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DemoContainer } from './renderers';

export const SearchParams: FC = () => {
  const [searchParams] = useSearchParams();

  return (
    <DemoContainer title={'LOCATION SERVICE INFORMATION'}>
      <p>Nonce token: {searchParams.get('nonceToken')}</p>
      <p>Payment intent id: {searchParams.get('pid')}</p>
      <p>
        Telemetry consent:{' '}
        {searchParams.get('tc')?.toLocaleLowerCase() === 'true'
          ? 'enabled'
          : 'disabled'}
      </p>
      <p>Locale: {searchParams.get('locale')}</p>
      <p>Status: {searchParams.get('status')}</p>
    </DemoContainer>
  );
};
