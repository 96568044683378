import { useThemeContext } from 'main/components/contexts/theme/ThemeContext';
import { AppTheme } from 'main/schemas/Theme';
import { FC } from 'react';

import { DemoContainer } from '../utils/renderers';

export const TestTheme: FC = () => {
  const { theme, setTheme } = useThemeContext();

  return (
    <>
      <h1 className="font-bold text-xl mt-sm">THEME</h1>
      <DemoContainer title={'CURRENT THEME'}>
        <p>{`Applied theme: ${theme}`}</p>
        <button
          className="border border-1 p-2 m-2"
          onClick={() => {
            if (theme !== AppTheme.PM) {
              setTheme(AppTheme.PM);
            }
          }}
        >
          Apply PM theme
        </button>
        <button
          className="border border-1 p-2 m-2"
          onClick={() => {
            if (theme !== AppTheme.Fitline) {
              setTheme(AppTheme.Fitline);
            }
          }}
        >
          Apply Fitline theme
        </button>
      </DemoContainer>
    </>
  );
};
