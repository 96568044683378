import { LoadingSpinner, LoadingSpinnerSize } from 'main/components/common';
import { AlertWarning } from 'main/components/common/alerts';
import { RedirectStatus } from 'main/components/contexts/params';
import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { setClarityTag } from 'main/services/clarity';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import TransbankService from 'main/services/transbank/TransbankService';
import { redirect } from 'main/utils/redirect';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const PayViaTransbankBtn: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const paymentModuleT = useUserFriendlyPaymentModuleName();

  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    TransbankService.getRedirectUrl(language as SupportedLocale)
      .then(setRedirectUrl)
      .catch((e: Error) => {
        TelemetryService.trackException(e, 'TransbankBtn');
        Logger.error(
          LoggerOrigin.ReactComponent,
          'Transbank URL fetch failure',
          e
        );
        setError(e);
        setClarityTag('pay', [
          RedirectStatus.Failure,
          paymentModuleT(PaymentModuleId.Transbank),
        ]);
      })
      .finally(() => setLoading(false));
  }, [language, paymentModuleT]);

  if (error) {
    return (
      <AlertWarning
        title={t('Something went wrong!')}
        description={t('Transbank payments are currently unavailable.')}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="h-14">
        <LoadingSpinner size={LoadingSpinnerSize.Small} />
      </div>
    );
  }

  return (
    <div className="flex">
      <PayButton onClick={() => redirect(redirectUrl)} />
    </div>
  );
};
