/* eslint-disable import/order */
import { FC, useEffect, useRef } from 'react';

import { injectMicrosoftClarityScript } from 'main/services/clarity';
import { isStartupableStarted } from 'main/services/base/StartupHelper';
import TelemetryService from 'main/services/telemetry/TelemetryService';

/**
 * Component that injects the scripts required by the MicroSoft Clarity Telemetry service
 * @returns null
 */
const ClarityInjector: FC = () => {
  const isClarityInjectedRef = useRef<boolean>(false);

  const isTelemetryConsentGiven = isStartupableStarted(TelemetryService);

  useEffect(() => {
    if (!isClarityInjectedRef.current) {
      injectMicrosoftClarityScript(
        window,
        window.document,
        'clarity',
        'script',
        process.env.REACT_APP_MS_CLARITY_ID ?? ''
      );

      isClarityInjectedRef.current = true;

      // log notification only on localhost
      if (process.env.NODE_ENV === 'development')
        console.info(
          '%cMSC%c injected',
          'color: teal; font-weight: bold; font-size: 0.75rem;',
          'color: unset; font-weight: unset; font-size: unset;'
        );
    }
  }, []);

  /**
   * activate cookie tracking by MS Clarity if consent provided
   * https://learn.microsoft.com/en-us/clarity/setup-and-installation/cookie-consent
   */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isTelemetryConsentGiven && window?.clarity) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.clarity('consent');

      // log notification only on localhost
      if (process.env.NODE_ENV === 'development')
        console.info(
          '%cMSC%c cookies activated',
          'color: teal; font-weight: bold; font-size: 0.75rem;',
          'color: unset; font-weight: unset; font-size: unset;'
        );
    }
  }, [isTelemetryConsentGiven]);

  return null;
};

export default ClarityInjector;
