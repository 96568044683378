import { ApplePay } from 'braintree-web';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { registerStartupable } from 'main/services/base/StartupHelper';
import { deferredPromise } from 'main/utils/promises';

import { OptionalStartupable } from '../base/Startupable';
import Logger, { LoggerOrigin } from '../Logger';
import PaymentStore from '../payments/PaymentStore';
import { BraintreeClient } from './BraintreeClient';
import { UnsupportedDeviceError } from './errors/UnsupportedDeviceError';
import { isApplePaySupported } from './helpers/ApplePayHelper';
import { createBraintreeApplePayClient } from './helpers/BraintreeSdkWrapper';

class BraintreeApplePayService implements OptionalStartupable {
  public readonly name = 'BraintreeApplePayService';
  public readonly moduleId = PaymentModuleId.BraintreeApplePay;

  private _instance: ApplePay | undefined;
  private _braintreeClient!: BraintreeClient;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public async startup(): Promise<void> {
    if (!isApplePaySupported()) {
      throw new UnsupportedDeviceError(this.name);
    }

    const defaultToken = await PaymentStore.getBraintreeDefaultToken();
    if (defaultToken === undefined) {
      throw new Error('Default token has no value!');
    }

    this._braintreeClient = new BraintreeClient(this.moduleId, defaultToken);
    await this._braintreeClient.init();

    this._instance = await createBraintreeApplePayClient(
      this._braintreeClient.client
    );

    Logger.log(
      LoggerOrigin.BraintreeApplePayService,
      'Apple pay over braintree is ready'
    );
  }

  public shouldStart(): boolean {
    const isAllowed = PaymentStore.allowedPaymentModules.includes(
      this.moduleId
    );

    return isAllowed && isApplePaySupported();
  }

  public authorize() {
    if (!this._instance) {
      return Promise.reject('Instance not available');
    }

    Logger.log(
      LoggerOrigin.BraintreeApplePayService,
      '... creating Apple Pay payment request'
    );

    const request = this._instance.createPaymentRequest({
      total: {
        label: 'PM International',
        amount: PaymentStore.orderTotal.toString(),
      },
      currencyCode: PaymentStore.currency,
    });

    Logger.log(
      LoggerOrigin.BraintreeApplePayService,
      '... creating ApplePaySession'
    );

    // TODO: Define proper typings
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowWithApplePay = window as any;
    const session = new windowWithApplePay.ApplePaySession(3, request);

    Logger.log(
      LoggerOrigin.BraintreeApplePayService,
      '... attaching event listners to session'
    );

    const deferred = deferredPromise<void>();

    // TODO: Define proper typings
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    session.onvalidatemerchant = (event: any) => {
      this._instance
        ?.performValidation({
          validationURL: event.validationURL,
          displayName: 'PM International',
        })
        .then((merchantSession) =>
          session.completeMerchantValidation(merchantSession)
        )
        .catch((err) => {
          Logger.error(
            LoggerOrigin.BraintreeApplePayService,
            'Error while validating merchant',
            err
          );

          session.abort();

          Logger.log(
            LoggerOrigin.BraintreeApplePayService,
            '... session aborted',
            err
          );
          deferred.reject(err);
        });
    };

    // TODO: Define proper typings
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    session.onpaymentauthorized = (event: any) => {
      this._instance
        ?.tokenize({
          token: event.payment.token,
        })
        .then((payload) =>
          this._braintreeClient.authorize(
            PaymentStore.paymentIntentId,
            payload.nonce
          )
        )
        .then(() => {
          // Dismiss the Apple Pay sheet.
          session.completePayment(
            windowWithApplePay.ApplePaySession.STATUS_SUCCESS
          );

          Logger.log(
            LoggerOrigin.BraintreeApplePayService,
            '... session was completed'
          );
        })
        .then(() => deferred.resolve())
        .catch((tokenizeErr) => {
          Logger.error(
            LoggerOrigin.BraintreeApplePayService,
            'Error tokenizing Apple Pay:',
            tokenizeErr
          );

          session.completePayment(
            windowWithApplePay.ApplePaySession.STATUS_FAILURE
          );
          deferred.reject(tokenizeErr);
        });
    };

    session.oncancel = () => {
      Logger.log(
        LoggerOrigin.BraintreeApplePayService,
        '... session was cancelled'
      );

      deferred.reject();
    };

    Logger.log(LoggerOrigin.BraintreeApplePayService, '... starting session');

    // Open Apple Pay sheet
    session.begin();

    return deferred.promise;
  }
}

export default new BraintreeApplePayService();
