import { disableUIBlocker, PageLayout } from 'main/components/common';
import { AlertLoading } from 'main/components/common/alerts';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface PaymentProcessingProps {
  readonly title: string;
  readonly orderId?: string;
}

export const PaymentProcessing: FC<PaymentProcessingProps> = ({
  title,
  orderId,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    disableUIBlocker();
  }, []);

  return (
    <PageLayout
      headerDescription={orderId ? `${t('Order')}: ${orderId}` : undefined}
    >
      <AlertLoading title={title} />
    </PageLayout>
  );
};
