import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { execGet } from 'main/services/utils/RequestUtils';

import { NuveiConfiguration as NuveiConfiguration } from './NuveiConfiguration';
import { NuveiConfigurationApiResponse as NuveiConfigurationApiResponse } from './NuveiConfigurationApiResponse';

export async function getNuveiConfiguration(
  paymentIntentId: string,
  paymentDataTypeId: PaymentModuleId,
  paymentInstrumentId?: number
) {
  const searchParams = new URLSearchParams({
    paymentDataTypeId: paymentDataTypeId.toString(),
  });

  if (paymentInstrumentId !== undefined) {
    searchParams.append('paymentInstrumentId', paymentInstrumentId.toString());
  }

  const response = await execGet<NuveiConfigurationApiResponse>(
    `paymentintent/${paymentIntentId}/nuvei/configuration?${searchParams.toString()}`
  );

  const convertedResponse: NuveiConfiguration = { ...response };

  return convertedResponse;
}

export async function getManageNuveiConfiguration(
  paymentDataTypeId: PaymentModuleId,
  legalEntityNumber: number,
  custonerNumber: string
) {
  const searchParams = new URLSearchParams({
    paymentDataTypeId: paymentDataTypeId.toString(),
    legalEntityNumber: legalEntityNumber.toString(),
    customerNumber: custonerNumber,
  });

  const response = await execGet<NuveiConfigurationApiResponse>(
    `paymentinstruments/nuvei/configuration?${searchParams.toString()}`
  );

  const convertedResponse: NuveiConfiguration = { ...response };

  return convertedResponse;
}
