export function DirectDebit(className: string) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      <g>
        <rect x="6" y="20" width="6" height="26" fill="#aeaeae"></rect>
        <rect x="16" y="20" width="6" height="26" fill="#aeaeae"></rect>
        <rect x="26" y="20" width="6" height="26" fill="#aeaeae"></rect>
        <rect x="36" y="20" width="6" height="26" fill="#aeaeae"></rect>
        <path
          d="M46.479,15.122l-22-12a1,1,0,0,0-.958,0l-22,12A1,1,0,0,0,1,16v6a1,1,0,0,0,1,1H46a1,1,0,0,0,1-1V16A1,1,0,0,0,46.479,15.122Z"
          fill="#e3e3e3"
        ></path>
        <path
          d="M46,43H2a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1H46a1,1,0,0,0,1-1V44A1,1,0,0,0,46,43Z"
          fill="#e3e3e3"
        ></path>
        <circle cx="24" cy="14" r="4" fill="#949494"></circle>
        <rect x="6" y="23" width="6" height="3" fill="#949494"></rect>
        <rect x="16" y="23" width="6" height="3" fill="#949494"></rect>
        <rect x="26" y="23" width="6" height="3" fill="#949494"></rect>
        <rect x="36" y="23" width="6" height="3" fill="#949494"></rect>
      </g>
    </svg>
  );
}
