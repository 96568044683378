import cn from 'clsx';
import { FC } from 'react';

import { BasicButton } from './BasicButton';
import type { IButtonProps } from './schemas';

const styles = {
  colors: 'bg-primary text-white border-primary',
  spacing: 'py-3.5 px-6',
  shape: 'border-2 rounded-button',
  flexShape: 'flex-1',
  font: 'font-bold text-center',
  hover: 'filter hover:bg-primary-interaction',
  focus: 'filter focus:bg-primary-interaction',
  active: 'filter active:bg-primary-interaction',
  postion: 'relative',
};

export const PrimaryButton: FC<IButtonProps> = ({
  flex = true,
  disabled,
  onClick,
  className,
  ariaLabel,
  children,
}) => (
  <BasicButton
    onClick={onClick}
    disabled={disabled}
    ariaLabel={ariaLabel}
    className={cn(
      styles.colors,
      styles.spacing,
      styles.shape,
      styles.postion,
      flex && styles.flexShape,
      styles.font,
      !disabled && styles.hover,
      !disabled && styles.focus,
      !disabled && styles.active,
      disabled && 'cursor-not-allowed disabled',
      className && className
    )}
  >
    {children}
  </BasicButton>
);
