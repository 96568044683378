import {
  errorToast,
  infoToast,
  successToast,
  warningToast,
} from 'main/components/common/toasts/utils';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const ToasterButtons: FC = () => {
  return (
    <DemoContainer title={'TOASTER BUTTONS'}>
      <button
        className="border border-1 p-2 m-2"
        onClick={() => successToast('Success toast', 'Random message')}
      >
        success
      </button>
      <button
        className="border border-1 p-2 m-2"
        onClick={() => infoToast('Info toast', 'Random message')}
      >
        info
      </button>
      <button
        className="border border-1 p-2 m-2"
        onClick={() => warningToast('Warning toast', 'Random message')}
      >
        warning
      </button>
      <button
        className="border border-1 p-2 m-2"
        onClick={() => errorToast('Error toast', 'Random message')}
      >
        error
      </button>
    </DemoContainer>
  );
};
