import { ParentComponent } from 'main/schemas/ParentComponent';
import PaymentStore from 'main/services/payments/PaymentStore';
import { FC, useEffect } from 'react';

import { useThemeContext } from './ThemeContext';

export const ThemedPage: FC<ParentComponent> = ({ children }) => {
  const { setTheme } = useThemeContext();

  useEffect(() => {
    setTheme(PaymentStore.theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};
