import cn from 'clsx';
import { FC } from 'react';

import { BasicButton } from './BasicButton';
import type { IButtonProps } from './schemas';

const styles = {
  colors: 'bg-transparent text-primary border-primary',
  spacing: 'py-3.5 px-6',
  shape: 'border-2 rounded-button',
  flexShape: 'flex-1',
  font: 'font-bold text-center',
  hover: 'filter hover:text-primary-interaction',
  focus: 'filter focus:text-primary-interaction',
  active: 'filter active:text-primary-interaction',
  smallSpacing: 'py-2 px-3',
  smallFont: 'text-xs text-center',
  postion: 'relative',
};

export const SecondaryButton: FC<IButtonProps> = ({
  flex = true,
  disabled,
  isSmall,
  onClick,
  className,
  ariaLabel,
  children,
}) => (
  <BasicButton
    onClick={onClick}
    disabled={disabled}
    aria-label={ariaLabel}
    className={cn(
      styles.colors,
      styles.postion,
      isSmall ? styles.smallSpacing : styles.spacing,
      isSmall && styles.smallFont,
      styles.shape,
      flex && styles.flexShape,
      styles.font,
      !disabled && styles.hover,
      !disabled && styles.focus,
      !disabled && styles.active,
      disabled && 'cursor-not-allowed disabled',
      className && className
    )}
  >
    {children}
  </BasicButton>
);
