import { ConfirmationModal, Modal } from 'main/components/common';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DemoContainer } from './renderers';

export const CommonModals: FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => setModalOpen(false), 3000);
    }
  }, [isModalOpen]);

  return (
    <DemoContainer title={'UI MODALS'}>
      <button
        className="border border-1 p-2 m-2"
        onClick={() => setModalOpen(true)}
      >
        Open modal
      </button>
      <Modal isOpen={isModalOpen}>Modal closes in 3 seconds</Modal>
      <button
        className="border border-1 p-2 m-2"
        onClick={() => setConfirmationModalOpen(true)}
      >
        Open confirmation modal
      </button>
      <ConfirmationModal
        confirmBtnTitle="confirm"
        cancelBtnTitle="cancel"
        description="modal description"
        title="modal title"
        isOpen={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={() => {
          return Promise.reject(
            t('An error occurred. Please contact the support team.')
          );
        }}
      ></ConfirmationModal>
    </DemoContainer>
  );
};
