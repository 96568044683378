export function ECommerceConnect(className: string) {
  return (
    <svg
      className={className}
      width="342"
      height="169"
      viewBox="0 0 342 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M288.394 46.9286H342V0.095003H288.394C264.854 0.095003 244.487 14.7246 234.613 35.9089C224.473 14.1546 202.872 0.095003 179.508 0.095003H120.436V0.28498C120.436 0.189983 120.348 0.095003 120.26 0.095003H93.9861C84.6404 0.095003 77.058 8.26474 77.058 18.3344V63.6481C77.058 73.2428 70.71 82.0776 61.8051 82.9325C51.8422 83.8825 43.4664 75.4278 43.4664 64.8831V0.28498C43.4664 0.0949856 43.3782 0 43.2019 0H0.264517C0.0881832 0 0 0.0949856 0 0.28498V62.4131C0 97.0871 24.3341 127.581 56.4269 129.671C91.4292 131.951 120.436 102.122 120.436 64.8831V46.7386V46.8336H180.478C187.179 46.8336 193.615 50.8235 196.084 57.4733C200.933 70.4879 192.116 82.9325 180.654 82.9325H164.432H137.364C128.019 82.9325 120.436 91.1023 120.436 101.172V129.766V169H164.432V129.766H180.654C204.283 129.766 224.65 115.137 234.524 93.8572C244.399 115.137 264.766 129.766 288.394 129.766H342V82.9325H288.394C279.137 82.9325 271.643 74.8578 271.643 64.8831C271.643 55.0034 279.137 46.9286 288.394 46.9286Z"
        fill="rgb(26 104 250)"
      ></path>
    </svg>
  );
}
