import { Startupable } from 'main/services/base/Startupable';

export class UnknownStartupableError extends Error {
  constructor(service: Startupable) {
    super(
      `All startupables must be registered. Unknown startupable found: ${service.name}`
    );
    this.name = 'UnknownStartupableError';
  }
}
