import { registerStartupable } from 'main/services/base/StartupHelper';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { APP_URL, isProdBuild } from 'main/utils/env';
import { Log, User, UserManager, UserManagerSettings } from 'oidc-client-ts';

const OidcSettings: UserManagerSettings = {
  authority: process.env.REACT_APP_IDSRV_API || '',
  client_id: process.env.REACT_APP_IDSRV_CLIENT_ID || '',
  redirect_uri: `${APP_URL}/${process.env.REACT_APP_IDSRV_REDIRECT_ROUTE}`,
  response_type: 'code',
  scope: process.env.REACT_APP_IDSRV_SCOPE,
  loadUserInfo: true,
};

class AuthenticationService {
  public readonly name = 'AuthenticationService';

  private _userManager: UserManager | undefined;
  private _user: User | undefined;

  constructor() {
    this._userManager = undefined;
    this._user = undefined;
    registerStartupable(this, []);
  }

  public async startup(nonceToken?: string): Promise<void> {
    this.attachLogger();

    this._userManager = new UserManager({
      ...OidcSettings,
      acr_values: nonceToken ? `otp:${nonceToken}` : undefined,
      automaticSilentRenew: nonceToken ? false : true,
    });

    TelemetryService.trackEvent({
      name: `${this.name}-startup`,
      properties: {
        acr_values: this._userManager.settings.acr_values,
        href: window.location.href,
      },
    });

    // Cleanup user details in memory after signout
    this._userManager.events.addUserSignedOut(() => {
      this._user = undefined;
    });

    // Refresh in-memory copy of the user
    this._userManager.events.addUserLoaded((u) => {
      this._user = u;
    });

    return Promise.resolve();
  }

  public async startSignIn(currentState?: string) {
    if (this._userManager === undefined) {
      return;
    }

    this._userManager.signinRedirect({ state: currentState });
  }

  public async finishSignIn(): Promise<string | undefined> {
    if (this._userManager === undefined) {
      return Promise.reject();
    }

    const signedInUser = await this._userManager.signinCallback();

    // If successfully authenticated, restore window location
    if (signedInUser && !signedInUser.expired) {
      let state = undefined;

      if (signedInUser.state && typeof signedInUser.state === 'string') {
        // Restore window location to the state value
        state = signedInUser.state;
      }
      this._user = signedInUser;
      return Promise.resolve(state);
    }

    return Promise.reject();
  }

  public get isSignedIn() {
    return this._user !== undefined;
  }

  public startSignout(signoutUrl?: string, state?: string) {
    const post_logout_redirect_uri = signoutUrl;
    return this._userManager?.signoutRedirect({
      post_logout_redirect_uri,
      state,
    });
  }

  public async finishSignout() {
    if (this._userManager === undefined) {
      return Promise.reject();
    }

    const signoutState = await this._userManager.signoutRedirectCallback();
    return Promise.resolve(signoutState.userState);
  }

  get accessToken() {
    return this._user?.access_token;
  }

  get user() {
    return {
      firstName:
        this._user?.profile.given_name === '---' ||
        this._user?.profile.given_name === ''
          ? undefined
          : this._user?.profile.given_name,
      lastName:
        this._user?.profile.family_name === '---' ||
        this._user?.profile.given_name === ''
          ? undefined
          : this._user?.profile.family_name,
      tpId: this._user?.profile.sub,
      isPreviewUser: ((this._user?.profile['role'] as string) ?? '').includes(
        'payments_preview_user'
      ),
    };
  }

  private attachLogger() {
    Log.setLevel(isProdBuild ? Log.ERROR : Log.DEBUG);
    const customLogger = {
      info: (...args: unknown[]) => {
        Logger.info(LoggerOrigin.AuthenticationServiceClient, '', ...args);
      },
      warn: (...args: unknown[]) => {
        Logger.warn(LoggerOrigin.AuthenticationServiceClient, '', ...args);
      },
      error: (...args: unknown[]) => {
        const err = args.find((i) => i instanceof Error) as Error | undefined;
        TelemetryService.trackException(err, `${this.name}-[OIDC-TS]`, {
          args: args,
        });
        Logger.error(LoggerOrigin.AuthenticationServiceClient, '', ...args);
      },
      debug: (...args: unknown[]) => {
        Logger.log(LoggerOrigin.AuthenticationServiceClient, '', ...args);
      },
    };
    Log.setLogger(customLogger);
  }
}

export default new AuthenticationService();
