import cn from 'clsx';
import {
  CheckMarkIcon,
  InfoIcon,
  WarningIcon,
} from 'main/components/common/icons';
import { toast } from 'react-toastify';
import type {
  ToastContainerProps,
  ToastContent,
  ToastOptions,
} from 'react-toastify';

import { ToastType } from './schemas';
import type { ToastContainerConfig } from './schemas';

export const ToastConfiguration: ToastContainerConfig = {
  [ToastType.Error]: {
    bgColor: 'bg-error',
    textColor: 'text-error',
  },
  [ToastType.Info]: {
    bgColor: 'bg-info',
    textColor: 'text-info',
  },
  [ToastType.Success]: {
    bgColor: 'bg-success',
    textColor: 'text-success',
  },
  [ToastType.Warning]: {
    bgColor: 'bg-warn',
    textColor: 'text-warn',
  },
};

const styles = {
  title: 'font-bold',
  message: 'whitespace-pre-wrap',
  icon: 'w-4 h-5',
};

export const AppToastContainerConfig: ToastContainerProps = {
  closeButton: true,
  closeOnClick: true,
  autoClose: 5000,
  draggable: false,
  role: 'alert',
  position: 'top-right',
  className: 'space-y-2 mx-2 xs:mx-0',
  bodyClassName: 'text-sm font-app items-start',
};

export function successToast(title: string, message?: string) {
  showToast(ToastType.Success, title, message);
}

export function infoToast(title: string, message?: string) {
  showToast(ToastType.Info, title, message);
}

export function warningToast(title: string, message?: string) {
  showToast(ToastType.Warning, title, message);
}

export function errorToast(title: string, message?: string) {
  showToast(ToastType.Error, title, message);
}

function showToast(type: ToastType, title: string, message?: string) {
  const toastOptions: ToastOptions = {
    type,
    icon: getToastTypeIcon(type),
  };

  const toastContent: ToastContent = (
    <>
      <h4 className={cn(styles.title, ToastConfiguration[type].textColor)}>
        {title}
      </h4>
      {message && (
        <p className={cn(styles.message, ToastConfiguration[type].textColor)}>
          {message}
        </p>
      )}
    </>
  );

  toast(toastContent, toastOptions);
}

function getToastTypeIcon(toastType: ToastType) {
  const className = `w-4 h-5 ${ToastConfiguration[toastType].textColor}`;

  switch (toastType) {
    case ToastType.Success:
      return <CheckMarkIcon className={className} />;
    case ToastType.Error:
    case ToastType.Warning:
      return <WarningIcon className={className} />;
    case ToastType.Info:
      return <InfoIcon className={className} />;
  }
}
