import { isProdBuild } from 'main/utils/env';
export class InvalidFlowError extends Error {
  constructor(unexpectedFlow: string) {
    let errMsg = `Flow "${unexpectedFlow}" is not supported.`;
    if (!isProdBuild) {
      errMsg = errMsg.concat('\n¯\\_(ツ)_/¯');
    }

    super(errMsg);
    this.name = 'InvalidFlowError';
  }
}
