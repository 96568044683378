import { SupportedLocale } from 'main/i18n';

/*
  For 'XOF' currency (some of the West-African countries) Intl.NumberFormat returns 'F CFA'
  This may confuse users, so we need to 'hack it'
   */
const WestAfricanCurrencyValue = 'XOF';

export function formatPrice(
  locale: SupportedLocale,
  currency: string,
  price: number
): string {
  let formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(price);

  /*
  /F\sCFA is a regex that should catch 'F CFA' substring.
  Regex needs to be used here, cause Intl.NumberFormat outputs some hidden symbols between 'F' and 'CFA' parts of 'F CFA' string
  */

  if (
    currency.toUpperCase() === WestAfricanCurrencyValue &&
    /F\sCFA/g.test(formattedPrice)
  ) {
    formattedPrice = formattedPrice.replace(
      /F\sCFA/g,
      WestAfricanCurrencyValue
    );
  }
  return formattedPrice;
}
