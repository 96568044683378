import { PrimaryButton, SimpleButton } from 'main/components/common';
import { useState } from 'react';

import { TestDebuggerInfo } from './drawers/TestDebuggerInfo';
import { TestEnvironmentInfo } from './drawers/TestEnvironmentInfo';
import { TestSupportedPayments } from './drawers/TestSupportedPayments';
import { TestTheme } from './drawers/TestTheme';
import { TestUiElements } from './drawers/TestUiElements';

enum Drawer {
  Theme,
  EnvInfo,
  SupportedPayments,
  UIElements,
  Debugger,
}

const DrawerConfigs = {
  [Drawer.Theme]: {
    title: 'Theme',
    component: <TestTheme />,
    icon: (
      <img
        className="w-8 h-8 inline-block mr-xs"
        src="/icons/palette.svg"
        alt=""
      />
    ),
  },
  [Drawer.EnvInfo]: {
    title: 'Environment',
    component: <TestEnvironmentInfo />,
    icon: (
      <img
        className="w-8 h-8 inline-block mr-xs"
        src="/icons/research.svg"
        alt=""
      />
    ),
  },
  [Drawer.SupportedPayments]: {
    title: 'Payments',
    component: <TestSupportedPayments />,
    icon: (
      <img
        className="w-8 h-8 inline-block mr-xs"
        src="/icons/money-coins.svg"
        alt=""
      />
    ),
  },
  [Drawer.UIElements]: {
    title: 'UI',
    component: <TestUiElements />,
    icon: (
      <img
        className="w-8 h-8 inline-block mr-xs"
        src="/icons/blockchain.svg"
        alt=""
      />
    ),
  },
  [Drawer.Debugger]: {
    title: 'Debugger',
    component: <TestDebuggerInfo />,
    icon: (
      <img
        className="w-8 h-8 inline-block mr-xs"
        src="/icons/ladybug.svg"
        alt=""
      />
    ),
  },
};

export const DemoDrawer = () => {
  const [drawer, setDrawer] = useState<Drawer>();

  const renderDrawerButton = (btnDrawer: Drawer) => {
    return (
      <span className="transition ease-in-out bg-transparent rounded-button p-sm hover:bg-secondary">
        <SimpleButton
          onClick={() =>
            setDrawer(drawer === btnDrawer ? undefined : btnDrawer)
          }
        >
          {DrawerConfigs[btnDrawer].icon}
          {DrawerConfigs[btnDrawer].title}
        </SimpleButton>
      </span>
    );
  };

  return (
    <div className="bg-white border-b-8 border-primary">
      <div className="w-full p-sm border-b-2 border-primary">
        {renderDrawerButton(Drawer.Theme)}
        {renderDrawerButton(Drawer.EnvInfo)}
        {renderDrawerButton(Drawer.SupportedPayments)}
        {renderDrawerButton(Drawer.UIElements)}
        {renderDrawerButton(Drawer.Debugger)}
      </div>
      {drawer !== undefined && (
        <>
          <div className="p-sm">{DrawerConfigs[drawer].component}</div>
          <div className="w-full p-sm text-center">
            <PrimaryButton onClick={() => setDrawer(undefined)}>
              {'Close'}
            </PrimaryButton>
          </div>
        </>
      )}
    </div>
  );
};
