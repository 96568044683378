import braintree from 'braintree-web';
import { loadScript } from 'main/hooks/loadScript';
import { SupportedLocale } from 'main/i18n/schemas/Locales';
import {
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import { registerStartupable } from 'main/services/base/StartupHelper';

import AuthenticationService from '../auth/AuthenticationService';
import { OptionalStartupable } from '../base/Startupable';
import PaymentStore from '../payments/PaymentStore';
import { BraintreeClient, BraintreeMerchantInfo } from './BraintreeClient';
import {
  getBraintreeMerchantInfo,
  getBraintreeSecureNonce,
} from './helpers/BraintreeApi';
import { SepaSupportedLocale } from './helpers/BraintreeSupportedLocalesHelper';
import { BraintreeSepaLocale } from './schemas/BraintreeLocale';

interface SepaBillingAddress {
  //https://github.com/braintree/braintree-web/blob/main/src/sepa/external/mandate.js#L34
  readonly addressLine1: string; //number, street
  readonly adminArea1: string;
  readonly adminArea2: string;
  readonly postalCode: string;
}

interface SepaTokenizeSuccessResponse {
  readonly nonce: string;
  readonly ibanLastFour: string;
}

class BraintreeSepaService implements OptionalStartupable {
  public readonly name = 'BraintreeSepaService';
  public readonly moduleId = PaymentModuleId.BraintreeSepa;

  private _sepaClient!: any;

  private _braintreeClient!: BraintreeClient;
  private _merchantInfo!: BraintreeMerchantInfo;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public async startup(): Promise<void> {
    loadScript('https://js.braintreegateway.com/web/3.90.0/js/client.min.js');
    loadScript('https://js.braintreegateway.com/web/3.90.0/js/sepa.min.js');

    const defaultToken = await PaymentStore.getBraintreeDefaultToken();
    if (defaultToken === undefined) {
      throw new Error('Default token has no value!');
    }

    if (PaymentStore.flow === PaymentIntentFlow.Manage) {
      this._merchantInfo = await getBraintreeMerchantInfo(
        undefined,
        PaymentStore.legalEntity
      );
    } else {
      this._merchantInfo = await getBraintreeMerchantInfo(
        PaymentStore.paymentIntentId,
        undefined
      );
    }

    if (this._merchantInfo === undefined) {
      throw new Error('merchant info has no value!');
    }

    this._braintreeClient = new BraintreeClient(this.moduleId, defaultToken);
    await this._braintreeClient.init();
    this._sepaClient = await this.createSepaClient(
      this._braintreeClient.client,
      this._merchantInfo.merchantId
    );
  }

  public shouldStart(): boolean {
    const isAllowed = PaymentStore.allowedPaymentModules.includes(
      this.moduleId
    );

    const hasAtLeastOneInstrument =
      PaymentStore.instruments.find((i) => i.moduleId === this.moduleId) !==
      undefined;

    return isAllowed || hasAtLeastOneInstrument;
  }

  private createSepaClient(
    clientInstance: braintree.Client,
    merchantId: string
  ): Promise<any> {
    return (braintree as any).sepa.create({
      client: clientInstance,
      merchantId: merchantId,
    });
  }

  public async tokenizeSepa(
    accountHolderName: string,
    customerId: string,
    iban: string,
    mandateType: string,
    countryCode: string,
    merchantAccountId: string,
    billingAddress: SepaBillingAddress,
    locale: BraintreeSepaLocale
  ): Promise<SepaTokenizeSuccessResponse> {
    return this._sepaClient.tokenize({
      accountHolderName: accountHolderName,
      customerId: customerId,
      iban: iban,
      mandateType: mandateType,
      countryCode: countryCode,
      merchantAccountId: merchantAccountId,
      billingAddress: {
        addressLine1: billingAddress.addressLine1, //number, street
        adminArea1: billingAddress.adminArea1, //City
        adminArea2: billingAddress.adminArea2, //Region or State
        postalCode: billingAddress.postalCode, //zip code
      },
      //locale is a BCP-47 code from https://developer.paypal.com/reference/locale-codes/
      locale: locale,
    });
  }

  public async submitSepaForm(
    accountHolderName: string,
    iban: string,
    locale: SupportedLocale
  ): Promise<void> {
    const customerNumber = AuthenticationService.user.tpId;

    if (customerNumber === undefined) {
      throw new Error('Customer number is not set');
    }

    //fields are taken from https://github.com/braintree/braintree-web/blob/main/src/sepa/external/mandate.js#L32-L38
    const billingAddress = {
      addressLine1: PaymentStore.customerStreet,
      adminArea1: PaymentStore.customerCity,
      adminArea2: PaymentStore.customerRegion,
      postalCode:
        PaymentStore.customerZipCode.length > 9
          ? PaymentStore.customerZipCode.substring(0, 9)
          : PaymentStore.customerZipCode,
    };

    const sepaLocale = SepaSupportedLocale[locale];

    const mandateType = 'RECURRENT';

    const token = await this.tokenizeSepa(
      accountHolderName,
      customerNumber,
      iban,
      mandateType,
      PaymentStore.customerCountryCode,
      this._merchantInfo.merchantAccountId,
      billingAddress,
      sepaLocale
    );

    if (
      token === undefined ||
      token.nonce === undefined ||
      token.nonce === ''
    ) {
      throw new Error('nonce token is invalid');
    }

    if (PaymentStore.flow === PaymentIntentFlow.Manage) {
      if (PaymentStore.legalEntity === undefined) {
        throw new Error('Legal entity cannot be undefined');
      }
      await this._braintreeClient.tokenize(
        PaymentStore.legalEntity,
        customerNumber,
        token.nonce,
        token.ibanLastFour
      );
    } else {
      await this._braintreeClient.authorize(
        PaymentStore.paymentIntentId,
        token.nonce,
        undefined,
        token.ibanLastFour
      );
    }
  }

  public async authorizeInstrument(
    instrument: PaymentInstrument
  ): Promise<void> {
    const secureNonce = await getBraintreeSecureNonce(instrument.id);
    const nonce = secureNonce;

    return this._braintreeClient.authorize(
      PaymentStore.paymentIntentId,
      nonce,
      instrument.id
    );
  }
}

export default new BraintreeSepaService();
