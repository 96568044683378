import braintree from 'braintree-web';
import { ThreeDSecureInfo } from 'braintree-web/three-d-secure';
import {
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';
import { noop } from 'main/utils/noop';

import { ForceAuthorizeFor3dsStatuses } from '../schemas/AcceptedLiabilityStatus';
import { ThreeDSecureInfoV2 } from '../schemas/schemas';

export function createBraintreeClient(
  clientToken: string
): Promise<braintree.Client> {
  return braintree.client.create({
    authorization: clientToken,
  });
}

export function createBraintreeApplePayClient(
  clientInstance: braintree.Client
): Promise<braintree.ApplePay> {
  return braintree.applePay.create({ client: clientInstance });
}

export function createBraintreeGooglePayClient(
  clientInstance: braintree.Client
): Promise<braintree.GooglePayment> {
  return braintree.googlePayment.create({
    client: clientInstance,
    googlePayVersion: 2,
    googleMerchantId: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_ID,
  });
}

export function createPayPalCheckoutInstance(
  clientInstance: braintree.Client
): Promise<braintree.PayPalCheckout> {
  return braintree.paypalCheckout.create({ client: clientInstance });
}

export function createBraintreeDataCollector(
  clientInstance: braintree.Client
): Promise<braintree.DataCollector> {
  return braintree.dataCollector.create({
    client: clientInstance,
  });
}

export function destroyBraintreeClient(
  clientInstance: braintree.Client
): Promise<void> {
  return clientInstance.teardown(noop);
}

export function createBraintreeThreeDSecureClient(
  clientInstance: braintree.Client
): Promise<braintree.ThreeDSecure> {
  return braintree.threeDSecure
    .create({
      client: clientInstance,
      version: 2,
    })
    .then((threeDSecureInstance) => {
      attachThreeDSecureEventListeners(threeDSecureInstance);
      return threeDSecureInstance;
    });
}

function attachThreeDSecureEventListeners(instance: braintree.ThreeDSecure) {
  // All possile events fire when using the 3D Secure 2.0 flow

  // 'lookup-complete'
  // Emitted when the initial lookup request completes
  instance.on('lookup-complete', (_: unknown, next: (() => void) | undefined) =>
    next ? next() : noop
  );

  // 'customer-canceled'
  // Emitted when the customer cancels the 3D Secure challenge
  instance.on('customer-canceled', noop);

  // 'authentication-modal-render'
  // Emitted when the authentication modal is rendered
  instance.on('authentication-modal-render', noop);

  // 'authentication-modal-close'
  // Emitted when the authentication modal closes, either because the
  // authentication was completed or because the customer canceled the process
  instance.on('authentication-modal-close', noop);
}

export function verifyBraintreeCard(
  clientInstance: braintree.ThreeDSecure,
  nonce: string,
  bin: string,
  amount: number,
  email: string
) {
  return clientInstance.verifyCard({
    nonce,
    bin,
    amount: amount.toString(),
    email,
  });
}

export function isBraintreePaymentInstrument(instrument: PaymentInstrument) {
  return (
    instrument.moduleId === PaymentModuleId.BraintreeCard ||
    instrument.moduleId === PaymentModuleId.BraintreePayPal ||
    instrument.moduleId === PaymentModuleId.BraintreeApplePay ||
    instrument.moduleId === PaymentModuleId.BraintreeGooglePay ||
    instrument.moduleId === PaymentModuleId.BraintreeSepa
  );
}

export function isBraintreeModule(moduleId: PaymentModuleId) {
  return (
    moduleId === PaymentModuleId.BraintreeCard ||
    moduleId === PaymentModuleId.BraintreePayPal ||
    moduleId === PaymentModuleId.BraintreeApplePay ||
    moduleId === PaymentModuleId.BraintreeGooglePay ||
    moduleId === PaymentModuleId.BraintreeSepa
  );
}

export function isLiabilityShifted(payload: ThreeDSecureInfo): boolean {
  return payload.liabilityShifted;
}

export function isOwnLiabilityAccepted(payload: ThreeDSecureInfo): boolean {
  return !!ForceAuthorizeFor3dsStatuses.find(
    (s) => s === (payload as ThreeDSecureInfoV2).status
  );
}
