interface DeferredPromise<T> {
  readonly resolve: (arg: T) => void;
  readonly reject: (reason?: Error) => void;
  readonly promise: Promise<T>;
}

export function deferredPromise<T>(): DeferredPromise<T> {
  let deferred = {};
  const promise = new Promise((resolve, reject) => {
    deferred = {
      resolve,
      reject,
    };
  });

  deferred = {
    ...deferred,
    promise,
  };

  return deferred as DeferredPromise<T>;
}
