import {
  PaymentInstrument,
  InstrumentIcon,
  InstrumentStatus,
  PaymentModuleId,
  InstrumentBrand,
} from 'main/schemas/PaymentInstrument';
import { mapApiModuleNameToModuleId } from 'main/services/payments/intent/helpers/PaymentIntentHelper';

import {
  isCard,
  isGooglePay,
  isPayPal,
  isDirectDebit,
  isDirectDebitLsvOrMpmSepa,
  isPostFinance,
} from '../utils/InstrumentUtils';
import {
  ManagePaymentInstrumentApiResponse,
  PaymentInstrumentApiResponse,
} from './PaymentInstrumentsApiResponse';

interface MaskedInfo {
  readonly instrumentBrand?: InstrumentBrand;
  readonly expirationDate?: Date;
  readonly info: string;
  readonly bin: string;
}

export function parseResponse(
  instruments: PaymentInstrumentApiResponse[]
): ReadonlyArray<PaymentInstrument> {
  const filteredInstruments = removeUnknownInstruments(instruments);
  const enrichedInstruments = enrichWithModuleData(filteredInstruments);
  const instrumentsSortedByDefaultAndMaskedInfo =
    sortInstrumentsByDefaultAndMaskedInfo(enrichedInstruments);
  const result = sortInstrumentsByExpiryDate(
    instrumentsSortedByDefaultAndMaskedInfo
  );

  return result;
}

export function parseManageResponse(
  instruments: ManagePaymentInstrumentApiResponse[]
): ReadonlyArray<PaymentInstrument> {
  const enrichedInstruments =
    enrichManagedInstrumentWithModuleData(instruments);
  const filteredInstruments =
    removeUnknownManageInstruments(enrichedInstruments);

  const instrumentsSortedByDefaultAndMaskedInfo =
    sortInstrumentsByDefaultAndMaskedInfo(filteredInstruments);

  const instrumentsSortedByExpiryDate = sortInstrumentsByExpiryDate(
    instrumentsSortedByDefaultAndMaskedInfo
  );

  return instrumentsSortedByExpiryDate;
}

function removeUnknownInstruments(instruments: PaymentInstrumentApiResponse[]) {
  return instruments.filter(isInstrumentTypeValid);
}

function removeUnknownManageInstruments(instruments: PaymentInstrument[]) {
  return instruments.filter(isManageInstrumentTypeValid);
}

function isInstrumentTypeValid(instrument: PaymentInstrumentApiResponse) {
  return Object.values(PaymentModuleId).includes(instrument.paymentDataTypeId);
}

function isManageInstrumentTypeValid(instrument: PaymentInstrument) {
  return Object.values(PaymentModuleId).includes(
    instrument.moduleId as PaymentModuleId
  );
}

/**
 *
 * @param instruments
 * @returns a new sorted array where all expired instruments are at the end
 * of the array
 */
function sortInstrumentsByExpiryDate(
  instruments: PaymentInstrument[]
): PaymentInstrument[] {
  return [...instruments].sort((i1, i2) => {
    const is1Expired = i1.status === InstrumentStatus.Expired;
    const is2Expired = i2.status === InstrumentStatus.Expired;

    if (is1Expired && is2Expired) {
      return 0;
    }

    if (is1Expired) {
      return 1;
    }

    if (is2Expired) {
      return -1;
    }

    return 0;
  });
}

/**
 *
 * @param instruments
 * @returns a new sorted array where 'Default' method goes first and methods are sorted by MaskedInfo
 * of the array
 */
function sortInstrumentsByDefaultAndMaskedInfo(
  instruments: PaymentInstrument[]
): PaymentInstrument[] {
  const instrumentsSortedByDefaultAndMaskedInfo = instruments.sort(function (
    a,
    b
  ) {
    return (b.isDefault ? 1 : 0) || a.maskedInfo.localeCompare(b.maskedInfo);
  });

  return instrumentsSortedByDefaultAndMaskedInfo;
}

function enrichWithModuleData(
  instrument: PaymentInstrumentApiResponse[]
): PaymentInstrument[] {
  return instrument.map((i) => {
    const { id, paymentDataTypeId: moduleId, maskedInfo: info } = i;
    const maskedInfo: MaskedInfo = extractMaskedInfo(moduleId, info);
    const paymentInstrument: PaymentInstrument = {
      id,
      moduleId,
      maskedInfo: maskedInfo.info,
      expirationDate: maskedInfo.expirationDate
        ? expirationDateToString(maskedInfo.expirationDate)
        : undefined,
      brand: maskedInfo.instrumentBrand,
      iconName: determineInstrumentIcon(maskedInfo.instrumentBrand, moduleId),
      status: determineInstrumentStatus(maskedInfo.expirationDate),
      legalEntityNumber: undefined,
      isDefault: false,
      isDuplicate: false,
      bin: maskedInfo.bin,
    };

    return paymentInstrument;
  });
}

function enrichManagedInstrumentWithModuleData(
  instruments: ManagePaymentInstrumentApiResponse[]
): PaymentInstrument[] {
  return instruments.map((i) => {
    const {
      id,
      paymentDataTypeId: moduleId,
      maskedInfo: info,
      legalEntityNumber,
      isDefault,
    } = i;
    const mappedModuleId = mapApiModuleNameToModuleId(moduleId);

    if (mappedModuleId === undefined) {
      throw new Error('Module name was not mapped');
    }

    const maskedInfo: MaskedInfo = extractMaskedInfo(mappedModuleId, info);
    const paymentInstrument: PaymentInstrument = {
      id,
      moduleId: mappedModuleId,
      maskedInfo: maskedInfo.info,
      expirationDate: maskedInfo.expirationDate
        ? expirationDateToString(maskedInfo.expirationDate)
        : undefined,
      brand: maskedInfo.instrumentBrand,
      iconName: determineInstrumentIcon(
        maskedInfo.instrumentBrand,
        mappedModuleId
      ),
      status: determineInstrumentStatus(maskedInfo.expirationDate),
      legalEntityNumber: legalEntityNumber,
      isDefault: isDefault,
      isDuplicate:
        instruments.filter((x) => x.maskedInfo === i.maskedInfo).length > 1,
      bin: maskedInfo.bin,
    };

    return paymentInstrument;
  });
}

/**
 * Parse the API returned maskedInfo to extract all the information contained
 * in it.
 * @param maskedInfo
 */
function extractMaskedInfo(
  moduleId: PaymentModuleId,
  maskedInfo: string
): MaskedInfo {
  let info: string = maskedInfo;
  let expirationDate: string | undefined;
  let instrumentBrand: string | undefined;

  if (isCard(moduleId) || isGooglePay(moduleId)) {
    // Cards and GooglePay card instruments contain information about the
    // card type and it's expiration date. The information is space separated
    // but we need to keep in mind that some card types can have spaces too
    // (e.g., "American Express").
    // What we can be sure is the order in the string:
    //  "<card-type> <masked-card-number> <expiration-date>"
    const DELIMITER = ' ';
    const expirationDateDelimiterIndex = maskedInfo.lastIndexOf(DELIMITER);
    expirationDate = maskedInfo.substring(expirationDateDelimiterIndex + 1);
    const infoDelimiterIndex = maskedInfo
      .substring(0, expirationDateDelimiterIndex)
      .lastIndexOf(DELIMITER);
    info = maskedInfo.substring(
      infoDelimiterIndex + 1,
      expirationDateDelimiterIndex
    );
    instrumentBrand = maskedInfo.substring(0, infoDelimiterIndex).toLowerCase();

    // Ensure we have always info in case of unexpected string format
    if (!info) {
      info = maskedInfo;
    }
  }

  return {
    info: formatInstrumentInfo(moduleId, info),
    expirationDate: formatInstrumentExpirationDate(expirationDate),
    instrumentBrand: getInstrumentBrand(moduleId, instrumentBrand ?? ''),
    bin: getBin(moduleId, info),
  };
}

function formatInstrumentInfo(
  moduleId: PaymentModuleId,
  instrumentMaskedInfo: string
): string {
  if (
    (isCard(moduleId) || isGooglePay(moduleId)) &&
    instrumentMaskedInfo.length >= 4
  ) {
    // Card can contain 4 or more digits. For the sake of consistency, we
    // only show the last 4 digits and normalize the way we mask the other.
    // E.g., "1234", "****1234", "1234********1234" are all valid strings.
    return `**** ${instrumentMaskedInfo.slice(-4)}`;
  }

  if (isDirectDebit(moduleId) && instrumentMaskedInfo.length >= 4) {
    // Direct debit instruments only contain partial IBAN number where only the
    // last 4 digits are available. The rest is masked. E.g., "xxxx1234"
    return instrumentMaskedInfo.slice(-4);
  }

  return instrumentMaskedInfo;
}

function getBin(
  moduleId: PaymentModuleId,
  instrumentMaskedInfo: string
): string {
  if (
    moduleId === PaymentModuleId.BraintreeCard &&
    instrumentMaskedInfo.length >= 6
  ) {
    return instrumentMaskedInfo.slice(0, 6);
  }

  return instrumentMaskedInfo;
}

function formatInstrumentExpirationDate(
  expirationDate?: string
): Date | undefined {
  if (!expirationDate) {
    return undefined;
  }

  const [expirationYear, expirationMonth] = expirationDate
    ? expirationDate.split('-')
    : [];

  // Instruments are valid until the end of the last day of the month in the
  // expiration date provided. E.g., an instruments with expiration date of
  // expiration 2022-03 will be valid until the March 31st 23h 59m 59s 999ms.
  //
  // This is the reason why we don't need to fix the month value here to be
  // within the interval [0, 11]. Preserving the original value corresponding
  // to "internval + 1", we are essentialy setting the 1st day of the next
  // month. E.g., for the same expiration date of 2022-03, the date object
  // with month value of 3 will represent April 1st 0h 0m 0s 0ms. This date
  // is the exact moment at which the card is considered expired.
  const expirationDay = new Date(+expirationYear, +expirationMonth, 1);

  // Ignore the date in case the string is not in the expected format
  if (expirationDay.toString() === 'Invalid Date') {
    return undefined;
  }

  return expirationDay;
}

function expirationDateToString(expirationDate: Date): string {
  // The expiration date represents the moment at which the card is considered
  // expired which midnight of the first day of the next month written in the
  // card.
  //
  // E.g., the card expiration date of 2024-12 means the card will still be
  // valid at the last month (December of 2024) and will expire exactly at
  // January 1st, 2025 at 00h 00m 00s 00ms.
  //
  // For this reason, to have the expected string value of the date, we need
  // to remove from expirationDate 1 second to go back to the actual last
  // month when the card is valid.
  const expirationTime = expirationDate.getTime();
  const oneSecond = 1000;
  const shiftedDate = new Date(expirationTime - oneSecond);

  const year = shiftedDate.getFullYear();
  // The JavaScript month index is 0 based. Meaning January === month 0.
  // For this reason, to have the correct month number, we need to increment it
  // by 1.
  const month = String(shiftedDate.getMonth() + 1).padStart(2, '0');

  return `${year}-${month}`;
}

function determineInstrumentStatus(
  expirationDate: Date | undefined
): InstrumentStatus {
  if (expirationDate !== undefined) {
    const expirationDay = expirationDate.getTime();
    const today = new Date().getTime();
    if (today >= expirationDay) {
      return InstrumentStatus.Expired;
    }
  }

  return InstrumentStatus.Valid;
}

function getInstrumentBrand(
  moduleId: PaymentModuleId,
  brandName?: string
): InstrumentBrand | undefined {
  if (isPayPal(moduleId)) {
    return InstrumentBrand.PayPal;
  }

  if (isDirectDebit(moduleId)) {
    return InstrumentBrand.DirectDebit;
  }

  if (isPostFinance(moduleId)) {
    return InstrumentBrand.DirectDebitPostFinance;
  }

  if ((isCard(moduleId) || isGooglePay(moduleId)) && brandName !== undefined) {
    return mapBrandNameToInstrumentBrand(brandName);
  }

  return undefined;
}

export function mapBrandNameToInstrumentBrand(
  brandName: string
): InstrumentBrand | undefined {
  switch (brandName) {
    case 'amex':
    case 'american-express':
    case 'american express':
      return InstrumentBrand.Amex;
    case 'diners':
    case 'diners-club':
    case 'diners club':
      return InstrumentBrand.Diners;
    case 'discover':
      return InstrumentBrand.Discover;
    case 'elo':
      return InstrumentBrand.Elo;
    case 'jcb':
      return InstrumentBrand.Jcb;
    case 'mastercard':
    case 'master-card':
    case 'master_card':
    case 'ecmc':
      return InstrumentBrand.MasterCard;
    case 'maestro':
    case 'uk_maestro':
      return InstrumentBrand.Maestro;
    case 'unionpay':
    case 'union_pay':
    case 'china_union_pay':
      return InstrumentBrand.UnionPay;
    case 'visa':
      return InstrumentBrand.Visa;
    default:
      return undefined;
  }
}

function determineInstrumentIcon(
  brand: InstrumentBrand | undefined,
  moduleId: PaymentModuleId
): InstrumentIcon {
  if (isPayPal(moduleId)) {
    return InstrumentIcon.PayPal;
  }

  if (isGooglePay(moduleId)) {
    return InstrumentIcon.GooglePay;
  }

  if (isCard(moduleId) && brand !== undefined) {
    return getCardBrandIcon(brand);
  }

  if (isDirectDebitLsvOrMpmSepa(moduleId)) {
    return InstrumentIcon.DirectDebit;
  }

  if (isPostFinance(moduleId)) {
    return InstrumentIcon.DirectDebitPostFinance;
  }

  if (moduleId === PaymentModuleId.BraintreeSepa) {
    return InstrumentIcon.SEPA;
  }

  return InstrumentIcon.GenericCard;
}

function getCardBrandIcon(brand: InstrumentBrand): InstrumentIcon {
  switch (brand) {
    case InstrumentBrand.Amex:
      return InstrumentIcon.Amex;
    case InstrumentBrand.Diners:
      return InstrumentIcon.Diners;
    case InstrumentBrand.Discover:
      return InstrumentIcon.Discover;
    case InstrumentBrand.Elo:
      return InstrumentIcon.Elo;
    case InstrumentBrand.Jcb:
      return InstrumentIcon.Jcb;
    case InstrumentBrand.MasterCard:
      return InstrumentIcon.MasterCard;
    case InstrumentBrand.Maestro:
      return InstrumentIcon.Maestro;
    case InstrumentBrand.UnionPay:
      return InstrumentIcon.UnionPay;
    case InstrumentBrand.Visa:
      return InstrumentIcon.Visa;
    default:
      return InstrumentIcon.GenericCard;
  }
}

export function getCardIcon(cardBrand: string) {
  const brand = mapBrandNameToInstrumentBrand(cardBrand);
  if (brand) {
    return getCardBrandIcon(brand);
  }

  return InstrumentIcon.GenericCard;
}
