import { CheckoutOptions } from '../utils/CheckoutOptions';
import { PaymentIcons } from '../utils/PaymentIcons';

export function TestSupportedPayments() {
  return (
    <>
      <h1 className="font-bold text-xl mt-sm">SUPPORTED PAYMENTS</h1>
      <PaymentIcons />
      <CheckoutOptions />
    </>
  );
}
