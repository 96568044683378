import {
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';

export function excludeLsvIfAlreadyAdded(
  allowedPaymentModules: PaymentModuleId[],
  instruments: PaymentInstrument[]
) {
  const lsvAlreadyAdded =
    instruments.find((i) => i.moduleId === PaymentModuleId.DirectDebitLSV) !==
    undefined;
  if (lsvAlreadyAdded) {
    return allowedPaymentModules.filter(
      (moduleId) => moduleId !== PaymentModuleId.DirectDebitLSV
    );
  }
  return allowedPaymentModules;
}
