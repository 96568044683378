import { Copyrights, LanguageSelector } from 'main/components/common';
import { FC } from 'react';

const styles = {
  container:
    'text-sm my-md flex flex-col xs:flex-row justify-center items-center mx-auto max-w-panel',
  copyrightsContainer: 'flex mt-xs ml-0 xs:ml-8 xs:mt-0',
};

export const Footer: FC = () => {
  return (
    <div className={styles.container}>
      <LanguageSelector />
      <div className={styles.copyrightsContainer}>
        <Copyrights />
      </div>
    </div>
  );
};
