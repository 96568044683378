import { WarningIcon } from 'main/components/common';
import { FC, ReactNode } from 'react';

import { Alert } from './Alert';

interface AlertWarningProps {
  readonly title: string;
  readonly description?: string;
  readonly children?: ReactNode;
}

export const AlertWarning: FC<AlertWarningProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <Alert
      icon={<WarningIcon className="w-12 text-warn" />}
      title={title}
      description={description}
    >
      {children}
    </Alert>
  );
};
