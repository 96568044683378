import ServiceRegistrar, {
  TBootstrapMode,
} from 'main/services/base/ServiceRegistrar';
import {
  getOptionalStartupableFailures,
  isStartupableStarted,
} from 'main/services/base/StartupHelper';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LoadingSpinner } from './common';
import { ErrorHandler } from './common/errors/ErrorHandler';

export const ServiceBootstrapper: FC<{
  children: ReactNode;
  bootstrapMode: TBootstrapMode;
}> = ({ children, bootstrapMode }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isReadyToRender, setReadyToRender] = useState<boolean>(false);
  const [startupError, setStartupError] = useState<unknown>();

  useEffect(() => {
    const serviceStartTime = Date.now();
    Logger.startup()
      .then(() => {
        if (searchParams.get('tc')?.toLowerCase() === 'true') {
          return TelemetryService.startup();
        }
      })
      .then(() => ServiceRegistrar.startServicesFor(bootstrapMode))
      .then(() => {
        getOptionalStartupableFailures().forEach((failure) => {
          TelemetryService.trackException(failure.cause, 'bootstrapper', {
            serviceName: failure.serviceName,
          });
        });
      })
      .then(() => {
        const serviceStartupTime = Date.now() - serviceStartTime;

        Logger.info(
          LoggerOrigin.ReactComponent,
          `Services statup time was ${serviceStartupTime}ms`
        );

        TelemetryService.trackMetric({
          name: 'StartupTime.Services',
          average: serviceStartupTime,
          sampleCount: 1,
        });
      })
      .then(() => {
        if (
          isStartupableStarted(PaymentStore) &&
          searchParams.has('nonceToken')
        ) {
          searchParams.set('pid', PaymentStore.paymentIntentId);
          searchParams.delete('nonceToken');
          setSearchParams(searchParams);
        }
      })
      .then(() => setReadyToRender(true))
      .catch((error) => {
        setStartupError(error);
        Logger.error(
          LoggerOrigin.ServiceBootstrapper,
          'Startup failure',
          error
        );

        // Since we don't know at this stage which service failed to start,
        // we need to check if it survived before trying to send telemetry.
        if (isStartupableStarted(TelemetryService)) {
          TelemetryService.trackException(error, 'bootstrapper', {
            result: 'The application could not be started.',
          });
        }

        TelemetryService.trackException(
          new Error('Bootstrapping services failed.', { cause: error }),
          'ServiceBootstrapper.tsx'
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bootstrapMode]);

  if (startupError) {
    return (
      <ErrorHandler
        err={startupError instanceof Error ? startupError : undefined}
      />
    );
  }

  if (!isReadyToRender) {
    return <LoadingSpinner />;
  }

  return <>{children}</>;
};
