import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { registerStartupable } from 'main/services/base/StartupHelper';

import { OptionalStartupable } from '../base/Startupable';
import PaymentStore from '../payments/PaymentStore';
import { getEcommerceConnectRedirectUrl } from './helpers/ECommerceConnectApi';

class ECommerceConnectService implements OptionalStartupable {
  public readonly name = 'ECommerceConnectService';
  public readonly moduleId = PaymentModuleId.ECommerceConnect;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public startup(): Promise<void> {
    return Promise.resolve();
  }

  public shouldStart(): boolean {
    return PaymentStore.allowedPaymentModules.includes(this.moduleId);
  }

  public getRedirectUrl(
    locale: SupportedLocale,
    telemetryConsent = false
  ): Promise<string> {
    const paymentIntentId = PaymentStore.paymentIntentId;

    return getEcommerceConnectRedirectUrl(
      paymentIntentId,
      locale,
      telemetryConsent
    );
  }
}

export default new ECommerceConnectService();
