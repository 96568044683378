import { BraintreeErrorCodes, BraintreeError } from '../errors/BraintreeError';

export function isUnsupportedCard(error: BraintreeError): boolean {
  return (
    error.code === BraintreeErrorCodes.ThreeDSecureLookup &&
    error.details?.originalError.details.httpStatus === 422 &&
    error.details?.originalError.details.originalError.error.message.toLowerCase() ===
      'unsupported card type'
  );
}
