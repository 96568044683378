export enum BraintreeErrorCodes {
  ThreeDSecureLookup = 'THREEDS_LOOKUP_VALIDATION_ERROR',
  SepaCustomerCanceled = 'SEPA_CUSTOMER_CANCELED',
  SepaCreateMandateFailed = 'SEPA_CREATE_MANDATE_FAILED',
}

export class BraintreeError extends Error {
  code: BraintreeErrorCodes | undefined;
  type: string | undefined;
  details: Details | undefined;

  constructor(error: BraintreeError) {
    super(`Braintree validation error occured`);
    this.name = 'BraintreeError';

    this.code = error.code;
    this.type = error.type;
    this.details = error.details;
  }
}

interface Details {
  originalError: OriginalError;
}

interface OriginalError {
  name: string;
  code: string;
  message: string;
  type: string;
  details: DetailsInternal;
}

interface DetailsInternal {
  originalError: OriginalErrorInternal;
  httpStatus: number;
}

interface OriginalErrorInternal {
  error: Error;
  threeDSecureInfo: ThreeDsecureInfo;
}

interface ThreeDsecureInfo {
  liabilityShifted: boolean;
  liabilityShiftPossible: boolean;
}
