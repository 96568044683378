import { SupportedLocale } from 'main/i18n';

import { ProcessingKZLocale } from './schemas/ProcessingKZLocale';

export const ProcessingKZSupportedLocale: {
  [key in SupportedLocale]: ProcessingKZLocale;
} = {
  [SupportedLocale.Bulgarian]: 'en',
  [SupportedLocale.Czech]: 'en',
  [SupportedLocale.ChineseSimplifiedChina]: 'en',
  [SupportedLocale.Danish]: 'en',
  [SupportedLocale.Dutch]: 'en',
  [SupportedLocale.English]: 'en',
  [SupportedLocale.Finnish]: 'en',
  [SupportedLocale.French]: 'en',
  [SupportedLocale.German]: 'en',
  [SupportedLocale.Hungarian]: 'en',
  [SupportedLocale.Indonesian]: 'en',
  [SupportedLocale.Italian]: 'en',
  [SupportedLocale.Japanese]: 'en',
  [SupportedLocale.Korean]: 'en',
  [SupportedLocale.Norwegian]: 'en',
  [SupportedLocale.Polish]: 'en',
  [SupportedLocale.Portuguese]: 'en',

  [SupportedLocale.Russian]: 'ru',

  [SupportedLocale.Spanish]: 'en',
  [SupportedLocale.Swedish]: 'en',
  [SupportedLocale.Thai]: 'en',
  [SupportedLocale.Turkish]: 'en',
  [SupportedLocale.ChineseTraditionalTaiwan]: 'en',
  [SupportedLocale.Ukrainian]: 'en',
};
