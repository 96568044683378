export function Delivery(className: string) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3844 3.23071H2.76901C1.7494 3.23071 0.922852 4.18641 0.922852 5.36533V18.173C0.922852 19.3519 1.7494 20.3076 2.76901 20.3076H19.3844C20.404 20.3076 21.2305 19.3519 21.2305 18.173V5.36533C21.2305 4.18641 20.404 3.23071 19.3844 3.23071Z"
        fill="#6CC4F5"
        stroke="#2A4B55"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="15.0586"
        y="7.6731"
        width="8.19231"
        height="6.80769"
        rx="2.01923"
        fill="white"
        stroke="#2A4B55"
        strokeWidth="1.5"
      />
      <circle cx="18.2314" cy="11.0768" r="0.692308" fill="#133E5D" />
    </svg>
  );
}
