import { SupportedLocale } from 'main/i18n';
import {
  BraintreeApplePayLocale,
  BraintreePayPalLocale,
  BraintreeSepaLocale,
  GooglePayLocale,
} from 'main/services/braintree';

export const SepaSupportedLocale: {
  [key in SupportedLocale]: BraintreeSepaLocale;
} = {
  [SupportedLocale.Bulgarian]: 'en-US', // Bulgarian is not supported
  [SupportedLocale.Czech]: 'cs-CZ',
  [SupportedLocale.ChineseSimplifiedChina]: 'zh-CN',
  [SupportedLocale.Danish]: 'da-DK',
  [SupportedLocale.Dutch]: 'nl-NL',
  [SupportedLocale.English]: 'en-US',
  [SupportedLocale.Finnish]: 'fi-FI',
  [SupportedLocale.French]: 'fr-FR',
  [SupportedLocale.German]: 'de-DE',
  [SupportedLocale.Hungarian]: 'hu-HU',
  [SupportedLocale.Indonesian]: 'id-ID',
  [SupportedLocale.Italian]: 'it-IT',
  [SupportedLocale.Japanese]: 'ja-JP',
  [SupportedLocale.Korean]: 'ko-KR',
  [SupportedLocale.Norwegian]: 'no-NO',
  [SupportedLocale.Polish]: 'pl-PL',
  [SupportedLocale.Portuguese]: 'pt-PT',
  [SupportedLocale.Russian]: 'ru-RU',
  [SupportedLocale.Spanish]: 'es-ES',
  [SupportedLocale.Swedish]: 'sv-SE',
  [SupportedLocale.Thai]: 'th-TH',
  [SupportedLocale.Turkish]: 'en-US', // Turkish is not supported
  [SupportedLocale.ChineseTraditionalTaiwan]: 'zh-TW',
  [SupportedLocale.Ukrainian]: 'en-US', // Ukrainian is not supported
};

export const PayPalSupportedLocale: {
  [key in SupportedLocale]: BraintreePayPalLocale;
} = {
  [SupportedLocale.Bulgarian]: 'en_US', // Bulgarian is not supported by PayPal
  [SupportedLocale.Czech]: 'en_US', // Czech is not supported by PayPal
  [SupportedLocale.ChineseSimplifiedChina]: 'zh_CN',
  [SupportedLocale.Danish]: 'da_DK',
  [SupportedLocale.Dutch]: 'nl_NL',
  [SupportedLocale.English]: 'en_US',
  [SupportedLocale.Finnish]: 'en_US', // Finish is not supported by PayPal
  [SupportedLocale.French]: 'fr_FR',
  [SupportedLocale.German]: 'de_DE',
  [SupportedLocale.Hungarian]: 'en_US', // Hungarian is not supported by PayPal
  [SupportedLocale.Indonesian]: 'id_ID',
  [SupportedLocale.Italian]: 'it_IT',
  [SupportedLocale.Japanese]: 'ja_JP',
  [SupportedLocale.Korean]: 'ko_KR',
  [SupportedLocale.Norwegian]: 'no_NO',
  [SupportedLocale.Polish]: 'pl_PL',
  [SupportedLocale.Portuguese]: 'pt_PT',
  [SupportedLocale.Russian]: 'ru_RU',
  [SupportedLocale.Spanish]: 'es_ES',
  [SupportedLocale.Swedish]: 'sv_SE',
  [SupportedLocale.Thai]: 'th_TH',
  [SupportedLocale.Turkish]: 'en_US', // Turkish is not supported by PayPal
  [SupportedLocale.ChineseTraditionalTaiwan]: 'zh_TW',
  [SupportedLocale.Ukrainian]: 'en_US', // Ukrainian is not supported by PayPal
};

export const ApplePaySupportedLocale: {
  [key in SupportedLocale]: BraintreeApplePayLocale;
} = {
  [SupportedLocale.Bulgarian]: 'en-US', // Bulgarian is not supported by Apple Pay
  [SupportedLocale.Czech]: 'cs-CZ',
  [SupportedLocale.ChineseSimplifiedChina]: 'zh-CN',
  [SupportedLocale.Danish]: 'da-DK',
  [SupportedLocale.Dutch]: 'nl-NL',
  [SupportedLocale.English]: 'en-US',
  [SupportedLocale.Finnish]: 'fi-FI',
  [SupportedLocale.French]: 'fr-FR',
  [SupportedLocale.German]: 'de-DE',
  [SupportedLocale.Hungarian]: 'hu-HU',
  [SupportedLocale.Indonesian]: 'id-ID',
  [SupportedLocale.Italian]: 'it-IT',
  [SupportedLocale.Japanese]: 'ja-JP',
  [SupportedLocale.Korean]: 'ko-KR',
  [SupportedLocale.Norwegian]: 'nb-NO',
  [SupportedLocale.Polish]: 'pl-PL',
  [SupportedLocale.Portuguese]: 'pt-PT',
  [SupportedLocale.Russian]: 'ru-RU',
  [SupportedLocale.Spanish]: 'es-ES',
  [SupportedLocale.Swedish]: 'sv-SE',
  [SupportedLocale.Thai]: 'th-TH',
  [SupportedLocale.Turkish]: 'tr-TR',
  [SupportedLocale.ChineseTraditionalTaiwan]: 'zh-TW',
  [SupportedLocale.Ukrainian]: 'uk-UA',
};

export const GooglePaySupportedLocale: {
  [key in SupportedLocale]: GooglePayLocale;
} = {
  [SupportedLocale.Bulgarian]: 'bg',
  [SupportedLocale.Czech]: 'cs',
  [SupportedLocale.ChineseSimplifiedChina]: 'zh',
  [SupportedLocale.Danish]: 'da',
  [SupportedLocale.Dutch]: 'nl',
  [SupportedLocale.English]: 'en',
  [SupportedLocale.Finnish]: 'fi',
  [SupportedLocale.French]: 'fr',
  [SupportedLocale.German]: 'de',
  [SupportedLocale.Hungarian]: 'en', // Hungarian is not supported by Google Pay
  [SupportedLocale.Indonesian]: 'id',
  [SupportedLocale.Italian]: 'it',
  [SupportedLocale.Japanese]: 'ja',
  [SupportedLocale.Korean]: 'ko',
  [SupportedLocale.Norwegian]: 'no',
  [SupportedLocale.Polish]: 'pl',
  [SupportedLocale.Portuguese]: 'pt',
  [SupportedLocale.Russian]: 'ru',
  [SupportedLocale.Spanish]: 'es',
  [SupportedLocale.Swedish]: 'sv',
  [SupportedLocale.Thai]: 'th',
  [SupportedLocale.Turkish]: 'tr',
  [SupportedLocale.ChineseTraditionalTaiwan]: 'zh',
  [SupportedLocale.Ukrainian]: 'uk',
};
