import { PaymentIcon } from 'main/components/common';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { InstrumentIconForModuleId } from 'main/services/payments/instruments/PaymentInstrumentTypes';

import { DemoContainer } from './renderers';

export const CheckoutOptions = () => {
  const paymentModuleT = useUserFriendlyPaymentModuleName();

  return (
    <DemoContainer title={'CHECKOUT OPTIONS'}>
      <div className="flex flex-col">
        {Object.values(PaymentModuleId)
          .filter((mid) => typeof mid === 'number')
          .map((mid) => {
            return (
              <div key={mid} className={'flex flex-row items-center'}>
                <PaymentIcon
                  icon={InstrumentIconForModuleId[mid as PaymentModuleId]}
                />
                <p className="ml-sm font-bold">
                  {paymentModuleT(mid as PaymentModuleId)}
                </p>
                <p className="ml-sm">[{mid}]</p>
              </div>
            );
          })}
      </div>
    </DemoContainer>
  );
};
