import { IPaymentIntent, PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import { execGet, execPost } from 'main/services/utils/RequestUtils';

import { AuthorizationError } from './errors/AuthorizationError';
import { IntentAlreadyProcessedError } from './errors/IntentAlreadyProcessedError';
import { parseApiPaymentIntent } from './helpers/PaymentIntentHelper';
import { ApiPaymentIntent } from './schemas/ApiPaymentIntent';
import { AuthorizationPayload } from './schemas/AuthorizationPayload';

export async function getPaymentIntentByNonceToken(
  nonceToken: string
): Promise<IPaymentIntent> {
  const response = await execGet<ApiPaymentIntent>(
    `paymentintent?nonceToken=${nonceToken}`
  );

  return parseApiPaymentIntent(response);
}

export async function getPaymentIntentById(
  paymentIntentId: string
): Promise<IPaymentIntent> {
  const response = await execGet<ApiPaymentIntent>(
    `paymentintent/${paymentIntentId}`
  );

  const intent = parseApiPaymentIntent(response);

  // After adding a new payment intrument, the intent will have its
  // status updated. For management flow, we want the user to continue
  // in the page and add multiple cards and leave only when he wants
  if (response?.customer?.flow === PaymentIntentFlow.Manage) {
    return intent;
  }

  if (!intent.isPayable) {
    throw new IntentAlreadyProcessedError(intent.id);
  }

  return intent;
}

export function paymentInstrumentAuthorize(
  body: AuthorizationPayload
): Promise<void> {
  return execPost<void>(
    `paymentintent/${body.paymentIntentId}/authorize`,
    body
  ).catch((err) => {
    throw new AuthorizationError(body.paymentDataTypeId, err);
  });
}
