import { PrimaryButton } from 'main/components/common';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const RedirectTimer = 5;

const styles = {
  container: 'w-full flex',
  progressBar:
    'bg-secondary rounded-button h-full absolute top-0 left-0 pointer-events-none opacity-10',
};

interface ProgressBarButtonProps {
  readonly onClick: () => void;
}

export const ProgressBarButton: FC<ProgressBarButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  const [progress, setProgress] = useState(0);
  const [timer, setTimer] = useState(RedirectTimer);

  useEffect(() => {
    window.setInterval(() => {
      setProgress((val) => (val === 100 ? val : val + 2));
    }, 100);

    window.setInterval(() => {
      setTimer((val) => (val === 0 ? val : val - 1));
    }, 1000);
  }, []);

  useEffect(() => {
    if (progress >= 100) {
      onClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <div className={styles.container}>
      <PrimaryButton onClick={onClick}>
        {t('You will be redirected back in')} {timer}s
        <div
          className={styles.progressBar}
          style={{
            transition: '1s',
            width: `${progress}%`,
          }}
        ></div>
      </PrimaryButton>
    </div>
  );
};
