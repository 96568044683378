import {
  enableUIBlocker,
  PageLayout,
  PrimaryButton,
} from 'main/components/common';
import { AlertWarning } from 'main/components/common/alerts';
import AuthenticationService from 'main/services/auth/AuthenticationService';
import { APP_URL } from 'main/utils/env';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ForbidenError: FC = () => {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <AlertWarning title={t('Wrong Account!')}>
        {t(
          'It looks like you are currently logged in as {{user}} but this page is intended for someone else. Please sign out and try again with the correct account.',
          {
            user:
              AuthenticationService.user.firstName ??
              AuthenticationService.user.tpId,
          }
        )}
      </AlertWarning>

      <div className="px-sm pb-sm md:px-md md:pb-md 2xl:px-lg 2xl:pb-lg text-center">
        <PrimaryButton
          onClick={() => {
            enableUIBlocker();
            AuthenticationService.startSignout(
              APP_URL,
              window.location.href.replace(window.location.origin, '')
            );
          }}
        >
          {t('Sign out')}
        </PrimaryButton>
      </div>
    </PageLayout>
  );
};
