import { GooglePaymentTokenizePayload } from 'braintree-web';
import { SupportedLocale } from 'main/i18n';
import {
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import { OptionalStartupable } from 'main/services/base/Startupable';
import { registerStartupable } from 'main/services/base/StartupHelper';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { isProdBuild } from 'main/utils/env';

import AuthenticationService from '../auth/AuthenticationService';
import Logger, { LoggerOrigin } from '../Logger';
import PaymentStore from '../payments/PaymentStore';
import { AuthorizationError } from './../payments/intent/errors/AuthorizationError';
import { BraintreeClient } from './BraintreeClient';
import { GooglePayJSRequestError } from './errors/GooglePayJSRequestError';
import { GooglePayNotSupportedError } from './errors/GooglePayNotSupportedError';
import { GooglePayPaymentDataError } from './errors/GooglePayPaymentDataError';
import { GooglePayPaymentResponseError } from './errors/GooglePayPaymentResponseError';
import { LiabilityNotShiftedError } from './errors/LiabilityNotShiftedError';
import { getBraintreeSecureNonce } from './helpers/BraintreeApi';
import { createBraintreeGooglePayClient } from './helpers/BraintreeSdkWrapper';
import { GooglePaySupportedLocale } from './helpers/BraintreeSupportedLocalesHelper';

const GOOGLE_PAY_SDK_SRC = 'https://pay.google.com/gp/p/js/pay.js';

interface GooglePayCallbacks {
  readonly onModalOpen: () => void;
  readonly onSuccess: () => void;
  readonly onError: (err: Error) => void;
  readonly onCancel: () => void;
}

class BraintreeGooglePayService implements OptionalStartupable {
  public readonly name = 'BraintreeGooglePayService';
  public readonly moduleId = PaymentModuleId.BraintreeGooglePay;

  private _checkoutInstance!: braintree.GooglePayment;
  private _braintreeClient!: BraintreeClient;
  private _paymentsClient!: google.payments.api.PaymentsClient;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public shouldStart(): boolean {
    const isAllowed = PaymentStore.allowedPaymentModules.includes(
      this.moduleId
    );

    const hasAtLeastOneInstrument =
      PaymentStore.instruments.find((i) => i.moduleId === this.moduleId) !==
      undefined;

    return isAllowed || hasAtLeastOneInstrument;
  }

  public async startup(): Promise<void> {
    const defaultToken = await PaymentStore.getBraintreeDefaultToken();
    if (defaultToken === undefined) {
      throw new Error('Default token has no value!');
    }

    this._braintreeClient = new BraintreeClient(this.moduleId, defaultToken);

    if (PaymentStore.skip3DS) {
      await this._braintreeClient.init();
    } else {
      await this._braintreeClient.initWith3DS();
    }

    await this.loadGooglePaySDK();

    this._paymentsClient = new google.payments.api.PaymentsClient({
      environment: isProdBuild ? 'PRODUCTION' : 'TEST',
    });

    this._checkoutInstance = await createBraintreeGooglePayClient(
      this._braintreeClient.client
    );
    const paymentDataRequest = await this.createPaymentDataRequest();

    const googlePayReadinessRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: paymentDataRequest.allowedPaymentMethods,
      existingPaymentMethodRequired: false,
    };
    const isReady = await this._paymentsClient.isReadyToPay(
      googlePayReadinessRequest
    );

    if (!isReady.result) {
      throw new GooglePayNotSupportedError(googlePayReadinessRequest);
    }
  }

  public createButton(locale: SupportedLocale, callbacks: GooglePayCallbacks) {
    return this._paymentsClient.createButton({
      buttonColor: 'black',
      buttonType: 'pay',
      buttonSizeMode: 'fill',
      buttonLocale: GooglePaySupportedLocale[locale],
      onClick: async (event: Event) => {
        event.preventDefault();
        callbacks.onModalOpen();

        let paymentData: google.payments.api.PaymentData;
        const paymentDataRequest = await this.createPaymentDataRequest();

        try {
          paymentData = await this._paymentsClient.loadPaymentData(
            paymentDataRequest
          );
        } catch (err) {
          const paymentError = err as google.payments.api.PaymentsError;
          if (paymentError.statusCode === 'CANCELED') {
            callbacks.onCancel();
          } else {
            const error = new GooglePayPaymentDataError(paymentDataRequest);
            callbacks.onError(error);
            TelemetryService.trackException(error, this.name, {
              cause: err,
            });
          }

          return;
        }

        let result: GooglePaymentTokenizePayload;

        try {
          result = await this._checkoutInstance.parseResponse(paymentData);
        } catch (err) {
          const error = new GooglePayPaymentResponseError();
          callbacks.onError(error);
          TelemetryService.trackException(error, this.name, { cause: err });
          return;
        }

        let nonce: string = result.nonce;

        // Trigger 3DS for non-network tokenized Google Pay cards
        // https://developer.paypal.com/braintree/docs/guides/3d-secure/client-side/android/v3#using-3d-secure-with-google-pay
        if (result.details.isNetworkTokenized === false) {
          try {
            Logger.log(
              LoggerOrigin.BraintreeGooglePayService,
              'Card isNetworkTokenized is false. Triggering 3DS',
              result.details
            );

            if (!PaymentStore.skip3DS) {
              const verifiedResult = await this._braintreeClient.verify3DS(
                result.nonce,
                result.details.bin,
                PaymentStore.orderTotal,
                PaymentStore.customerEmail
              );

              if (!verifiedResult.liabilityShifted) {
                TelemetryService.trackEvent({
                  name: 'Braintree3DSLiabilityNotShifted',
                  properties: {
                    proceedWithAuthorization: true,
                    status: verifiedResult.status,
                  },
                });
              }

              nonce = verifiedResult.nonce;
            }
          } catch (err) {
            callbacks.onError(err as LiabilityNotShiftedError);
            TelemetryService.trackException(
              err as LiabilityNotShiftedError,
              this.name
            );
            return;
          }
        } else {
          Logger.log(
            LoggerOrigin.BraintreeGooglePayService,
            'Card isNetworkTokenized is true',
            result.details
          );
        }

        try {
          if (PaymentStore.flow === PaymentIntentFlow.Manage) {
            const customerNumber = AuthenticationService.user.tpId;
            if (customerNumber === undefined) {
              throw new Error('customerNumber is undefined!');
            }

            if (PaymentStore.legalEntity === undefined) {
              throw new Error('Legal entity cannot be undefined');
            }

            await this._braintreeClient.tokenize(
              PaymentStore.legalEntity,
              customerNumber,
              nonce
            );
          } else {
            await this._braintreeClient.authorize(
              PaymentStore.paymentIntentId,
              nonce
            );
          }

          callbacks.onSuccess();
        } catch (err) {
          callbacks.onError(err as AuthorizationError);
          TelemetryService.trackException(err as AuthorizationError, this.name);
          return;
        }
      },
    });
  }

  public async authorizeInstrument(
    instrument: PaymentInstrument
  ): Promise<void> {
    const secureNonce = await getBraintreeSecureNonce(instrument.id);
    let nonce = secureNonce;

    if (!PaymentStore.skip3DS) {
      const result = await this._braintreeClient.verify3DS(
        secureNonce,
        instrument.bin,
        PaymentStore.orderTotal,
        PaymentStore.customerEmail
      );

      if (!result.liabilityShifted) {
        TelemetryService.trackEvent({
          name: 'Braintree3DSLiabilityNotShifted',
          properties: {
            proceedWithAuthorization: true,
            status: result.status,
          },
        });
      }
      nonce = result.nonce;
    }
    return this._braintreeClient.authorize(
      PaymentStore.paymentIntentId,
      nonce,
      instrument.id
    );
  }

  private loadGooglePaySDK() {
    const promise = new Promise<void>((resolve, reject) => {
      // Create script
      const script = document.createElement('script');
      script.src = GOOGLE_PAY_SDK_SRC;
      script.async = true;

      // Script event listener callbacks for load and error
      const onScriptLoad = (): void => {
        resolve();
      };

      const onScriptError = (): void => {
        cleanup();
        script.remove();
        reject(new GooglePayJSRequestError(GOOGLE_PAY_SDK_SRC));
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      // Add script to document body
      document.body.appendChild(script);

      // Remove event listeners on cleanup
      function cleanup(): void {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      }
    });

    return promise;
  }

  private async createPaymentDataRequest() {
    const paymentDataRequest =
      await this._checkoutInstance.createPaymentDataRequest({
        transactionInfo: {
          currencyCode: PaymentStore.currency,
          totalPriceStatus: 'FINAL',
          totalPrice: PaymentStore.orderTotal.toString(),
        },
      });

    // We will accept only Google Pay credit cards stored in Google Account
    // (not the ones stored on Android device), because only those can be
    // vaulted for future transactions. "PAN_ONLY" refers to cards on file
    // with Google.
    // Docs: https://developers.google.com/pay/api/web/reference/request-objects#CardParameters
    paymentDataRequest.allowedPaymentMethods[0].parameters.allowedAuthMethods =
      ['PAN_ONLY'];

    return paymentDataRequest;
  }
}

export default new BraintreeGooglePayService();
