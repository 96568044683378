import { CopyrightIcon } from 'main/components/common/icons';
import { Paragraph } from 'main/components/common/text/Paragraph';
import { FC, useMemo } from 'react';

export const Copyrights: FC = () => {
  const thisYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <Paragraph>
      <CopyrightIcon className="inline-block" />
      <span className="ml-xs">Copyright 1993-{thisYear} PM-International</span>
    </Paragraph>
  );
};
