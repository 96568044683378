import ClarityInjector from 'main/components/clarity/ClarityInjector';
import { UIBlocker } from 'main/components/common';
import { ErrorHandler } from 'main/components/common/errors/ErrorHandler';
import { ThemeProvider } from 'main/components/contexts/theme';
import { ServiceBootstrapper } from 'main/components/ServiceBootstrapper';
import { WrapperProviders } from 'main/components/wrappers';
import { AuthCallback } from 'main/components/wrappers/AuthCallback';
import { AuthenticationWrapper } from 'main/components/wrappers/AuthenticationWrapper';
import ManageApp from 'main/ManageApp';
import PaymentIntentApp from 'main/PaymentIntentApp';
import 'main/styles/globals.scss';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

startApplication();

async function startApplication() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <BrowserRouter>
      <Routes>
        <Route
          index
          element={
            <AuthenticationWrapper>
              <ThemeProvider>
                <ServiceBootstrapper bootstrapMode="payment-intent">
                  <ClarityInjector />
                  <WrapperProviders>
                    <PaymentIntentApp />
                    <ToastContainer />
                    <UIBlocker />
                  </WrapperProviders>
                </ServiceBootstrapper>
              </ThemeProvider>
            </AuthenticationWrapper>
          }
        />
        <Route
          path="manage"
          element={
            <AuthenticationWrapper>
              <ThemeProvider>
                <ServiceBootstrapper bootstrapMode="payment-manage">
                  <ClarityInjector />
                  <WrapperProviders>
                    <ManageApp />
                    <ToastContainer />
                    <UIBlocker />
                  </WrapperProviders>
                </ServiceBootstrapper>
              </ThemeProvider>
            </AuthenticationWrapper>
          }
        />
        <Route path="authcallback" element={<AuthCallback />} />
        <Route path="*" element={<ErrorHandler />} />
      </Routes>
    </BrowserRouter>
  );
}
