import { MissingTranslationsError } from '../errors/MissingTranslationsError';
import { SupportedLocale } from '../schemas/Locales';

export async function loadTranslationsFor(locale: SupportedLocale) {
  try {
    const module = await import(`main/i18n/translations/${locale}.json`);
    return module.default;
  } catch (err) {
    throw new MissingTranslationsError(locale, err as Error);
  }
}
