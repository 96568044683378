import { FC } from 'react';

import { PMLogoOnly } from './PMLogoOnly';
import { PMLogoWithClaim } from './PMLogoWithClaim';

interface PMLogoProps {
  readonly showClaim?: boolean;
}

export const PMLogo: FC<PMLogoProps> = ({ showClaim = false }) => {
  return showClaim ? <PMLogoWithClaim /> : <PMLogoOnly />;
};
