export class StartupableStartupError extends Error {
  error: Error;
  constructor(startupableName: string, cause: Error) {
    super(
      `Startup failed for "${startupableName}".\nReason: ${cause.message}`,
      { cause }
    );
    this.name = 'StartupableStartupError';
    this.error = cause;
  }
}
