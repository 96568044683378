import cn from 'clsx';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import { useEffect, useState } from 'react';

import { DemoContainer } from '../utils/renderers';

type DebuggerOrigins = { [o in LoggerOrigin]: { isOn: boolean } };

export function TestDebuggerInfo() {
  const [origins, setOrigins] = useState<DebuggerOrigins>({
    ApplePay: {
      isOn: false,
    },
    AuthenticationService: {
      isOn: false,
    },
    AuthenticationServiceClient: {
      isOn: false,
    },
    BraintreeApplePayService: {
      isOn: false,
    },
    BraintreeCardService: {
      isOn: false,
    },
    ErrorBoundaries: {
      isOn: false,
    },
    LoggerService: {
      isOn: false,
    },
    PayPalButton: {
      isOn: false,
    },
    NavigationService: {
      isOn: false,
    },
    ReactComponent: {
      isOn: false,
    },
    RequestUtils: {
      isOn: false,
    },
    ServiceBootstrapper: {
      isOn: false,
    },
    ServiceRegistrar: {
      isOn: false,
    },
    StartupHelper: {
      isOn: false,
    },
    TelemetryService: {
      isOn: false,
    },
    TranslationService: {
      isOn: false,
    },
    BraintreeGooglePayService: {
      isOn: false,
    },
  });

  useEffect(() => {
    Logger.subscribe((blockedOrigins) => {
      const newOrigins: DebuggerOrigins = { ...origins };
      Object.values(LoggerOrigin).forEach(
        (o) => (newOrigins[o] = { isOn: true })
      );
      blockedOrigins.forEach((v) => {
        newOrigins[v] = { isOn: false };
      });
      setOrigins(newOrigins);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h1 className="font-bold text-xl mt-sm">DEBUGGER</h1>
      <DemoContainer title={'LOGGER ORIGINS ENABLED'}>
        <div className="mt-sm">
          {Object.values(LoggerOrigin).map((o) => {
            return (
              <div
                key={`${o}-${origins[o].isOn.toString()}`}
                className="flex flex-row mb-sm last:mb-0"
              >
                <div>
                  <button
                    onClick={() => Logger.disableOrigin(o)}
                    className={cn(
                      'w-14 border',
                      origins[o].isOn ? 'bg-silver' : 'bg-error'
                    )}
                  >
                    OFF
                  </button>
                  <button
                    onClick={() => Logger.enableOrigin(o)}
                    className={cn(
                      'ml-xs w-14 border',
                      origins[o].isOn ? 'bg-success' : 'bg-silver'
                    )}
                  >
                    ON
                  </button>
                </div>
                <p className="ml-sm">
                  {o} - {origins[o].isOn ? 'Enabled' : 'Disabled'}
                </p>
              </div>
            );
          })}
        </div>
      </DemoContainer>
    </>
  );
}
