import { FitlineLogo, PMLogo } from 'main/components/common/logos';
import { Paragraph } from 'main/components/common/text';
import { useThemeContext } from 'main/components/contexts/theme/ThemeContext';
import { ParentComponent } from 'main/schemas/ParentComponent';
import { AppTheme } from 'main/schemas/Theme';
import AuthenticationService from 'main/services/auth/AuthenticationService';
import { FC, useMemo } from 'react';

import { Footer } from './Footer';
import { UserDetails } from './UserDetails';

const styles = {
  page: 'flex flex-col shadow bg-white mt-0 xs:mt-md md:mt-lg lg:mt-xl max-w-panel mx-auto xs:rounded-panel',
  pageHeader: 'flex justify-between p-sm md:p-md 2xl:p-lg',
  pageHeaderDetails:
    'flex flex-col justify-between text-right text-sm break-all',
};

interface PageLayoutProps extends ParentComponent {
  readonly headerDescription?: string;
}

export const PageLayout: FC<PageLayoutProps> = ({
  headerDescription,
  children,
}) => {
  const { theme } = useThemeContext();

  const logo = useMemo(() => {
    if (theme === AppTheme.Fitline) {
      return <FitlineLogo />;
    }

    if (theme === AppTheme.PM) {
      return <PMLogo showClaim />;
    }

    return null;
  }, [theme]);

  return (
    <>
      <div className={styles.page}>
        {AuthenticationService.user.isPreviewUser && (
          <div className="bg-error animate-pulse text-center text-white p-sm">
            Preview mode
          </div>
        )}
        <header className={styles.pageHeader}>
          {logo}
          <div className={styles.pageHeaderDetails}>
            <UserDetails />
            {headerDescription && (
              <span className="ml-sm md:ml-md 2xl:ml-lg text-silver">
                <Paragraph>{headerDescription}</Paragraph>
              </span>
            )}
          </div>
        </header>
        <main>{children}</main>
      </div>
      <Footer />
    </>
  );
};
