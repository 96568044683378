export function NuveiTwint(className: string) {
  return (
    <svg
      className={className}
      viewBox="0 0 2261.257 857"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <radialGradient
          cx="22.357%"
          cy="8.811%"
          fx="22.357%"
          fy="8.811%"
          r="113.202%"
          gradientTransform="matrix(1 0 0 .8888 0 .01)"
          id="a"
        >
          <stop stopColor="#FC0" offset="0%" />
          <stop stopColor="#FFC800" offset="9.157%" />
          <stop stopColor="#FFBD00" offset="17.39%" />
          <stop stopColor="#FFAB00" offset="25.28%" />
          <stop stopColor="#FF9100" offset="32.95%" />
          <stop stopColor="#FF7000" offset="40.46%" />
          <stop stopColor="#FF4700" offset="47.86%" />
          <stop stopColor="#FF1800" offset="55.03%" />
          <stop stopColor="red" offset="58.22%" />
          <stop stopColor="red" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="2.552%"
          cy="14.432%"
          fx="2.552%"
          fy="14.432%"
          r="139.457%"
          gradientTransform="matrix(1 0 0 .6598 0 .05)"
          id="b"
        >
          <stop stopColor="#00B4E6" offset="0%" />
          <stop stopColor="#00B0E3" offset="20.1%" />
          <stop stopColor="#01A5DB" offset="38.98%" />
          <stop stopColor="#0292CD" offset="57.37%" />
          <stop stopColor="#0377BA" offset="75.46%" />
          <stop stopColor="#0455A1" offset="93.16%" />
          <stop stopColor="#054696" offset="100%" />
        </radialGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="M2230.27 857H31.37A31.19 31.19 0 010 825.63V31.37A31.19 31.19 0 0131.37 0h2198.5a31.2 31.2 0 0131.38 31.37v794.26c.39 17.47-13.9 31.37-30.98 31.37z"
          fill="#000"
        />
        <path
          d="M703.7 575.83c0 8.34-5.95 18.67-13.1 22.64L435.25 745.8c-7.15 4.37-19.06 4.37-26.2 0L153.68 598.47c-7.15-4.37-13.1-14.3-13.1-22.64V280.77c0-8.34 5.95-18.67 13.1-22.64L409.04 110.8c7.15-4.37 19.06-4.37 26.21 0L690.6 258.13c7.15 4.37 13.1 14.3 13.1 22.64v295.06z"
          fill="#FFF"
        />
        <g fill="#FFF">
          <path d="M2121.45 302.21h-228.74v54.41h81.8v233.51h64.74V356.62h82.2zM1082.57 302.21H853.82v54.41h82.2v233.51h64.74V356.62h81.8zM1744.58 293.08c-71.88 0-112 46.07-112 112.39v184.66h63.95V403.88c0-29 17.07-51.23 48.84-51.23 31.38 0 48.45 26.2 48.45 51.23v186.25h63.94V405.47c0-66.32-41.3-112.39-113.18-112.39zM1502.73 302.21v287.92h64.33V302.21zM1285.9 416.59l2.38 12.7 59.97 160.84h26.2l81.81-287.92h-63.14l-39.31 151.3-1.99 16.3-3.18-16.3-52.42-151.3h-20.65l-52.02 151.3-3.18 16.3-2.38-16.3-38.92-151.3h-63.14l81.8 287.92h26.22l59.96-160.84 1.99-12.7" />
        </g>
        <path
          d="M343.27 139.4l-66.32 97.68-34.15-52.42 39.31-58.77c7.15-10.33 23.03-39.32 4.77-78.63C272.18 15.49 240.4 0 212.6 0c-27.8 0-58.37 14.3-74.26 47.26-18.27 37.73-2.38 67.51 4.37 77.44 0 0 21.84 32.17 40.1 59.17l29.8 42.89 44.47 67.9c.4.4 7.55 11.13 19.86 11.13 11.91 0 19.06-10.72 20.25-11.92L401.65 139.4h-58.38zm-130.66 2.37s-17.47-26.6-28.59-44.87c-12.31-19.86 1.59-49.24 28.6-49.24 27.4 0 40.9 29.38 28.59 49.24-11.12 18.66-28.6 44.87-28.6 44.87z"
          fill="url(#a)"
          transform="translate(211.518 289.903)"
        />
        <path
          d="M148.28 233.9l-65.53-91.73s-17.47-26.6-28.59-44.87c-12.31-19.86 1.59-49.25 28.6-49.25 3.57 0 6.74.4 9.52 1.2l23.04-42.1C104.6 2.78 93.48.4 82.75.4 54.95.4 24.38 14.7 8.5 47.66c-18.27 37.72-2.38 67.5 4.37 77.43l114.37 169.58c.8 1.59 8.34 12.3 20.25 12.3 12.32 0 19.07-10.32 20.26-11.9l34.55-52.43-29.79-43.68-24.22 34.95z"
          fill="url(#b)"
          transform="translate(211.518 289.903)"
        />
      </g>
    </svg>
  );
}
