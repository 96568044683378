import { InstrumentBrand } from 'main/schemas/PaymentInstrument';
import { useTranslation } from 'react-i18next';

export const useUserFriendlyPrefixForInstrumentBrand = () => {
  const { t } = useTranslation();

  return (brand: InstrumentBrand) => {
    switch (brand) {
      case InstrumentBrand.Amex:
        return 'Amex';
      case InstrumentBrand.Diners:
        return 'Diners';
      case InstrumentBrand.Discover:
        return 'Discover';
      case InstrumentBrand.Elo:
        return 'Elo';
      case InstrumentBrand.Jcb:
        return 'Jcb';
      case InstrumentBrand.Maestro:
        return 'Maestro';
      case InstrumentBrand.MasterCard:
        return 'Mastercard';
      case InstrumentBrand.DirectDebit:
        return t('Direct debit');
      case InstrumentBrand.UnionPay:
        return 'UPay';
      case InstrumentBrand.Visa:
        return 'Visa';
      default:
        return undefined;
    }
  };
};
