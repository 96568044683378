import { ApiError } from 'main/services/utils/ApiError';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SomethingWentWrong } from '../general-errors/SomethingWentWrong';
import { ForbidenError } from './ForbidenError';

interface HttpErrorProps {
  readonly err: ApiError;
}

export const HttpError: FC<HttpErrorProps> = ({ err }) => {
  const { i18n } = useTranslation();

  if (err.status === 403 && i18n.isInitialized) {
    return <ForbidenError />;
  }

  return <SomethingWentWrong err={err} />;
};
