import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { registerStartupable } from 'main/services/base/StartupHelper';

import PaymentStore from '../payments/PaymentStore';
import { OptionalStartupable } from './../base/Startupable';
import { getTransbankUrl } from './helpers/TransbankApi';

interface ParamsUsedInRequest {
  readonly paymentIntentId: string;
  readonly locale: string;
  readonly telemetryConsent: boolean;
}

class TransbankService implements OptionalStartupable {
  public readonly name = 'TransbankService';
  public readonly moduleId = PaymentModuleId.Transbank;

  private dataUsedForRequest: ParamsUsedInRequest | undefined;
  private redirectUrl: string | undefined;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public startup(): Promise<void> {
    return Promise.resolve();
  }

  public shouldStart(): boolean {
    return PaymentStore.allowedPaymentModules.includes(this.moduleId);
  }

  public getRedirectUrl(
    locale: SupportedLocale,
    telemetryConsent = false
  ): Promise<string> {
    const paymentIntentId = PaymentStore.paymentIntentId;

    if (
      this.shouldReturnCachedUrl(paymentIntentId, locale, telemetryConsent) &&
      this.redirectUrl
    ) {
      return Promise.resolve(this.redirectUrl);
    }

    return getTransbankUrl(paymentIntentId, locale, telemetryConsent).then(
      (url) => {
        this.redirectUrl = url;
        this.dataUsedForRequest = {
          paymentIntentId,
          locale,
          telemetryConsent,
        };

        return this.redirectUrl;
      }
    );
  }

  private shouldReturnCachedUrl(
    paymentIntentId: string,
    locale: SupportedLocale,
    telemetryConsent: boolean
  ) {
    return (
      this.dataUsedForRequest &&
      this.dataUsedForRequest.paymentIntentId === paymentIntentId &&
      this.dataUsedForRequest.locale === locale &&
      this.dataUsedForRequest.telemetryConsent === telemetryConsent
    );
  }
}

export default new TransbankService();
