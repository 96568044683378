import { PageLayout } from 'main/components/common';
import { AlertError } from 'main/components/common/alerts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface SomethingWentWrongProps {
  readonly err?: Error;
  readonly description?: string;
  readonly orderId?: string;
}

export const SomethingWentWrong: FC<SomethingWentWrongProps> = ({
  err,
  description,
  orderId,
}) => {
  const { t } = useTranslation();

  const alertTitle = err ? err.name : t('Something went wrong!');
  const alertDescription = err ? err.message : description;

  return (
    <PageLayout
      headerDescription={orderId ? `${t('Order')}: ${orderId}` : undefined}
    >
      <AlertError title={alertTitle} description={alertDescription} />
    </PageLayout>
  );
};
