import {
  IconButton,
  PrimaryButton,
  SecondaryButton,
  SimpleButton,
} from 'main/components/common';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const CommonButtons: FC = () => {
  return (
    <DemoContainer title={'UI BUTTONS'}>
      <div className="flex flex-row flex-wrap gap-4 mb-4">
        <PrimaryButton>Button</PrimaryButton>
        <PrimaryButton disabled={true}>Button</PrimaryButton>
      </div>
      <div className="flex flex-row flex-wrap gap-4 mb-4">
        <SecondaryButton>Secondary Button</SecondaryButton>
        <SecondaryButton disabled={true}>Secondary Button</SecondaryButton>
        <SecondaryButton isSmall={true}>Secondary Button</SecondaryButton>
      </div>
      <div className="flex flex-row flex-wrap gap-4">
        <SimpleButton>Button</SimpleButton>
        <SimpleButton disabled={true}>Button</SimpleButton>
        <SimpleButton isSmall={true}>Button</SimpleButton>
      </div>
      <div className="flex flex-row flex-wrap gap-4">
        <IconButton icon="IconButton" />
        <IconButton icon="IconButton" disabled />
        <IconButton icon="IconButton" isSmall />
      </div>
    </DemoContainer>
  );
};
