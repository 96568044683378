import Bowser from 'bowser';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';

export function excludeBraintreeSepaForSafari(methods: PaymentModuleId[]) {
  /*
  TODO: This is a temp funtion to exclude Braintree SEPA from the list of avilable payment methods for working in Safari browser.
        Once Braintree will fix issue with Safari on their side this code shouls be removed and no exclusion should be made.
  */
  const browser = Bowser.getParser(window.navigator.userAgent);

  return methods.filter(function (item) {
    const isSafari = browser.getBrowser().name === 'Safari';
    const isBraintreeSepa = item === PaymentModuleId.BraintreeSepa;
    return !(isBraintreeSepa && isSafari);
  });
}
