import Navigation from './NavigationService';

export class NavigationSubscription {
  private id: string;

  constructor(id: string) {
    this.id = id;
  }

  public unsubscribe() {
    Navigation.unsubscribe(this.id);
  }
}
