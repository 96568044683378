import cn from 'clsx';
import * as React from 'react';

import { BasicBadgeProps } from './schemas';

const styles = {
  badge: 'rounded border px-xs text-xs',
};

export const BasicBadge: React.FunctionComponent<BasicBadgeProps> = ({
  extraClassName,
  children,
}) => {
  return <span className={cn(styles.badge, extraClassName)}>{children}</span>;
};
