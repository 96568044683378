import { FC } from 'react';

import type { IconProps } from './schemas';

export const CheckMarkIcon: FC<IconProps> = ({
  className = 'h-4 w-4 self-center inline-block',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 16 16"
    >
      <path
        d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7,11.4L3.6,8L5,6.6l2,2l4-4L12.4,6L7,11.4z"
        fill="currentColor"
      />
    </svg>
  );
};
