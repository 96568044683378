import cn from 'clsx';
import type { ParentComponent } from 'main/schemas/ParentComponent';
import { FC } from 'react';

interface ParagraphProps extends ParentComponent {
  readonly isError?: boolean;
  readonly ellipsisOnOverflow?: boolean;
  readonly disabled?: boolean;
}

const styles = {
  paragraph: 'font-app font-normal m-0',
  errorParagraph: 'text-error',
  paragraphOverflow:
    'overflow-hidden whitespace-nowrap overflow-ellipsis w-full',
};

export const Paragraph: FC<ParagraphProps> = ({
  isError = false,
  ellipsisOnOverflow,
  disabled = false,
  children,
}) => {
  return (
    <p
      className={cn(
        styles.paragraph,
        isError && styles.errorParagraph,
        ellipsisOnOverflow ? styles.paragraphOverflow : 'whitespace-pre-wrap',
        disabled && 'disabled'
      )}
    >
      {children}
    </p>
  );
};
