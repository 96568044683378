import { PaymentIcon } from 'main/components/common';
import { InstrumentIcon } from 'main/schemas/PaymentInstrument';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const PaymentIcons: FC = () => {
  return (
    <DemoContainer title={'PAYMENT ICONS'}>
      <div className="flex flex-row flex-wrap gap-2">
        {Object.values(InstrumentIcon).map((i) => (
          <PaymentIcon icon={i} key={i} />
        ))}
      </div>
    </DemoContainer>
  );
};
