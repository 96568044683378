import type { FC } from 'react';

interface ICountryFlagProps {
  readonly countryCode: string;
}

/**
 * Wrapper component using https://flagpedia.net/download/api to render country
 * flags.
 * @param countryCode the country code representing the flag
 * @returns
 */
export const CountryFlag: FC<ICountryFlagProps> = ({ countryCode }) => {
  countryCode = countryCode.toLowerCase();

  return (
    <img
      className="w-6 h-4 shadow object-cover"
      src={`https://flagcdn.com/h20/${countryCode}.png`}
      srcSet={`https://flagcdn.com/h40/${countryCode}.png 2x, https://flagcdn.com/h60/${countryCode}.png 3x`}
      height="16"
      alt={countryCode}
    />
  );
};
