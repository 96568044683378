import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';

import { OptionalStartupable } from '../base/Startupable';
import { registerStartupable } from '../base/StartupHelper';
import PaymentStore from '../payments/PaymentStore';
import { getProcessingKZRedirectUrl } from './helpers/ProcessingKZApi';
import { ProcessingKZSupportedLocale } from './helpers/ProcessingKZSupportedLocalesHelper';

class ProcessingKZService implements OptionalStartupable {
  public readonly name = 'ProcessingKZService';
  public readonly moduleId = PaymentModuleId.ProcessingKZ;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  shouldStart(): boolean {
    const isAllowed = PaymentStore.allowedPaymentModules.includes(
      this.moduleId
    );

    return isAllowed;
  }

  startup(): Promise<void> {
    return Promise.resolve();
  }

  public getRedirectUrl(
    locale: SupportedLocale,
    telemetryConsent = false
  ): Promise<string> {
    const paymentIntentId = PaymentStore.paymentIntentId;

    const processingKZLocale = ProcessingKZSupportedLocale[locale];

    return getProcessingKZRedirectUrl(
      paymentIntentId,
      processingKZLocale,
      telemetryConsent
    );
  }
}

export default new ProcessingKZService();
