export const ForceAuthorizeFor3dsStatuses = [
  'lookup_error',
  'authentication_unavailable',
  'unsupported_card',
  'lookup_not_enrolled',
  'lookup_bypassed',
  'unsupported_account_type',
  'unsupported_three_d_secure_version',
  'authentication_bypassed',
  'lookup_failed_acs_error',
  'data_only_successful',
];
