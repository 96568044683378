export function GooglePay(className: string) {
  return (
    <svg
      width="752px"
      height="400px"
      viewBox="0 0 752 400"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g
        id="Payment-Method-Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="google-pay-mark_800" fillRule="nonzero">
          <path
            d="M552,0 L200,0 C90,0 0,90 0,200 L0,200 C0,310 90,400 200,400 L552,400 C662,400 752,310 752,200 L752,200 C752,90 662,0 552,0 Z"
            id="Base_1_"
            fill="#FFFFFF"
          ></path>
          <path
            d="M552,16.2 C576.7,16.2 600.7,21.1 623.3,30.7 C645.2,40 664.8,53.3 681.8,70.2 C698.7,87.1 712,106.8 721.3,128.7 C730.9,151.3 735.8,175.3 735.8,200 C735.8,224.7 730.9,248.7 721.3,271.3 C712,293.2 698.7,312.8 681.8,329.8 C664.9,346.7 645.2,360 623.3,369.3 C600.7,378.9 576.7,383.8 552,383.8 L200,383.8 C175.3,383.8 151.3,378.9 128.7,369.3 C106.8,360 87.2,346.7 70.2,329.8 C53.3,312.9 40,293.2 30.7,271.3 C21.1,248.7 16.2,224.7 16.2,200 C16.2,175.3 21.1,151.3 30.7,128.7 C40,106.8 53.3,87.2 70.2,70.2 C87.1,53.3 106.8,40 128.7,30.7 C151.3,21.1 175.3,16.2 200,16.2 L552,16.2 M552,0 L200,0 C90,0 0,90 0,200 L0,200 C0,310 90,400 200,400 L552,400 C662,400 752,310 752,200 L752,200 C752,90 662,0 552,0 L552,0 Z"
            id="Outline"
            fill="#3C4043"
          ></path>
          <g id="G_Pay_Lockup_1_" transform="translate(114.500000, 114.400000)">
            <g
              id="Pay_Typeface_3_"
              transform="translate(224.900000, 10.900000)"
              fill="#3C4043"
            >
              <path
                d="M19.2,88.9 L19.2,149.4 L-1.13686838e-13,149.4 L-1.13686838e-13,0 L50.9,0 C63.8,0 74.8,4.3 83.8,12.9 C93,21.5 97.6,32 97.6,44.4 C97.6,57.1 93,67.6 83.8,76.1 C74.9,84.6 63.9,88.8 50.9,88.8 L19.2,88.8 L19.2,88.9 Z M19.2,18.4 L19.2,70.5 L51.3,70.5 C58.9,70.5 65.3,67.9 70.3,62.8 C75.4,57.7 78,51.5 78,44.5 C78,37.6 75.4,31.5 70.3,26.4 C65.3,21.1 59,18.5 51.3,18.5 L19.2,18.5 L19.2,18.4 Z"
                id="Letter_p_3_"
              ></path>
              <path
                d="M147.8,43.8 C162,43.8 173.2,47.6 181.4,55.2 C189.6,62.8 193.7,73.2 193.7,86.4 L193.7,149.4 L175.4,149.4 L175.4,135.2 L174.6,135.2 C166.7,146.9 156.1,152.7 142.9,152.7 C131.6,152.7 122.2,149.4 114.6,142.7 C107,136 103.2,127.7 103.2,117.7 C103.2,107.1 107.2,98.7 115.2,92.5 C123.2,86.2 133.9,83.1 147.2,83.1 C158.6,83.1 168,85.2 175.3,89.4 L175.3,85 C175.3,78.3 172.7,72.7 167.4,68 C162.1,63.3 155.9,61 148.8,61 C138.1,61 129.6,65.5 123.4,74.6 L106.5,64 C115.8,50.5 129.6,43.8 147.8,43.8 Z M123,118 C123,123 125.1,127.2 129.4,130.5 C133.6,133.8 138.6,135.5 144.3,135.5 C152.4,135.5 159.6,132.5 165.9,126.5 C172.2,120.5 175.4,113.5 175.4,105.4 C169.4,100.7 161.1,98.3 150.4,98.3 C142.6,98.3 136.1,100.2 130.9,103.9 C125.6,107.8 123,112.5 123,118 Z"
                id="Letter_a_3_"
              ></path>
              <polygon
                id="Letter_y_3_"
                points="298.1 47.1 234.1 194.3 214.3 194.3 238.1 142.8 195.9 47.1 216.8 47.1 247.2 120.5 247.6 120.5 277.2 47.1"
              ></polygon>
            </g>
            <g id="G_Mark_1_">
              <path
                d="M167.73,87.6 C167.73,81.34 167.17,75.35 166.13,69.59 L85.65,69.59 L85.65,102.59 L132,102.6 C130.12,113.58 124.07,122.94 114.8,129.18 L114.8,150.59 L142.39,150.59 C158.5,135.68 167.73,113.64 167.73,87.6 Z"
                id="Blue_500"
                fill="#4285F4"
              ></path>
              <path
                d="M114.81,129.18 C107.13,134.36 97.24,137.39 85.67,137.39 C63.32,137.39 44.36,122.33 37.57,102.03 L9.11,102.03 L9.11,124.11 C23.21,152.09 52.19,171.29 85.67,171.29 C108.81,171.29 128.25,163.68 142.4,150.58 L114.81,129.18 Z"
                id="Green_500_1_"
                fill="#34A853"
              ></path>
              <path
                d="M34.89,85.65 C34.89,79.95 35.84,74.44 37.57,69.26 L37.57,47.18 L9.11,47.18 C3.28,58.75 -5.68434189e-14,71.81 -5.68434189e-14,85.65 C-5.68434189e-14,99.49 3.29,112.55 9.11,124.12 L37.57,102.04 C35.84,96.86 34.89,91.35 34.89,85.65 Z"
                id="Yellow_500_1_"
                fill="#FABB05"
              ></path>
              <path
                d="M85.67,33.9 C98.3,33.9 109.61,38.25 118.54,46.75 L142.99,22.32 C128.14,8.49 108.78,5.68434189e-14 85.67,5.68434189e-14 C52.2,5.68434189e-14 23.21,19.2 9.11,47.18 L37.57,69.26 C44.36,48.96 63.32,33.9 85.67,33.9 Z"
                id="Red_500"
                fill="#E94235"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
