import type { ParentComponent } from 'main/schemas/ParentComponent';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { Component, ErrorInfo, Fragment } from 'react';

export class ErrorBoundaries extends Component<ParentComponent> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    TelemetryService.trackException(error, 'ErrorBoundaries', { errorInfo });

    Logger.error(
      LoggerOrigin.ErrorBoundaries,
      '(╯°□°）╯︵ ┻━┻',
      error,
      errorInfo
    );
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}
