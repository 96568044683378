export function BankTransfer(className: string) {
  return (
    <svg
      className={className}
      width="85"
      height="52"
      viewBox="0 0 85 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.77 22.043L42.1624 4.68642L70.5548 22.043H13.77Z"
        fill="#6CC4F5"
        stroke="#2A4B55"
        strokeWidth="4.4"
      />
      <rect
        x="20.7305"
        y="26.7026"
        width="4.91892"
        height="16.2553"
        rx="2.45946"
        fill="#2A4B55"
      />
      <rect
        x="39.7031"
        y="26.7026"
        width="4.91892"
        height="16.2553"
        rx="2.45946"
        fill="#2A4B55"
      />
      <rect
        x="58.6758"
        y="26.7026"
        width="4.91892"
        height="16.2553"
        rx="2.45946"
        fill="#2A4B55"
      />
      <rect
        x="13"
        y="46.3784"
        width="57.6216"
        height="5"
        rx="2.5"
        fill="#2A4B55"
      />
      <g clipPath="url(#clip0_100_2103)">
        <path
          d="M61.1347 28.9866C68.4114 28.9866 74.3103 23.0877 74.3103 15.8109C74.3103 8.5342 68.4114 2.63525 61.1347 2.63525C53.8579 2.63525 47.959 8.5342 47.959 15.8109C47.959 23.0877 53.8579 28.9866 61.1347 28.9866Z"
          fill="white"
          stroke="#2A4B55"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.1355 2.63525C64.4311 6.24322 66.304 10.9254 66.4058 15.8109C66.304 20.6964 64.4311 25.3786 61.1355 28.9866C57.8399 25.3786 55.967 20.6964 55.8652 15.8109C55.967 10.9254 57.8399 6.24322 61.1355 2.63525Z"
          fill="#6CC4F5"
          stroke="#2A4B55"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.959 15.811H74.3103"
          stroke="#2A4B55"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_100_2103">
          <rect
            width="31.6216"
            height="31.6216"
            fill="white"
            transform="translate(45.3242)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
