import { BoldHeading, Heading } from 'main/components/common';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const CommonHeadings: FC = () => {
  return (
    <DemoContainer title={'UI HEADINGS'}>
      <Heading>Normal Heading</Heading>
      <BoldHeading>Bold Heading</BoldHeading>
    </DemoContainer>
  );
};
