import { CheckMarkIcon } from 'main/components/common';
import { FC, ReactNode } from 'react';

import { Alert } from './Alert';

interface AlertSuccessProps {
  readonly title: string;
  readonly description?: string;
  readonly children?: ReactNode;
}

export const AlertSuccess: FC<AlertSuccessProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <Alert
      icon={<CheckMarkIcon className="w-12 text-success" />}
      title={title}
      description={description}
    >
      {children}
    </Alert>
  );
};
