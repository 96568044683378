import { ThreeDSecureInfo } from 'braintree-web/three-d-secure';

import { ThreeDSecureInfoV2 } from './../schemas/schemas';

export class LiabilityNotShiftedError extends Error {
  public readonly failureStatus: string | undefined;

  constructor(payload: ThreeDSecureInfo) {
    const enrichedPayload = payload as ThreeDSecureInfoV2;
    super(`Liability not shifted! status = "${enrichedPayload.status}"`);
    this.name = 'LiabilityNotShiftedError';
    this.failureStatus = enrichedPayload.status;
  }
}
