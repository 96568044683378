import { FC } from 'react';

import type { IconProps } from './schemas';

export const InfoIcon: FC<IconProps> = ({
  className = 'h-4 w-4 self-center inline-block',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 16 16"
    >
      <path
        d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M9,12H7V7h2V12z M8,6C7.4,6,7,5.6,7,5s0.4-1,1-1 s1,0.4,1,1S8.6,6,8,6z"
        fill="currentColor"
      />
    </svg>
  );
};
