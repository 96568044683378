import { isProdBuild } from 'main/utils/env';

import Logger, { LoggerOrigin } from '../Logger';
import { StartupableCycleError } from './errors/StartupableCycleError';
import { StartupableStartupError } from './errors/StartupableStartupError';
import { UnknownStartupableError } from './errors/UnknownStartupableError';
import { TBootstrapMode } from './ServiceRegistrar';
import {
  Startupable,
  StartupableInfo,
  OptionalStartupable,
} from './Startupable';

interface StartupFailure {
  readonly serviceName: string;
  readonly cause: Error;
}

const _registedStartupables: StartupableInfo[] = [];

// Name of services that failed to start
const _optionalStartupablesFailedStart: StartupFailure[] = [];

export function registerStartupable(
  service: Startupable | OptionalStartupable,
  dependencies?: Startupable[]
): void {
  if (isAlreadyRegistered(service)) {
    return;
  }

  const result = {
    service,
    dependencies: dependencies ?? [],
    started: false,
  };

  if (!isProdBuild) {
    console.log(`[StartupHelper] Registered "${service.name}"`);
  }
  _registedStartupables.push(result);
}

export async function startInSequence(
  services: Startupable[],
  bootstrapMode: TBootstrapMode
): Promise<void> {
  // The reduce() method exeucutes the callback on each element, passing the
  // result for the next one. We can leverage this behavior with promises to
  // ensure startup is done in sequence
  return sortStartupables(services).reduce(async (acc, s) => {
    await acc;
    await startService(s.service, bootstrapMode);
    return Promise.resolve();
  }, Promise.resolve());
}

export function isStartupableStarted(service: Startupable) {
  try {
    return getStartupableInfo(service).started;
  } catch (e) {
    // Swallow the exception here. If service is not registered it is considered
    // as not started.
    return false;
  }
}

export function getOptionalStartupableFailures(): ReadonlyArray<StartupFailure> {
  return [..._optionalStartupablesFailedStart];
}

function startService(
  service: Startupable,
  bootstrapMode: TBootstrapMode
): Promise<void> {
  const startInfo = getStartupableInfo(service);

  if (startInfo.started) {
    return Promise.resolve();
  }

  const isOptionalStartupable = 'shouldStart' in startInfo.service === true;
  const shouldStart = isOptionalStartupable
    ? (startInfo.service as OptionalStartupable).shouldStart()
    : true;

  if (isOptionalStartupable && !shouldStart) {
    // Ignore OptionalStartupable
    Logger.log(
      LoggerOrigin.StartupHelper,
      `Service "${service.name}" shouldn't start.`
    );
    return Promise.resolve();
  }

  return service
    .startup(bootstrapMode)
    .then(() => {
      startInfo.started = true;
      if (!isProdBuild) {
        Logger.log(
          LoggerOrigin.StartupHelper,
          `Startup completed for "${service.name}"`
        );
      }
    })
    .catch((e) => {
      if (!isProdBuild) {
        Logger.error(
          LoggerOrigin.StartupHelper,
          `Startup failed for "${service.name}"`,
          {
            cause: e,
          }
        );
      }

      // Interrupt the startup sequence if when mandatory startupable fails
      // For optionals, record the failure and continue.
      if (!isOptionalStartupable) {
        // Rethrow to break the startup sequence
        throw new StartupableStartupError(service.name, e);
      } else {
        _optionalStartupablesFailedStart.push({
          serviceName: service.name,
          cause: e,
        });

        Logger.warn(
          LoggerOrigin.StartupHelper,
          `Failed to start "${service.name}". Check the console for error details. Related payment methods will be unavailable.`
        );
      }
    });
}

function getStartupableInfo(service: Startupable): StartupableInfo {
  const info = _registedStartupables.find((info) => info.service === service);

  if (!info) {
    throw new UnknownStartupableError(service);
  }

  return info;
}

function isAlreadyRegistered(service: Startupable): boolean {
  return _registedStartupables.map((s) => s.service).includes(service);
}

function sortStartupables(servicesToStart: Startupable[]): StartupableInfo[] {
  // const startInfos = servicesToStart.map(getStartupableInfo)

  // Filter out already started services
  const startInfos = servicesToStart.map(getStartupableInfo).map((info) => {
    info.dependencies = info.dependencies.filter(
      (d) => !getStartupableInfo(d).started
    );
    return info;
  });

  // Kahn's algorithm for Topological Sorting
  // - works with DAGs only! (Direct Acyclic Graph)
  const sortedServices = [];
  const readyServices = startInfos.filter((s) => s.dependencies.length === 0);
  let servicesToProcess = startInfos.filter((s) => s.dependencies.length > 0);

  while (readyServices.length > 0) {
    const s = readyServices.shift() as StartupableInfo;
    sortedServices.push(s);

    servicesToProcess = servicesToProcess.filter((p) => {
      let isReady = false;

      const i = p.dependencies.findIndex((d) => d === s.service);

      if (i !== -1) {
        p.dependencies?.splice(i, 1);
      }

      if (p.dependencies.length === 0) {
        isReady = true;
        readyServices.push(p);
      }

      // Keep the services that still need to be processed
      return !isReady;
    });
  }

  if (servicesToProcess.length > 0) {
    throw new StartupableCycleError(servicesToProcess);
  }

  return sortedServices;
}
