import AuthenticationService from 'main/services/auth/AuthenticationService';
import { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

export const AuthCallback: FC = () => {
  const [initialRoute, setInitialRoute] = useState<string>();

  useEffect(() => {
    // Recover nonceToken from session storage to rebuild the AuthenticationService
    // with the previous state, in case it exists.
    const nonceToken =
      window.sessionStorage.getItem('pmi.payments.nt') ?? undefined;
    if (nonceToken !== undefined) {
      // Clear it, we don't need to keep it in session storage anymore
      window.sessionStorage.removeItem('pmi.payments.nt');
    }

    AuthenticationService.startup(nonceToken)
      .then(() => AuthenticationService.finishSignIn())
      .then((state) => {
        if (state) {
          // Restore the satate provided when the signin process was started.
          // Please ensure the parsing of the state is in sync with the state
          // set in the AuthenticatedRoute component.
          setInitialRoute(state);
        }
      })
      .catch((err) => {
        console.error(err);
        setInitialRoute('/error');
      });
  }, []);

  return initialRoute ? <Navigate to={initialRoute} replace /> : null;
};
