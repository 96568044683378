import { DemoDrawer } from 'main/components/pages/dev-only/demo';
import { PaymentMethodsPage } from 'main/components/pages/payment-methods/PaymentMethodsPage';
import { SessionExpired } from 'main/components/pages/SessionExpired';
import { StoredInstrumentsPage } from 'main/components/pages/stored-intruments/StoredInstrumentsPage';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import { isProdBuild } from 'main/utils/env';
import { useEffect, useMemo, useState } from 'react';

import { ErrorHandler } from './components/common/errors/ErrorHandler';
import { PaymentIntentFlow } from './schemas/PaymentIntent';
import PaymentStore from './services/payments/PaymentStore';

function ManageApp() {
  const [internalPage, setInternalPage] = useState<InternalPage>();

  const pageToRender = useMemo(() => {
    switch (internalPage) {
      case InternalPage.PaymentMethods:
        return <PaymentMethodsPage flow={PaymentIntentFlow.Manage} />;
      case InternalPage.StatusError:
        return <ErrorHandler />;
      case InternalPage.StoredInstruments:
        return (
          <StoredInstrumentsPage
            instruments={PaymentStore.instruments}
            flow={PaymentStore.flow}
            canAddInstruments={PaymentStore.legalEntity !== undefined}
          />
        );
      case InternalPage.SessionExpired:
        return <SessionExpired />;
      default:
        return null;
    }
  }, [internalPage]);

  useEffect(() => {
    const navigationSubscription = NavigationService.subscribe(setInternalPage);
    return () => navigationSubscription.unsubscribe();
  }, []);

  return (
    <>
      {!isProdBuild && <DemoDrawer />}
      {pageToRender}
    </>
  );
}

export default ManageApp;
