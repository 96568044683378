import { PaymentModuleId } from './PaymentInstrument';
import { AppTheme } from './Theme';

export interface IPaymentIntent {
  readonly id: string;
  readonly customer: IPaymentIntentCustomer;
  readonly order: IPaymentIntentOrder;
  readonly urls: IPaymentIntentUrls;
  readonly isPayable: boolean;
}

export interface IPaymentIntentCustomer {
  readonly legalEntityNumber: number;
  readonly number: number;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly email: string | null;
  readonly phone: string | null;
  readonly company: string | null;
  readonly shippingAddress: IPaymentIntentShipping;
  readonly telemetryConsent: boolean;
  readonly flow: PaymentIntentFlow;
  readonly theme?: AppTheme;
  readonly allowedPaymentMethods: ReadonlyArray<PaymentModuleId>;
  readonly preSelectedPaymentMethod: PaymentModuleId | undefined;
  readonly skip3DS: boolean;
}

export interface IPaymentIntentShipping {
  readonly firstName: string;
  readonly lastName: string;
  readonly company: string;
  readonly streetAddress: string;
  readonly extendedAddress: string;
  readonly locality: string;
  readonly postalCode: string;
  readonly region: string;
  readonly countryCode: string;
}

export interface IPaymentIntentOrder {
  readonly number: string;
  readonly amount: number;
  readonly currency: string;
}

export interface IPaymentIntentUrls {
  readonly notification: string;
  readonly cancel: string;
  readonly failure: string;
  readonly success: string;
}

export enum PaymentIntentFlow {
  Cit = 'cit',
  Moto = 'moto',
  Manage = 'manage',
  Events = 'events',
}
