import { Startupable } from './base/Startupable';
import * as StartupHelper from './base/StartupHelper';

class UUIDService implements Startupable {
  public readonly name = 'UUIDService';

  public startup(): Promise<void> {
    return Promise.resolve();
  }

  public createId() {
    return Math.floor((Math.random() + 1) * 0x10000)
      .toString(16)
      .substring(1);
  }

  constructor() {
    StartupHelper.registerStartupable(this);
  }
}

export default new UUIDService();
