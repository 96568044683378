import { FC } from 'react';

export const PadlockIcon: FC = () => {
  return (
    <svg
      width={16}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      className="self-center inline-block"
    >
      <g fill="currentColor">
        <path
          data-color="color-2"
          d="M12,6H10V4A2,2,0,0,0,6,4V6H4V4a4,4,0,0,1,8,0Z"
        ></path>
        <path d="M14,7H2A1,1,0,0,0,1,8v7a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1V8A1,1,0,0,0,14,7ZM8,13a2,2,0,1,1,2-2A2,2,0,0,1,8,13Z"></path>
      </g>
    </svg>
  );
};
