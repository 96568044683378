import { ParentComponent } from 'main/schemas/ParentComponent';
import { FC } from 'react';

const styles = {
  heading: 'font-heading break-words m-0 font-heading-weight text-xl',
};

export const Heading: FC<ParentComponent> = ({ children }) => {
  return <h2 className={styles.heading}>{children}</h2>;
};
