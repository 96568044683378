import Logger, { LoggerOrigin } from 'main/services/Logger';
import { ApiError } from 'main/services/utils/ApiError';
import { FC, useEffect } from 'react';

import { SomethingWentWrong } from './general-errors/SomethingWentWrong';
import { HttpError } from './http-errors/HttpError';

interface ErrorHandlerProps {
  readonly err?: unknown;
}

export const ErrorHandler: FC<ErrorHandlerProps> = ({ err }) => {
  useEffect(() => {
    Logger.error(LoggerOrigin.ErrorBoundaries, '', err);
  }, [err]);

  let errorComponent = null;

  if (err !== undefined && !(err instanceof Error)) {
    errorComponent = <SomethingWentWrong err={err as Error} />;
  } else if (err !== undefined && err.name === 'ApiError') {
    errorComponent = <HttpError err={err as ApiError} />;
  } else if (
    err !== undefined &&
    err.name === 'StartupableStartupError' &&
    err.cause !== undefined
  ) {
    errorComponent = <ErrorHandler err={err.cause} />;
  } else {
    errorComponent = <SomethingWentWrong err={err} />;
  }

  return <>{errorComponent}</>;
};
