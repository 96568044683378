import { FC } from 'react';

import { LoadingIcon } from './icons';

export enum LoadingSpinnerSize {
  Small,
  Huge,
}

interface ILoadingSpinnerProps {
  readonly size?: LoadingSpinnerSize;
}

const HugeLoadingSpinner: FC = () => (
  <div className="w-screen h-screen flex flex-col bg-body items-center justify-center">
    <div className="flex items-center justify-center relative">
      <div className="z-10 rounded-full animate-spin-slow">
        <LoadingIcon className="w-32 text-primary" />
      </div>
    </div>
  </div>
);

const SmallLoadingSpinner: FC = () => (
  <div className="w-full flex items-center justify-center relative">
    <div className="z-10 rounded-full animate-spin-slow">
      <LoadingIcon className="w-6 text-primary" />
    </div>
  </div>
);

export const LoadingSpinner: FC<ILoadingSpinnerProps> = ({ size }) => {
  if (size === LoadingSpinnerSize.Small) {
    return <SmallLoadingSpinner />;
  }

  return <HugeLoadingSpinner />;
};
