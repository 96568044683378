import { FC } from 'react';

import type { IconProps } from './schemas';

export const CopyrightIcon: FC<IconProps> = ({
  className = 'self-center inline-block',
}) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" className={className}>
      <g fill="currentColor">
        <path d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 14a6 6 0 1 1 6-6 6.006 6.006 0 0 1-6 6Z"></path>
        <path d="M8.214 6a1.8 1.8 0 0 1 .833.177l.892.453.9-1.784-.891-.452A3.806 3.806 0 0 0 8.214 4C5.923 4 4.5 5.532 4.5 8s1.423 4 3.714 4a3.806 3.806 0 0 0 1.739-.394l.891-.452-.9-1.784-.892.453a1.8 1.8 0 0 1-.838.177C7.7 10 6.5 10 6.5 8s1.2-2 1.714-2Z"></path>
      </g>
    </svg>
  );
};
