import PaymentStore from 'main/services/payments/PaymentStore';
import { FC } from 'react';
import { Trans } from 'react-i18next';

export const BankTransferDetails: FC = () => {
  switch (PaymentStore.legalEntity) {
    case 910110:
      return <Trans i18nKey={'OfflinePayment_BankTransfer_910110_Info'} />;
    case 441000:
      return <Trans i18nKey={'OfflinePayment_BankTransfer_441000_Info'} />;
    case 790300:
      return <Trans i18nKey={'OfflinePayment_BankTransfer_790300_Info'} />;
    default:
      return null;
  }
};
