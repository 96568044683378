import { Heading, Paragraph } from 'main/components/common';
import { ParentComponent } from 'main/schemas/ParentComponent';
import { FC } from 'react';

interface AlertProps extends ParentComponent {
  readonly title?: string;
  readonly description?: React.ReactNode;
  readonly icon: React.ReactNode;
}

export const Alert: FC<AlertProps> = ({
  title,
  description,
  icon,
  children,
}) => {
  return (
    <div className="flex flex-col items-center text-center m-sm md:m-md">
      {icon}
      {title && (
        <span className="mt-sm md:mt-md">
          <Heading>{title}</Heading>
        </span>
      )}
      {description && (
        <span className="mt-xs">
          <Paragraph>{description}</Paragraph>
        </span>
      )}
      {children && <div className="flex w-full mt-sm md:mt-md">{children}</div>}
    </div>
  );
};
