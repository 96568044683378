export class GooglePayNotSupportedError extends Error {
  constructor(request: google.payments.api.IsReadyToPayRequest) {
    super(
      `Google Pay is not supported in this device. Request: ${JSON.stringify(
        request
      )}`
    );
    this.name = 'GooglePayNotSupportedError';
  }
}
