import { errorToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params';
import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { setClarityTag } from 'main/services/clarity';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import SubsidiaryService from 'main/services/subsidiary/SubsidiaryService';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const PayInSubsidiaryBtn: FC = () => {
  const { t } = useTranslation();
  const paymentModuleT = useUserFriendlyPaymentModuleName();

  return (
    <div className="flex">
      <PayButton
        onClick={() => {
          const currentPage = NavigationService.getInternalPage();
          NavigationService.navigateTo(InternalPage.StatusProcessing);
          SubsidiaryService.authorise()
            .then(() => {
              NavigationService.navigateTo(
                InternalPage.PaymentInSubsidiaryStatusSuccess
              );
              setClarityTag('pay', [
                RedirectStatus.Success,
                paymentModuleT(PaymentModuleId.PaymentAtSubsidiary),
              ]);
            })
            .catch((err) => {
              NavigationService.navigateTo(currentPage);
              TelemetryService.trackException(err, 'SubsidiaryBtn');
              Logger.error(
                LoggerOrigin.ReactComponent,
                'Payment at subsidiary failed',
                err
              );
              errorToast(
                t('Something went wrong!'),
                t('Failed to authorize payment at subsidiary.')
              );
              setClarityTag('pay', [
                RedirectStatus.Failure,
                paymentModuleT(PaymentModuleId.PaymentAtSubsidiary),
              ]);
            });
        }}
      />
    </div>
  );
};
