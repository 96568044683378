import { Component } from 'react';

import { UIBlockerBlockEvent, UIBlockerUnblockEvent } from './events';
import { UIBlockerProps } from './schemas';
import { UIBlockerContent } from './UIBlockerContent';

export class UIBlocker extends Component<unknown, UIBlockerProps> {
  constructor(props: unknown) {
    super(props);
    this.state = { blocked: false };
  }

  componentDidMount() {
    if (window?.document?.body) {
      window.document.body.addEventListener(
        UIBlockerBlockEvent,
        this._eventHandler
      );
      window.document.body.addEventListener(
        UIBlockerUnblockEvent,
        this._eventHandler
      );
    }
  }

  componentWillUnmount() {
    if (window?.document?.body) {
      window.document.body.removeEventListener(
        UIBlockerBlockEvent,
        this._eventHandler
      );
      window.document.body.removeEventListener(
        UIBlockerUnblockEvent,
        this._eventHandler
      );
    }
  }

  render() {
    return <UIBlockerContent blocked={this.state.blocked} />;
  }

  private _eventHandler = (event: Event) => {
    if (event.type === UIBlockerBlockEvent && !this.state.blocked) {
      this.setState({ blocked: true });
      return;
    }

    if (event.type === UIBlockerUnblockEvent && this.state.blocked) {
      this.setState({ blocked: false });
      return;
    }
  };
}
