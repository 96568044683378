import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';

import {
  isStartupableStarted,
  registerStartupable,
} from '../base/StartupHelper';
import PaymentStore from '../payments/PaymentStore';
import TelemetryService from '../telemetry/TelemetryService';
import { OptionalStartupable } from './../base/Startupable';
import { getBancontactUrl } from './helpers/WorldpayApi';
import { ParamsUsedInRequest } from './schemas';

class WorldpayBancontactService implements OptionalStartupable {
  public readonly name = 'WorldpayBancontactService';
  public readonly moduleId = PaymentModuleId.WorldpayBancontact;

  private _requestData: ParamsUsedInRequest | undefined;
  private _redirectUrl: string | undefined;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public shouldStart(): boolean {
    return PaymentStore.allowedPaymentModules.includes(this.moduleId);
  }

  public startup(): Promise<void> {
    this._requestData = undefined;
    this._redirectUrl = undefined;
    return Promise.resolve();
  }

  public async getRedirectUrl(locale: SupportedLocale): Promise<string> {
    const redirectUrl = this._redirectUrl;
    const telemetryConsent = isStartupableStarted(TelemetryService);
    const paymentIntentId = PaymentStore.paymentIntentId;

    if (
      this.shouldReturnCachedUrl(paymentIntentId, locale, telemetryConsent) &&
      redirectUrl
    ) {
      return Promise.resolve(redirectUrl);
    }

    const url = await getBancontactUrl(
      paymentIntentId,
      locale,
      telemetryConsent
    );
    this._redirectUrl = url;
    this._requestData = {
      paymentIntentId,
      locale,
      telemetryConsent,
    };
    return url;
  }

  private shouldReturnCachedUrl(
    paymentIntentId: string,
    locale: SupportedLocale,
    telemetryConsent: boolean
  ) {
    return (
      this._requestData &&
      this._requestData.paymentIntentId === paymentIntentId &&
      this._requestData.locale === locale &&
      this._requestData.telemetryConsent === telemetryConsent
    );
  }
}

export default new WorldpayBancontactService();
