import cn from 'clsx';
import { FC } from 'react';

interface ArrowIconProps {
  readonly direction: 'left' | 'right' | 'up' | 'down';
}

export const ArrowIcon: FC<ArrowIconProps> = ({ direction }) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(
        'transform self-center inline-block',
        direction === 'left' && '-rotate-90',
        direction === 'right' && 'rotate-90',
        direction === 'down' && 'rotate-180'
      )}
    >
      <g fill="currentColor">
        <polygon points="1.3,6.7 2.7,8.1 7,3.8 7,16 9,16 9,3.8 13.3,8.1 14.7,6.7 8,0"></polygon>
      </g>
    </svg>
  );
};
