import { HostedFields, hostedFields, HostedFieldsEvent } from 'braintree-web';
import {
  InstrumentIcon,
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';
import { registerStartupable } from 'main/services/base/StartupHelper';

import AuthenticationService from '../auth/AuthenticationService';
import Logger, { LoggerOrigin } from '../Logger';
import { getCardIcon } from '../payments/instruments/PaymentInstrumentsHelper';
import PaymentStore from '../payments/PaymentStore';
import TelemetryService from '../telemetry/TelemetryService';
import { OptionalStartupable } from './../base/Startupable';
import { BraintreeClient } from './BraintreeClient';
import { HostedFieldsInstanceMissingError } from './errors/HostedFieldsIntanceMissingError';
import { getBraintreeSecureNonce } from './helpers/BraintreeApi';
import { BTCCValidationResults } from './schemas/ValidationResults';

interface BTHostedFieldsForm {
  readonly cardholderName: BTHostedField;
  readonly expirationDate: BTHostedField;
  readonly number: BTHostedField;
  readonly cvv: BTHostedField;
}

interface BTHostedField {
  readonly container: string;
  readonly placeholder: string;
}

class BraintreeCardService implements OptionalStartupable {
  public readonly name = 'BraintreeCardService';
  public readonly moduleId = PaymentModuleId.BraintreeCard;

  private _braintreeClient!: BraintreeClient;
  private _hostedFieldsInstance: HostedFields | undefined;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public async startup(): Promise<void> {
    const defaultToken = await PaymentStore.getBraintreeDefaultToken();
    if (defaultToken === undefined) {
      throw new Error('Default token has no value!');
    }

    this._braintreeClient = new BraintreeClient(this.moduleId, defaultToken);

    if (PaymentStore.skip3DS) {
      await this._braintreeClient.init();
    } else {
      await this._braintreeClient.initWith3DS();
    }
  }

  public async reInit3DS(): Promise<void> {
    await this._braintreeClient.initWith3DS();
  }

  public shouldStart(): boolean {
    const isAllowed = PaymentStore.allowedPaymentModules.includes(
      this.moduleId
    );

    const hasAtLeastOneInstrument =
      PaymentStore.instruments.find((i) => i.moduleId === this.moduleId) !==
      undefined;

    return isAllowed || hasAtLeastOneInstrument;
  }

  public async setupHostedFields(
    config: BTHostedFieldsForm,
    onCardTypeChange?: (brandIcon: InstrumentIcon) => void,
    onValidityChange?: (result: BTCCValidationResults) => void
  ): Promise<void> {
    this._hostedFieldsInstance = await hostedFields.create({
      client: this._braintreeClient.client,
      styles: {
        input: 'braintree-cc-input',
        'input.invalid': 'braintree-cc-input-invalid',
      },
      fields: {
        cardholderName: config.cardholderName,
        expirationDate: config.expirationDate,
        number: config.number,
        cvv: config.cvv,
      },
    });

    if (onCardTypeChange) {
      this._hostedFieldsInstance.on(
        'cardTypeChange',
        (event: HostedFieldsEvent) => {
          if (event.emittedBy !== 'number') {
            return;
          }
          if (event.fields.number.isEmpty || event.cards.length > 1) {
            onCardTypeChange(InstrumentIcon.GenericCard);
          } else if (event.cards.length === 1) {
            onCardTypeChange(getCardIcon(event.cards[0].type));
          }
        }
      );
    }

    if (onValidityChange) {
      this._hostedFieldsInstance.on(
        'validityChange',
        (event: HostedFieldsEvent) => {
          const cardName = event.fields.cardholderName;
          const cardNumber = event.fields.number;
          const cardCvv = event.fields.cvv;
          const cardExpiration = event.fields.expirationDate;

          const invalidInputs = [];
          if (!cardName.isValid && !cardName.isEmpty) {
            invalidInputs.push(config.cardholderName.container);
          }

          if (!cardNumber.isValid && !cardNumber.isEmpty) {
            invalidInputs.push(config.number.container);
          }

          if (!cardCvv.isPotentiallyValid && !cardCvv.isEmpty) {
            invalidInputs.push(config.cvv.container);
          }

          if (!cardExpiration.isPotentiallyValid && !cardExpiration.isEmpty) {
            invalidInputs.push(config.expirationDate.container);
          }

          const result = {
            invalidIds: invalidInputs,
            isFilled:
              !cardName.isEmpty &&
              !cardNumber.isEmpty &&
              !cardExpiration.isEmpty &&
              !cardCvv.isEmpty,
          };

          Logger.log(
            LoggerOrigin.BraintreeCardService,
            'BraintreeCardService form validation',
            result
          );

          onValidityChange(result);
        }
      );
    }

    return Promise.resolve();
  }

  public async verifyAndAuthorizeNewInstrument(): Promise<void> {
    if (!this._hostedFieldsInstance) {
      throw new HostedFieldsInstanceMissingError();
    }

    const hostedfieldsPayload = await this._hostedFieldsInstance.tokenize();
    let nonce = hostedfieldsPayload.nonce;

    if (!PaymentStore.skip3DS) {
      nonce = await this.verify3DSAndLiability(
        nonce,
        hostedfieldsPayload.details.bin
      );
    }
    await this._braintreeClient.authorize(PaymentStore.paymentIntentId, nonce);
  }

  public async verifyAndTokenizeNewInstrument(): Promise<void> {
    if (!this._hostedFieldsInstance) {
      throw new HostedFieldsInstanceMissingError();
    }

    const hostedfieldsPayload = await this._hostedFieldsInstance.tokenize();
    let nonce = hostedfieldsPayload.nonce;

    if (!PaymentStore.skip3DS) {
      nonce = await this.verify3DSAndLiability(
        nonce,
        hostedfieldsPayload.details.bin
      );
    }

    const customerNumber = AuthenticationService.user.tpId;

    if (customerNumber === undefined) {
      throw new Error('Customer number is not set');
    }

    if (PaymentStore.legalEntity === undefined) {
      throw new Error('Legal entity cannot be undefined');
    }

    await this._braintreeClient.tokenize(
      PaymentStore.legalEntity,
      customerNumber,
      nonce
    );
  }

  public async authorizeWithoutVerification(): Promise<void> {
    if (!this._hostedFieldsInstance) {
      throw new HostedFieldsInstanceMissingError();
    }

    const hostedfieldsPayload = await this._hostedFieldsInstance.tokenize();

    return this._braintreeClient.authorize(
      PaymentStore.paymentIntentId,
      hostedfieldsPayload.nonce
    );
  }

  public async verifyAndAuthorizeInstrument(
    instrument: PaymentInstrument
  ): Promise<void> {
    const secureNonce = await getBraintreeSecureNonce(instrument.id);
    let nonce = secureNonce;

    if (!PaymentStore.skip3DS) {
      nonce = await this.verify3DSAndLiability(secureNonce, instrument.bin);
    }

    return this._braintreeClient.authorize(
      PaymentStore.paymentIntentId,
      nonce,
      instrument.id
    );
  }

  private async verify3DSAndLiability(
    nonce: string,
    bin: string
  ): Promise<string> {
    const result = await this._braintreeClient.verify3DS(
      nonce,
      bin,
      PaymentStore.orderTotal,
      PaymentStore.customerEmail
    );

    if (!result.liabilityShifted) {
      TelemetryService.trackEvent({
        name: 'Braintree3DSLiabilityNotShifted',
        properties: {
          proceedWithAuthorization: true,
          status: result.status,
        },
      });
    }

    return result.nonce;
  }
}

export default new BraintreeCardService();
