import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';

import { OptionalStartupable } from '../base/Startupable';
import {
  isStartupableStarted,
  registerStartupable,
} from '../base/StartupHelper';
import { paymentInstrumentAuthorize } from '../payments/intent/PaymentIntentApi';
import PaymentStore from '../payments/PaymentStore';
import TelemetryService from '../telemetry/TelemetryService';
import { getCardUrl, getManageCardUrl } from './helpers/FlutterwaveApi';

class FlutterwaveCardService implements OptionalStartupable {
  public readonly name = 'FlutterwaveCardService';
  public readonly moduleId = PaymentModuleId.FlutterwaveCreditCard;

  private _cachedUrls: { [key: string]: string } = {};

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public shouldStart(): boolean {
    const isAllowed = PaymentStore.allowedPaymentModules.includes(
      this.moduleId
    );

    const hasInstruments =
      PaymentStore.instruments.find((i) =>
        [this.moduleId].includes(i.moduleId)
      ) !== undefined;

    return isAllowed || hasInstruments;
  }

  public startup(): Promise<void> {
    this._cachedUrls = {};
    return Promise.resolve();
  }

  public async getRedirectUrl(
    locale: SupportedLocale,
    paymentInstrumentId?: number
  ): Promise<string> {
    const telemetryConsent = isStartupableStarted(TelemetryService);
    const paymentIntentId = PaymentStore.paymentIntentId;
    const redirectUrl = this.getCachedUrl(
      paymentIntentId,
      locale,
      telemetryConsent,
      paymentInstrumentId
    );

    if (redirectUrl) {
      return Promise.resolve(redirectUrl);
    }

    const url = await getCardUrl(
      paymentIntentId,
      paymentInstrumentId,
      locale,
      telemetryConsent
    );

    const key = this.computeCacheKeyFromParams(
      paymentIntentId,
      locale,
      telemetryConsent,
      paymentInstrumentId
    );
    this.saveUrlInCache(key, url);

    return url;
  }

  public async getManageRedirectUrl(
    legalEntityNumber: number,
    customerNumber: string,
    moduleId: PaymentModuleId,
    locale: SupportedLocale
  ): Promise<string> {
    const telemetryConsent = isStartupableStarted(TelemetryService);

    const redirectUrl = this.getCachedUrl(
      '',
      locale,
      telemetryConsent,
      undefined
    );

    if (redirectUrl) {
      return Promise.resolve(redirectUrl);
    }

    const url = await getManageCardUrl(
      legalEntityNumber,
      customerNumber,
      moduleId,
      locale,
      telemetryConsent
    );

    const key = this.computeCacheKeyFromParams(
      '',
      locale,
      telemetryConsent,
      undefined
    );
    this.saveUrlInCache(key, url);

    return url;
  }

  private getCachedUrl(
    paymentIntentId: string,
    locale: SupportedLocale,
    telemetryConsent: boolean,
    paymentInstrumentId?: number
  ): string | undefined {
    const key = this.computeCacheKeyFromParams(
      paymentIntentId,
      locale,
      telemetryConsent,
      paymentInstrumentId
    );
    return this._cachedUrls[key];
  }

  private computeCacheKeyFromParams(
    paymentIntentId: string,
    locale: SupportedLocale,
    telemetryConsent: boolean,
    paymentInstrumentId?: number
  ): string {
    return `${paymentIntentId}-${locale}-${telemetryConsent}-${
      paymentInstrumentId ?? ''
    }`;
  }

  private saveUrlInCache(key: string, value: string): void {
    this._cachedUrls[key] = value;
  }

  public authorize(instrumentId: number) {
    return paymentInstrumentAuthorize({
      paymentIntentId: PaymentStore.paymentIntentId,
      paymentDataTypeId: this.moduleId,
      paymentDataId: instrumentId,
    });
  }
}

export default new FlutterwaveCardService();
