import type { OnApproveData, PayPalScriptOptions } from '@paypal/paypal-js';
import { PayPalCheckoutCreatePaymentOptions } from 'braintree-web';
import { PayPalCheckoutTokenizationOptions } from 'braintree-web/paypal-checkout';
import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { AppTheme } from 'main/schemas/Theme';
import { registerStartupable } from 'main/services/base/StartupHelper';
import { isProdBuild } from 'main/utils/env';

import AuthenticationService from '../auth/AuthenticationService';
import PaymentStore from '../payments/PaymentStore';
import { OptionalStartupable } from './../base/Startupable';
import { BraintreeClient } from './BraintreeClient';
import { PayPalIdAlreadyUsedError } from './errors/PayPalIdAlreadyUsedError';
import { PayPalCheckoutInstanceMissingError } from './errors/PayPalInstanceMissingError';
import { createPayPalCheckoutInstance } from './helpers/BraintreeSdkWrapper';
import { PayPalSupportedLocale } from './helpers/BraintreeSupportedLocalesHelper';
import { PayPalBtnStyle } from './schemas/PayPalBtnStyle';

class BraintreePayPalService implements OptionalStartupable {
  public readonly name = 'BraintreePayPalService';
  public readonly moduleId = PaymentModuleId.BraintreePayPal;

  private _checkoutInstance: braintree.PayPalCheckout | undefined;
  private _braintreeClient!: BraintreeClient;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  public async startup(): Promise<void> {
    this.ensureCanInit();

    const paypalToken = await PaymentStore.getBraintreePaypalToken();
    if (paypalToken === undefined) {
      throw new Error('Paypal token has no value!');
    }

    this._braintreeClient = new BraintreeClient(this.moduleId, paypalToken);
    await this._braintreeClient.init();

    this._checkoutInstance = await createPayPalCheckoutInstance(
      this._braintreeClient.client
    );
  }

  public shouldStart(): boolean {
    const isAllowed = PaymentStore.allowedPaymentModules.includes(
      this.moduleId
    );

    const hasAtLeastOneInstrument =
      PaymentStore.instruments.find((i) => i.moduleId === this.moduleId) !==
      undefined;

    return isAllowed || hasAtLeastOneInstrument;
  }

  public createBillingAgreement(locale: SupportedLocale) {
    if (!this._checkoutInstance) {
      throw new PayPalCheckoutInstanceMissingError();
    }

    const braintreeLocale = PayPalSupportedLocale[locale];
    const options: PayPalCheckoutCreatePaymentOptions = {
      enableShippingAddress: false,
      shippingAddressEditable: false,
      flow: 'vault' as never, // The enum is not exported by the dependency
      intent: 'capture' as never, // The enum is not exported by the dependency
      vaultInitiatedCheckoutPaymentMethodToken: braintreeLocale,
      locale: braintreeLocale,
      currency: PaymentStore.currency,
      amount: PaymentStore.orderTotal,
    };

    return this._checkoutInstance.createPayment(options);
  }

  public async getTokenizedPayloadNonce(data: OnApproveData) {
    if (!this._checkoutInstance) {
      throw new PayPalCheckoutInstanceMissingError();
    }

    const tokenizeOptions: PayPalCheckoutTokenizationOptions = {
      payerId: data.payerID ?? '',
      billingToken: data.billingToken ?? '',
      paymentId: data.paymentID ?? '',
      vault: true,
    };

    const tokenizedData = await this._checkoutInstance.tokenizePayment(
      tokenizeOptions
    );

    return tokenizedData.nonce;
  }

  public getPayPalBtnStyle(theme: AppTheme): PayPalBtnStyle {
    return {
      shape: 'pill',
      label: 'paypal',
      layout: 'horizontal',
      tagline: false,
      height: 55,
      color: theme === AppTheme.Fitline ? 'gold' : 'blue',
    };
  }

  public getPayPalScriptOptions(locale: SupportedLocale): PayPalScriptOptions {
    return {
      'client-id': process.env.REACT_APP_PAYPAL_SDK_CLIENTID ?? '',
      'disable-funding': 'card',
      vault: true,
      intent: 'tokenize',
      locale: PayPalSupportedLocale[locale],
      components: 'buttons',
      debug: !isProdBuild,
    };
  }

  public authorize(nonceToken?: string, instrumentId?: number) {
    return this._braintreeClient.authorize(
      PaymentStore.paymentIntentId,
      nonceToken,
      instrumentId
    );
  }

  public async tokenize(nonceToken: string) {
    const customerNumber = AuthenticationService.user.tpId;

    if (customerNumber === undefined) {
      throw new Error('Customer number is not set');
    }

    if (PaymentStore.legalEntity === undefined) {
      throw new Error('Legal entity cannot be undefined');
    }

    await this._braintreeClient.tokenize(
      PaymentStore.legalEntity,
      customerNumber,
      nonceToken
    );

    return Promise.resolve();
  }

  private ensureCanInit() {
    const domElement = document.getElementById('paypal') ?? undefined;

    if (domElement !== undefined) {
      throw new PayPalIdAlreadyUsedError();
    }
  }
}

export default new BraintreePayPalService();
