import { FC } from 'react';

import { IconProps } from './schemas';

export const UserIcon: FC<IconProps> = ({
  className = 'h-4 w-4 self-center inline-block',
}) => {
  return (
    <svg viewBox="0 0 16 16" className={className} fill="currentColor">
      <circle cx="8" cy="4" r="4"></circle>
      <path d="M16,13.2a1.989,1.989,0,0,0-1.163-1.818A16.654,16.654,0,0,0,8,10a16.654,16.654,0,0,0-6.837,1.382A1.989,1.989,0,0,0,0,13.2V16H16Z"></path>
    </svg>
  );
};
