import {
  disableUIBlocker,
  PageLayout,
  PrimaryButton,
} from 'main/components/common';
import { AlertWarning } from 'main/components/common/alerts';
import PaymentStore from 'main/services/payments/PaymentStore';
import { redirect } from 'main/utils/redirect';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

//TODO: check if we need this session component because expiration should not happen

export const SessionExpired: FC = () => {
  const { t } = useTranslation();
  const url = PaymentStore.cancelUrl;

  useEffect(() => {
    disableUIBlocker();
  }, []);

  return (
    <PageLayout
      headerDescription={`${t('Order')}: ${PaymentStore.orderNumber}`}
    >
      <AlertWarning title={t('Your session expired!')}>
        {url !== undefined && (
          <PrimaryButton
            onClick={() => {
              redirect(url);
            }}
          >
            {t('Go back and restart')}
          </PrimaryButton>
        )}
      </AlertWarning>
    </PageLayout>
  );
};
