import { ArrowIcon, SimpleButton } from 'main/components/common';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import { useTranslation } from 'react-i18next';

export function NavigateToStoredInstrumentsPageBtn() {
  const { t } = useTranslation();

  return (
    <SimpleButton
      onClick={() =>
        NavigationService.navigateTo(InternalPage.StoredInstruments)
      }
    >
      <span className={'flex flex-row items-center justify-center'}>
        <ArrowIcon direction="left" />
        <span className={'ml-xs'}>{t('Back')}</span>
      </span>
    </SimpleButton>
  );
}
