/**
 * MicroSoft Clarity user behavior analytics tool
 * https://clarity.microsoft.com/
 *
 * Terms of use:
 * https://clarity.microsoft.com/terms
 *
 * Privacy page:
 * https://learn.microsoft.com/en-us/clarity/setup-and-installation/cookie-consent
 */

declare global {
  interface Window {
    clarity: {
      (operation: unknown, property: unknown, value: unknown): void;
      q: unknown[];
    };
  }
}

/**
 * Inject the MicroSoft Clarity telemetry/analytics script
 * https://clarity.microsoft.com/
 * @param c the browser Window object
 * @param l the browser document object
 * @param a the 'clarity' property
 * @param r the type of HTML element used to inject the script
 * @param i the ID/tag of the MS Clarity project
 * @param t the script element that will host MS Clarity
 * @param y the first <script> element in the DOM
 */
export function injectMicrosoftClarityScript(
  c: Window,
  l: Document,
  a: 'clarity',
  r: 'script',
  i: string,
  t?: HTMLScriptElement,
  y?: HTMLScriptElement
) {
  c[a] =
    c[a] ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      (c[a].q = c[a].q || []).push(arguments);
    };
  t = l.createElement(r);
  t.async = true;
  t.src = 'https://www.clarity.ms/tag/' + i;
  y = l.getElementsByTagName(r)[0];
  y.parentNode?.insertBefore(t, y);
}

export const setClarityTag = (tagName: string, value: string | string[]) => {
  if (window.clarity) {
    window.clarity('set', tagName, value);
  }
};
