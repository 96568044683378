import { UIBlockerBlockEvent, UIBlockerUnblockEvent } from './events';

export function enableUIBlocker(): void {
  if (window?.document?.body) {
    window.document.body.dispatchEvent(new CustomEvent(UIBlockerBlockEvent));
  }
}

export function disableUIBlocker(): void {
  if (window?.document?.body) {
    window.document.body.dispatchEvent(new CustomEvent(UIBlockerUnblockEvent));
  }
}
