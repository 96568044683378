import { FitlineLogo, PMLogo } from 'main/components/common';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const CommonLogos: FC = () => {
  return (
    <DemoContainer title={'UI LOGOS'}>
      <div className="flex flex-row flex-wrap gap-4">
        <FitlineLogo />
        <PMLogo />
        <PMLogo showClaim />
      </div>
    </DemoContainer>
  );
};
