import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';

import { OptionalStartupable } from '../base/Startupable';
import { registerStartupable } from '../base/StartupHelper';
import PaymentStore from '../payments/PaymentStore';
import { getTwoCTwoPRedirectUrl } from './helpers/TwoCTwoPApi';
import { TwoCTwoPSupportedLocale } from './helpers/TwoCTwoPSupportedLocalesHelper';

class TwoCTwoPService implements OptionalStartupable {
  public readonly name = 'TwoCTwoPService';
  public readonly moduleId = PaymentModuleId.TwoCTwoP;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  shouldStart(): boolean {
    const isAllowed = PaymentStore.allowedPaymentModules.includes(
      this.moduleId
    );

    return isAllowed;
  }

  startup(): Promise<void> {
    return Promise.resolve();
  }

  public getRedirectUrl(
    locale: SupportedLocale,
    telemetryConsent = false
  ): Promise<string> {
    const paymentIntentId = PaymentStore.paymentIntentId;

    const twoCTwoPLocale = TwoCTwoPSupportedLocale[locale];

    return getTwoCTwoPRedirectUrl(
      paymentIntentId,
      twoCTwoPLocale,
      telemetryConsent
    );
  }
}

export default new TwoCTwoPService();
