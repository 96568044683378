import { FC, MouseEvent } from 'react';

import type { IButtonProps } from './schemas';

export const BasicButton: FC<IButtonProps> = ({
  onClick,
  disabled = false,
  className,
  ariaLabel,
  children,
}) => {
  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      type="button"
      onClick={onClickHandler}
      disabled={disabled}
      className={className}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  );
};
