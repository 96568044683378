import AuthenticationService from 'main/services/auth/AuthenticationService';
import { APP_URL } from 'main/utils/env';
import { redirect } from 'main/utils/redirect';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ErrorHandler } from '../common/errors/ErrorHandler';

export const AuthenticationWrapper: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [searchParams] = useSearchParams();
  const [shouldRenderApp, setShouldRenderApp] = useState<boolean>(false);
  const [err, setErr] = useState<unknown>();

  const handleAuthentication = async () => {
    try {
      const nonceToken = searchParams.get('nonceToken') ?? undefined;
      if (nonceToken !== undefined) {
        // We need to persist it in the sessionStorage in order to recover it
        // and rebuild the service with the same state on /authcallback
        window.sessionStorage.setItem('pmi.payments.nt', nonceToken);
      }

      await AuthenticationService.startup(nonceToken);

      const state = searchParams.get('state') ?? undefined;
      if (state) {
        // If there is a state, it means the user was signed out and redirect
        // again to this application. We must complete the signout flow and get the
        // the user state to recover the page correctly.
        const signoutState = await AuthenticationService.finishSignout();
        const url = new URL(signoutState as string, APP_URL);
        redirect(url.href, 0);
      } else if (!AuthenticationService.isSignedIn) {
        // This state will be recovered on the identity redirect to the auth callback
        // URL. Please ensure the state passed here is properly handle in file
        // "routes/callback.tsx"
        AuthenticationService.startSignIn(
          window.location.href.replace(window.location.origin, '')
        );
      } else {
        setShouldRenderApp(true);
      }
    } catch (err) {
      setErr(err ?? new Error('Error'));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    handleAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (err !== undefined) {
    return <ErrorHandler err={err} />;
  }

  return shouldRenderApp ? <>{children}</> : null;
};
