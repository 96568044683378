import { ArrowIcon, SimpleButton } from 'main/components/common';
import PaymentStore from 'main/services/payments/PaymentStore';
import { redirect } from 'main/utils/redirect';
import { useTranslation } from 'react-i18next';

export function RedirectUrlButton() {
  const { t } = useTranslation();

  return (
    <SimpleButton
      onClick={() =>
        redirect(
          PaymentStore.redirectUrl !== undefined ? PaymentStore.redirectUrl : ''
        )
      }
    >
      <span className={'flex flex-row items-center justify-center'}>
        <ArrowIcon direction="left" />
        <span className={'ml-xs'}>{t('Back')}</span>
      </span>
    </SimpleButton>
  );
}
