import { ThreeDSecure } from 'braintree-web';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';

import { paymentInstrumentTokenize } from '../payments/instruments/PaymentInstrumentsApi';
import { paymentInstrumentAuthorize } from '../payments/intent/PaymentIntentApi';
import { LiabilityNotShiftedError } from './errors/LiabilityNotShiftedError';
import { ThreeDSecureInstanceMissingError } from './errors/ThreeDSecureInstanceMissingError';
import {
  createBraintreeClient,
  createBraintreeDataCollector,
  createBraintreeThreeDSecureClient,
  isLiabilityShifted,
  isOwnLiabilityAccepted,
  verifyBraintreeCard,
} from './helpers/BraintreeSdkWrapper';
import { ThreeDSecureInfoV2 } from './schemas/schemas';

type BraintreeModuleId =
  | PaymentModuleId.BraintreeApplePay
  | PaymentModuleId.BraintreeCard
  | PaymentModuleId.BraintreeGooglePay
  | PaymentModuleId.BraintreePayPal
  | PaymentModuleId.BraintreeSepa;

interface ThreeDSecureValidResult {
  readonly nonce: string;
  readonly liabilityShifted: boolean;
  readonly status: string | undefined;
}

export interface BraintreeMerchantInfo {
  readonly merchantId: string;
  readonly merchantAccountId: string;
}

export class BraintreeClient {
  private _client!: braintree.Client;
  private _deviceData: string | undefined;
  private _threeDsInstance: ThreeDSecure | undefined;

  private _braintreeAccessToken: string;
  private _moduleId: BraintreeModuleId;

  constructor(moduleId: BraintreeModuleId, braintreeAccessToken: string) {
    this._moduleId = moduleId;
    this._braintreeAccessToken = braintreeAccessToken;
  }

  public async init(): Promise<void> {
    this._client = await createBraintreeClient(this._braintreeAccessToken);
    const { deviceData } = await createBraintreeDataCollector(this._client);
    this._deviceData = deviceData;
  }

  public async initWith3DS(): Promise<void> {
    await this.init();

    this._threeDsInstance = await createBraintreeThreeDSecureClient(
      this._client
    );
  }

  public get client() {
    return this._client;
  }

  public authorize(
    intentId: string,
    nonce?: string,
    instrumentId?: number,
    ibanLastFour?: string
  ): Promise<void> {
    return paymentInstrumentAuthorize({
      paymentIntentId: intentId,
      paymentDataTypeId: this._moduleId,
      paymentDataId: instrumentId,
      deviceData: this._deviceData,
      nonceToken: nonce,
      ibanLastFour: ibanLastFour,
    });
  }

  public tokenize(
    legalEntityNumber: number,
    customerNumber: string,
    nonce: string,
    ibanLastFour?: string
  ): Promise<string> {
    return paymentInstrumentTokenize({
      paymentModuleId: this._moduleId,
      legalEntityNumber: legalEntityNumber,
      customerNumber: customerNumber,
      braintreeInstrument: {
        nonceToken: nonce,
        ibanLastFour: ibanLastFour,
        deviceData: this._deviceData,
      },
    });
  }

  public async verify3DS(
    nonce: string,
    bin: string,
    amount: number,
    email: string
  ): Promise<ThreeDSecureValidResult> {
    if (!this._threeDsInstance) {
      throw new ThreeDSecureInstanceMissingError();
    }

    const verificationPayload = await verifyBraintreeCard(
      this._threeDsInstance,
      nonce,
      bin,
      amount,
      email
    );

    if (isLiabilityShifted(verificationPayload.threeDSecureInfo)) {
      return {
        nonce: verificationPayload.nonce,
        liabilityShifted: true,
        status: undefined,
      };
    }

    if (isOwnLiabilityAccepted(verificationPayload.threeDSecureInfo)) {
      return {
        nonce: verificationPayload.nonce,
        liabilityShifted: false,
        status: (verificationPayload.threeDSecureInfo as ThreeDSecureInfoV2)
          .status,
      };
    }

    throw new LiabilityNotShiftedError(verificationPayload.threeDSecureInfo);
  }
}
