import { AppVersion } from 'main/utils/version';
import { FC } from 'react';

import { DemoContainer } from './renderers';

export const EnvInfo: FC = () => {
  return (
    <DemoContainer title={'ACTIVE ENVIRONMENT'}>
      <p>Environment name: {process.env.REACT_APP_ACTIVE_ENV}</p>
      <p>UI version: {AppVersion}</p>
    </DemoContainer>
  );
};
